// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCFLj9jdlw-fuyMUMz0sloOE5GVjevPAPg",
  authDomain: "makemybiodata-7b7b3.firebaseapp.com",
  projectId: "makemybiodata-7b7b3",
  storageBucket: "makemybiodata-7b7b3.appspot.com",
  messagingSenderId: "1029425600881",
  appId: "1:1029425600881:web:b5792f7f1f67b5131ebed0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
