import { Grid, Typography } from "@mui/material";
import ImagePath from "../../../assets/images";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { fetchBiodataTemplates } from "../../../services/ApiService";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { MdArrowBackIosNew } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";
import fontStyle from "../../../assets/fontStyle";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <ArrowForwardIosIcon
      className={className}
      style={{
        ...style,
        display: "block",
        backgroundColor: "white",
        borderRadius: 5,
        fontSize: "1.6rem",
        color: "black",
        padding: "5px",
        cursor: "pointer",
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <ArrowBackIosNewIcon
      className={className}
      style={{
        ...style,
        display: "block",
        backgroundColor: "white",
        borderRadius: 5,
        fontSize: "1.6rem",
        color: "black",
        padding: "5px",
        cursor: "pointer",
      }}
      onClick={onClick}
    />
  );
}

// export function SamplePrevArrow(props) {
//   const { className, style, onClick } = props;
//   return (
//     <div onClick={onClick} className={`arrow ${className}`}>
//       <IoIosArrowBack
//         className="arrows"
//         style={{ color: "white", backgroundColor: "pink" }}
//       />
//     </div>
//   );
// }

// export function SampleNextArrow(props) {
//   const { className, style, onClick } = props;
//   return (
//     <div onClick={onClick} className={`arrow ${className}`}>
//       <IoIosArrowForward className="arrows" style={{ color: "red" }} />
//     </div>
//   );
// }

const BioDataTemplate = () => {
  // const settings = {
  //   dots: false,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 4,
  //   slidesToScroll: 1,
  //   initialSlide: 0,
  //   // autoplay: true,
  //   // arrows: false,
  //   speed: 2000,
  //   centerPadding: "60px",
  //   autoplaySpeed: 2000,
  //   cssEase: "linear",
  //   nextArrow: <SampleNextArrow />,
  //   prevArrow: <SamplePrevArrow />,
  //   responsive: [
  //     {
  //       breakpoint: 360,
  //       settings: {
  //         slidesToShow: 1.02,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 390,
  //       settings: {
  //         slidesToShow: 1.1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 430,
  //       settings: {
  //         slidesToShow: 1.2,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 1.4,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 540,
  //       settings: {
  //         slidesToShow: 1.5,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 620,
  //       settings: {
  //         slidesToShow: 1.7,
  //         slidesToScroll: 1,
  //         initialSlide: 0,
  //       },
  //     },
  //     {
  //       breakpoint: 650,
  //       settings: {
  //         slidesToShow: 1.9,
  //         slidesToScroll: 1,
  //         initialSlide: 0,
  //       },
  //     },
  //     {
  //       breakpoint: 720,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //         initialSlide: 0,
  //       },
  //     },
  //     {
  //       breakpoint: 790,
  //       settings: {
  //         slidesToShow: 2.2,
  //         slidesToScroll: 1,
  //         initialSlide: 0,
  //       },
  //     },
  //     {
  //       breakpoint: 825,
  //       settings: {
  //         slidesToShow: 2.55,
  //         slidesToScroll: 1,
  //         initialSlide: 0,
  //       },
  //     },
  //     {
  //       breakpoint: 855,
  //       settings: {
  //         slidesToShow: 2.55,
  //         slidesToScroll: 1,
  //       },
  //     },

  //     {
  //       breakpoint: 920,
  //       settings: {
  //         slidesToShow: 2.6,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 1030,
  //       settings: {
  //         slidesToShow: 3.02,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 1150,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 1235,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 1285,
  //       settings: {
  //         slidesToShow: 4,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // };

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    arrows: window.innerWidth > 730 ? true : true,
    slidesToScroll: 1,
    initialSlide: 0,
    // autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",

    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1370,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1260,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 980,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 915,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 815,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 675,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 545,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 490,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 430,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 370,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      // {
      //   breakpoint: 768,
      //   settings: {
      //     slidesToShow: 2,
      //     slidesToScroll: 1,
      //   },
      // },
      // {
      //   breakpoint: 480,
      //   settings: {
      //     slidesToShow: 1,
      //     slidesToScroll: 1,
      //   },
      // },
    ],
  };

  const [tempOp, setTempOp] = useState([]);
  useEffect(() => {
    const fetchTemp = async () => {
      try {
        const temp = await fetchBiodataTemplates();
        setTempOp(temp);
        console.log(tempOp);
      } catch (error) {
        console.error("Error fetching templates:", error);
      }
    };
    fetchTemp();
  }, []);

  return (
    <Grid
      container
      sx={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#FFF9EC",
        margin: "0 auto",
      }}
    >
      <Grid
        container
        sx={{
          height: "auto",
          maxHeight: 1000,
          maxWidth: "1400px",
          paddingTop: "5%",
          // paddingBottom:"2.5%",
          zIndex: 1,
          paddingLeft: window.innerWidth > 1400 ? "0%" : "7%",
          paddingRight: window.innerWidth > 1400 ? "0%" : "7%",
          // padding: window.innerWidth > "1400px" ? "0 0 0 0" : "0 7% 0 7%",
          backgroundColor: "rgba(255, 249, 236, 1)",
          // backgroundColor:"red"
          // backgroundColor: "rgba(252, 101, 32, 0.07)",
          // backgroundColor: "red"
        }}
      >
        <Grid
          sm={12}
          ls={12}
          md={12}
          xs={12}
          style={{
            // backgroundColor: "white",
            // height: "25%",
            // padding:
            //   window.innerWidth < 300
            //     ? "7% 0 0px 0px"
            //     : window.innerWidth > 600
            //     ? "7% 0 0px 0px"
            //     : "7% 0 0px 0px",
            marginLeft: 15,
          }}
        >
          <h1
            style={{
              fontFamily: "Enoway",
              color: "#EC0673",
              fontSize: 35,
              fontWeight: 400,
              letterSpacing: "0.1rem",
              textAlign: "center",
            }}
          >
            <span style={{ color: "black" }}>Make your biodata with </span> our
            excellent samples
          </h1>
          <Typography style={{ fontFamily: "Avenir", textAlign: "center" }}>
            Say goodbye to design headaches and hello to a Make My BioData that
            gets noticed! Browse our collection today and find the perfect
            template to tell your story. Our collection of handcrafted marriage
            biodata templates eliminates the hassle of design and lets you focus
            on what truly matters.
          </Typography>
        </Grid>

        <Grid sm={12} ls={12} md={12} xs={12} style={{ height: "75%" }}>
          <Grid
            sm={12}
            ls={12}
            md={12}
            xs={12}
            style={{
              // backgroundColor: "white",
              height: "95%",
              padding: window.innerWidth > 1400 ? "10px 4%" : "10px 0",
              // marginLeft: 15,
            }}
          >
            {/* <Slider
            {...settings}
            style={{
              paddingLeft: window.innerWidth > 1030 ? 15 : 13,
              paddingRight: window.innerWidth > 1285 ? 15 : 10,
            }}
          >
            {tempOp.map((image) => (
              <div style={{ marginRight: 0 }}>
                <img
                  src={`https://includetechnology.com/MarriageBiodata/public/img/templates/${image.sample_path}`}
                  alt={`${image.name}`}
                  style={{ width: "15rem", paddingLeft: "1rem" }}
                />
              </div>
            ))}
          </Slider> */}
            {/* <Slider {...settings} style={{
              // paddingLeft: window.innerWidth > 1030 ? 15 : 13,
              paddingRight: window.innerWidth > 1285 ? 15 : 10,
            }}>
      {tempOp.map((image) => (
        <div key={image.id} style={{ padding: "0 0px" }}>
          <img
            src={`https://includetechnology.com/MarriageBiodata/public/img/templates/${image.sample_path}`}
            alt={image.name}
            style={{ width: "20rem", padding: "0 0rem" }}
          />
        </div>
      ))}
    </Slider> */}
            {/* <Slider {...settings} style={{paddingRight: "1rem", paddingLeft: "0.8rem"}}>
    {tempOp.map((image) => (
        <div key={image.id} style={{}}>
          <img
            src={`https://includetechnology.com/MarriageBiodata/public/img/templates/${image.sample_path}`}
            alt={image.name}
            style={{ width: window.innerWidth > 730 ? "20rem" : "15rem", padding: "10px" }}
          />
        </div>
      ))}
      </Slider> */}
            <Slider
              {...settings}
              style={{ paddingRight: "0.6rem", paddingLeft: "0.6rem" }}
            >
              {tempOp.map((image) => (
                <div key={image.id}>
                  <Grid container justifyContent="center">
                    <Grid item xs={12} spacing={5} style={{ padding: "10px" }}>
                      <img
                        src={`https://includetechnology.com/MarriageBiodata/public/img/templates/${image.sample_path}`}
                        alt={image.name}
                        style={{ maxWidth: "100%", height: "auto" }}
                      />
                    </Grid>
                  </Grid>
                </div>
              ))}
            </Slider>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BioDataTemplate;
