import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import ImagePath from "../../../assets/images";
import { IoLogIn } from "react-icons/io5";
import { CiLogin } from "react-icons/ci";
import { CiLogout } from "react-icons/ci";
import Swal from "sweetalert2";
import Styles from "./style";
import { NavLink, Navigate, useLocation } from "react-router-dom";
import fontStyle from "../../../assets/fontStyle";
import {
  isLoggedIn,
  isLoggedOut,
  loginUserIdName,
  loginUserName,
} from "../../../utils/localStorage";
import { UseDispatch, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { storeAuthData } from "../../../Redux/action";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";

const pages = ["Products", "Pricing", "Blog"];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

function NavBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [user, setUser] = React.useState(isLoggedIn());
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userName, setUserName] = React.useState(loginUserIdName());

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const login = () => {
    console.log(user);
  };

  const LoggedOut = () => {
    handleCloseUserMenu();
    Swal.fire({
      title: "Are you sure you want to logout?",
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: "Yes",
      // denyButtonText: `Don't save`
      customClass: {
        confirmButton: "btnlogoutconfirm", // Custom class for confirm button
        denyButton: "btnlogoutdeny", // Custom class for deny button
      },
    }).then((result) => {
      if (result.isConfirmed) {
        isLoggedOut();
        setUser(null);
        dispatch(storeAuthData(false));
        navigate("/");
      } else if (result.isDenied) {
      }
    });
    console.log(user);
  };

  const style = {
    MenuLink: {
      textDecoration: "none",
      color: "#000",
      ":hover": { color: "#8062ba" },
    },
  };

  const User = useSelector((store) => store.reducer.user);
  const location = useLocation();

  return (
    <Grid container sx={{ position: 'fixed', top: 0, left: 0, width: '100%', zIndex: 1100, display: "flex", justifyContent: "center", backgroundColor: "#FFF9EC",  margin: "0 auto" }}>
    {/* <Box sx={{ position: 'fixed', top: 0, left: 0, width: '100%', zIndex: 1100, display: 'flex', justifyContent: 'center', backgroundColor: 'red' }}> */}
    <AppBar
      position="static"
      sx={{
        height: "96px",
        backgroundColor: '#FFF9EC',
        boxShadow: 'none',
        // maxWidth: '1400px',
        width: '100%',
        padding: window.innerWidth > 1365 ? "0 15% 0 7%" : "0 7% 0 7%",
        // paddingLeft: '7%',
        // paddingRight: '7%',
        margin: '0 auto',
        left: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'flex-end',
        alignContent:"center"
      }}
    >
      {/* <Container sx={{maxWidth:"100% !important", display:"flex", alignContent:"center"}}> */}
        <Toolbar disableGutters >
          {/* <AdbIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} /> */}
          <Box
            sx={{
              // flexGrow: 1,
              display: { xs: "none", sm: "none", md: "none", lg: "flex" },
            }}
            style={{
              marginLeft: -22,
            }}
          >
            <Link to={"/"}>
              <img
                sx={{
                  display: { xs: "none", md: "flex" },
                  mt: 10,
                }}
                src={ImagePath.Icon}
                style={Styles.image1}
              ></img>
            </Link>
          </Box>

          <Box
            sx={{
              // flexGrow: 1,
              display: { xs: "flex", sm: "flex", md: "flex", lg: "none" },
            }}
            style={{ marginLeft: -12 }}
          >
            <Link to={"/"}>
              <img
                sx={{ display: { xs: "none", sm: "flex" },  }}
                src={ImagePath.Icon}
                style={Styles.imageMobile1}
              ></img>
            </Link>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", sm: "flex", md: "flex", lg: "none" },
              justifyContent: "flex-end",
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              // color="pink"
              // sx={{ color: "pink", fontSize: 30 }}
            >
              <MenuIcon
                sx={{ color: "#EC0673", fontSize: 30, fontWeight: "BOLD" }}
              />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              PaperProps={{
                style: {
                  backgroundColor: "#FFF9EC",
                },
              }}
              sx={{
                display: { xs: "block", sm: "block", md: "blocl", lg: "none" },
              }}
            >
              {user ? (
                <>
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography textAlign="center" style={{fontFamily: "Avenir", fontWeight:"bold", color: "black"}}>
                      Hello!{" "}
                      <span style={{ color: "#EC0673" }}> {userName}</span>
                    </Typography>
                  </MenuItem>
                  <hr />
                </>
              ) : null}
              <MenuItem onClick={handleCloseNavMenu}>
                <NavLink
                  to={"/"}
                  onClick={handleCloseNavMenu}
                  style={({ isActive, isPending, isTransitioning }) => {
                    return {
                      fontWeight: isActive ? "bold" : "lighter",
                      color: "black",
                      textDecoration: "none",
                      viewTransitionName: isTransitioning ? "" : "",
                    };
                  }}
                >
                   {({ isActive }) => (
      <Typography
        sx={{
        ...fontStyle.ContentFont,
        position: "relative",
        "&:hover": {
          color: "#EC0673",
        },
        fontWeight: "bold",
        "&::after": {
          content: '""',
          display: isActive ? 'block' : 'none',
          width: '20px',
          height: '4px',
          backgroundColor: '#EC0673',
          position: 'absolute',
          bottom: '-3px', // Adjust as needed
          left: '20%',
          transform: 'translateX(-50%)'
        },
        }}
       
      >
        Home
      </Typography>
    )}
                </NavLink>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <NavLink
                  to={"/aboutus"}
                  onClick={handleCloseNavMenu}
                  sx={{
                    "&:hover": {
                      color: "green", // Change the color to green on hover
                    },
                  }}
                  style={({ isActive, isPending, isTransitioning }) => {
                    return {
                      fontWeight: isActive ? "bold" : "lighter",
                      color: "black",
                      textDecoration: "none",
                      viewTransitionName: isTransitioning ? "" : "",
                    };
                  }}
                >
                   {({ isActive }) => (
      <Typography
        sx={{
        ...fontStyle.ContentFont,
        position: "relative",
        "&:hover": {
          color: "#EC0673",
        },
        fontWeight: "bold",
        "&::after": {
          content: '""',
          display: isActive ? 'block' : 'none',
          width: '20px',
          height: '4px',
          backgroundColor: '#EC0673',
          position: 'absolute',
          bottom: '-3px', // Adjust as needed
          left: '10%',
          transform: 'translateX(-50%)'
        },
        }}
       
      >
        About Us
      </Typography>
    )} 
                </NavLink>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <NavLink
                  to={"/personaldetails"}
                  onClick={handleCloseNavMenu}
                  style={({ isActive, isPending, isTransitioning }) => {
                    return {
                      fontWeight: isActive ? "bold" : "lighter",
                      color: "BLACK",
                      textDecoration: "none",
                      viewTransitionName: isTransitioning ? "" : "",
                    };
                  }}
                >
              {({ isActive }) => (
      <Typography
        sx={{
        ...fontStyle.ContentFont,
        position: "relative",
        "&:hover": {
          color: "#EC0673",
        },
        fontWeight: "bold",
        "&::after": {
          content: '""',
          display: isActive ? 'block' : 'none',
          width: '20px',
          height: '4px',
          backgroundColor: '#EC0673',
          position: 'absolute',
          bottom: '-3px', // Adjust as needed
          left: '10%',
          transform: 'translateX(-50%)'
        },
        }}
       
      >
        Make My BioData
      </Typography>
    )}
                </NavLink>
              </MenuItem>

              <MenuItem onClick={handleCloseNavMenu}>
                <NavLink
                  to={"/faqs"}
                  onClick={handleCloseNavMenu}
                  style={({ isActive, isPending, isTransitioning }) => {
                    return {
                      fontWeight: isActive ? "bold" : "lighter",
                      color: "black",
                      textDecoration: "none",
                      viewTransitionName: isTransitioning ? "" : "",
                    };
                  }}
                >
                 
               {({ isActive }) => (
      <Typography
        sx={{
        ...fontStyle.ContentFont,
        position: "relative",
        "&:hover": {
          color: "#EC0673",
        },
        fontWeight: "bold",
        "&::after": {
          content: '""',
          display: isActive ? 'block' : 'none',
          width: '20px',
          height: '4px',
          backgroundColor: '#EC0673',
          position: 'absolute',
          bottom: '-3px', // Adjust as needed
          left: '23%',
          transform: 'translateX(-50%)'
        },
        }}
       
      >
        FAQ's
      </Typography>
    )} 
                </NavLink>
              </MenuItem>

              <MenuItem onClick={handleCloseNavMenu}>
                <NavLink
                  to={"/contactus"}
                  onClick={handleCloseNavMenu}
                  style={({ isActive, isPending, isTransitioning }) => {
                    return {
                      fontWeight: isActive ? "bold" : "lighter",
                      color: "black",
                      textDecoration: "none",
                      viewTransitionName: isTransitioning ? "" : "",
                    };
                  }}
                >
                 
               {({ isActive }) => (
      <Typography
        sx={{
        ...fontStyle.ContentFont,
        position: "relative",
        "&:hover": {
          color: "#EC0673",
        },
        fontWeight: "bold",
        "&::after": {
          content: '""',
          display: isActive ? 'block' : 'none',
          width: '20px',
          height: '4px',
          backgroundColor: '#EC0673',
          position: 'absolute',
          bottom: '-3px', // Adjust as needed
          left: '10%',
          transform: 'translateX(-50%)'
        },
        }}
       
      >
        Contact Us
      </Typography>
    )} 
                </NavLink>
              </MenuItem>
              {!user ? (
                <MenuItem onClick={handleCloseNavMenu} style={{}}>
                  <Link
                    to={"/login"}
                    style={{
                      textDecoration: "none",
                      color: "white",
                      // borderRadius: 5,
                      padding: 6,
                      width: 90,
                      background:
                        "#EC0673",
                      display: "flex",
                      "&:hover": {
                        color: "black",
                        backgroundColor: "rgb(255, 210, 235)"
                      }
                    }}
                   
                  >
                    <Typography
                      textAlign="center"
                      style={{  fontSize: 17, fontFamily: "Avenir" }}
                    >
                      LOGIN
                    </Typography>
                    <CiLogin style={{  fontSize: 25 }} />
                  </Link>
                </MenuItem>
              ) : (
                <>
                  <MenuItem onClick={handleCloseNavMenu}>
                    <NavLink
                      to={"/profile"}
                      onClick={handleCloseNavMenu}
                      style={({ isActive, isPending, isTransitioning }) => {
                        return {
                          fontWeight: isActive ? "bold" : "lighter",
                          color: "black",
                          textDecoration: "none",
                          viewTransitionName: isTransitioning ? "" : "",
                        };
                      }}
                    >
               {({ isActive }) => (
      <Typography
        sx={{
        ...fontStyle.ContentFont,
        position: "relative",
        "&:hover": {
          color: "#EC0673",
        },
        fontWeight: "bold",
        "&::after": {
          content: '""',
          display: isActive ? 'block' : 'none',
          width: '20px',
          height: '4px',
          backgroundColor: '#EC0673',
          position: 'absolute',
          bottom: '-3px', // Adjust as needed
          left: '10%',
          transform: 'translateX(-50%)'
        },
        }}
       
      >
        My Profile
      </Typography>
    )} 
    
                    </NavLink>
                  </MenuItem>

                  <MenuItem onClick={handleCloseNavMenu}>
                    <Button
                      onClick={() => LoggedOut()}
                      sx={{
                        color: "white",

                        fontSize: 15,
                        background:
                          "#EC0673",
                        fontWeight: "normal",
                       "&:hover":{
                        color: "black",
                        backgroundColor: "rgb(255, 210, 235)"
                       }
                      }}
                     
                    >
                      <CiLogout style={{  fontSize: 25 }} />
                      <Typography
                        style={{  margin: 2, fontSize: 13, fontFamily: "Avenir" }}
                      >
                        LogOut
                      </Typography>
                    </Button>
                  </MenuItem>
                </>
              )}
            </Menu>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", sm: "none", md: "none", lg: "flex" },
              justifyContent: "flex-end",
              paddingRight: 0,
            }}
          >
            <NavLink
              to={"/"}
              onClick={handleCloseNavMenu}
              style={({ isActive, isPending, isTransitioning }) => {
                return {
                  fontWeight: isActive ? "bold" : "",
                  // color: isActive ? "#EC0673" : "grey",
color: "black",
                  textDecoration: "none",
                  paddingTop: 21,
                  paddingRight: window.innerWidth > 1200 && window.innerWidth < 1240 ? 20 : 30,
                  viewTransitionName: isTransitioning ? "" : "",
                };
              }}
            >
               {({ isActive }) => (
      <Typography
        sx={{
        ...fontStyle.ContentFont,
        position: "relative",
        "&:hover": {
          color: "#EC0673",
        },
        fontWeight: "bold",
        "&::after": {
          content: '""',
          display: isActive ? 'block' : 'none',
          width: '20px',
          height: '4px',
          backgroundColor: '#EC0673',
          position: 'absolute',
          bottom: '-3px', // Adjust as needed
          left: '20%',
          transform: 'translateX(-50%)'
        },
        }}
       
      >
        Home
      </Typography>
    )}
            </NavLink>

            <NavLink
              to={"/aboutus"}
              onClick={handleCloseNavMenu}
              style={({ isActive, isPending, isTransitioning }) => {
                return {
                  fontWeight: isActive ? "bold" : "",
                  // color: isActive ? "#EC0673" : "grey",
                  color: "black",
                  textDecoration: "none",
                  paddingTop: 21,
                  paddingRight: window.innerWidth > 1200 && window.innerWidth < 1240 ? 20 : 30,
                  viewTransitionName: isTransitioning ? "" : "",
                };
              }}
            >
              
              {({ isActive }) => (
      <Typography
        sx={{
        ...fontStyle.ContentFont,
        position: "relative",
        "&:hover": {
          color: "#EC0673",
        },
        fontWeight: "bold",
        "&::after": {
          content: '""',
          display: isActive ? 'block' : 'none',
          width: '20px',
          height: '4px',
          backgroundColor: '#EC0673',
          position: 'absolute',
          bottom: '-3px', // Adjust as needed
          left: '10%',
          transform: 'translateX(-50%)'
        },
        }}
       
      >
        About Us
      </Typography>
              )}
            </NavLink>

            <NavLink
              to={"/personaldetails"}
              onClick={handleCloseNavMenu}
              style={({ isActive, isPending, isTransitioning }) => {
                return {
                  fontWeight: isActive ? "bold" : "",
                  // color: isActive ? "#EC0673" : "grey",
                  color:"black",
                  textDecoration: "none",
                  paddingTop: 21,
                  paddingRight: window.innerWidth > 1200 && window.innerWidth < 1240 ? 20 : 30,
                  viewTransitionName: isTransitioning ? "" : "",
                };
              }}
            >
              {({ isActive }) => (
      <Typography
      sx={{
        ...fontStyle.ContentFont,
        position: "relative",
        "&:hover": {
          color: "#EC0673",
        },
        fontWeight: "bold",
        "&::after": {
          content: '""',
          display: isActive ? 'block' : 'none',
          width: '20px',
          height: '4px',
          backgroundColor: '#EC0673',
          position: 'absolute',
          bottom: '-3px', // Adjust as needed
          left: '10%',
          transform: 'translateX(-50%)'
        },
        }}
      >
        Make My BioData
      </Typography>
              )}
            </NavLink>


            <NavLink
              to={"/faqs"}
              onClick={handleCloseNavMenu}
              style={({ isActive, isPending, isTransitioning }) => {
                return {
                  fontWeight: isActive ? "bold" : "",
                  // color: isActive ? "#EC0673" : "grey",
                  color: "black",
                  textDecoration: "none",
                  paddingTop: 21,
                  paddingRight: window.innerWidth > 1200 && window.innerWidth < 1240 ? 20 : 30,
                  
                };
              }}
            >
              {({ isActive }) => (
      <Typography
        sx={{
          ...fontStyle.ContentFont,
          position: "relative",
          "&:hover": {
            color: "#EC0673",
          },
          fontWeight: "bold",
          "&::after": {
            content: '""',
            display: isActive ? 'block' : 'none',
            width: '20px',
            height: '4px',
            backgroundColor: '#EC0673',
            position: 'absolute',
            bottom: '-3px', // Adjust as needed
            left: '24%',
            transform: 'translateX(-50%)'
          },
          }}
      >
        FAQ's
      </Typography>
              )}
            </NavLink>

            <NavLink
              to={"/contactus"}
              onClick={handleCloseNavMenu}
              style={({ isActive, isPending, isTransitioning }) => {
                return {
                  fontWeight: isActive ? "bold" : "",
                  // color: isActive ? "#EC0673" : "grey",
                  color: "black",
                  textDecoration: "none",
                  paddingTop: 21,
                  paddingRight: window.innerWidth > 1200 && window.innerWidth < 1240 ? 20 : 30,
                  
                };
              }}
            >
              {({ isActive }) => (
      <Typography
        sx={{
          ...fontStyle.ContentFont,
          position: "relative",
          "&:hover": {
            color: "#EC0673",
          },
          fontWeight: "bold",
          "&::after": {
            content: '""',
            display: isActive ? 'block' : 'none',
            width: '20px',
            height: '4px',
            backgroundColor: '#EC0673',
            position: 'absolute',
            bottom: '-3px', // Adjust as needed
            left: '10%',
            transform: 'translateX(-50%)'
          },
          }}
      >
        Contact Us
      </Typography>
              )}
            </NavLink>

           

            {!user && location.pathname !== '/login' && (
        <Link to="/login" style={{ textDecoration: 'none' }}>
          <Button
            onClick={login}
            sx={{
              my: 1,
              color: 'white',
              display: 'block',
              width: '90px',
              height: '43px',
              marginTop: window.innerWidth < 1030 ? 2 : 1.5,
              display: 'flex',
              fontSize: 15,
              background: '#EC0673',
              fontWeight: 'normal',
              '&:hover': {
                color: 'black',
                backgroundColor: 'rgb(255, 210, 235)',
              },
            }}
          >
            <Typography
              style={{ margin: 2, fontSize: 14, fontFamily: 'Avenir' }}
            >
              Login
            </Typography>
            <CiLogin style={{ fontSize: 30 }} />
          </Button>
        </Link>
               
            )}

{user &&  (
              <Box>
                <Tooltip title="Profile">
                  <IconButton onClick={handleOpenUserMenu} sx={{}}>
                    <Avatar
                      alt={userName}
                      src="/static/images/avatar/2.jpg"
                      sx={{ height: 50, width: 50, background: "#EC0673", 
                      '&:hover': {
                        color: "black",
                        backgroundColor: "rgb(255, 210, 235)",
                      },
                    }}
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "50px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                  PaperProps={{
                    style: {
                      height: 170,
                      width: "15%",
                      backgroundColor: "#FFF9EC",
                    },
                  }}
                  style={{
                    flexGrow: 1,
                  }}
                >
                  <MenuItem
                    sx={{
                      display: "flex",
                      padding: "10px 10px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography textAlign="center" style={{fontFamily: "Avenir", fontWeight:"bold", color: "black"}}>
                      Hello!{" "}
                      <span style={{ color: "#EC0673" }}> {userName}</span>
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={handleCloseNavMenu} 
                  sx={{
                        display: "flex",
                        padding: "10px 10px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}>
                    <NavLink
                      to={"/profile"}
                      onClick={handleCloseNavMenu}
                      style={({ isActive, isPending, isTransitioning }) => {
                        return {
                          fontWeight: isActive ? "bold" : "lighter",
                          color: "black",
                          textDecoration: "none",
                          viewTransitionName: isTransitioning ? "" : "",
                        };
                      }}
                    >
               {({ isActive }) => (
      <Typography
        sx={{
        ...fontStyle.ContentFont,
        position: "relative",
        "&:hover": {
          color: "#EC0673",
        },
        fontWeight: "bold",
        "&::after": {
          content: '""',
          display: isActive ? 'block' : 'none',
          width: '20px',
          height: '4px',
          backgroundColor: '#EC0673',
          position: 'absolute',
          bottom: '-3px', // Adjust as needed
          left: '10%',
          transform: 'translateX(-50%)'
        },
        }}
       
      >
        My Profile
      </Typography>
    )} 
    
                    </NavLink>
                  </MenuItem>
                  <MenuItem
                   
                    sx={{
                      display: "flex",
                      padding: "10px 10px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      onClick={() => LoggedOut()}
                      sx={{
                        color: "white",
                        // borderRadius: 4,

                        fontSize: 15,
                        background:
                          "#EC0673",
                        fontWeight: "normal",
                        
                        "&:hover": {
                          backgroundColor: "rgb(255, 210, 235)", // Change background color on hover
                          color: "black", // Change text color on hover
                        },
                      }}
                      
                    >
                      <CiLogout style={{  fontSize: 25 }} />
                      <Typography
                        style={{  margin: 2, fontSize: 13, fontFamily: "Avenir" }}
                       
                      >
                        LogOut
                      </Typography>
                    </Button>
                  </MenuItem>
                </Menu>
              </Box>
            )}
          </Box>
        </Toolbar>
      {/* </Container> */}
    </AppBar>
    </Grid>
  );
}
export default NavBar;
