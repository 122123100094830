import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { clearForm } from "../../utils/localStorage";

const NotFoundPage = () => {

  useEffect(() => {
    clearForm();
  }, []);

  return (
    // <div>
    //   <h1>404 - Not Found</h1>
    //   <p>The page you're looking for does not exist.</p>
    // </div>

    
    <Grid
      container
      style={{
        alignContent: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#FFD2EB",
        flexDirection: "column",
      }}
    >
      <Typography
        style={{
          fontSize: 100,
          marginBottom: -30,
          color: "#EC0673",
        }}
      >
        404
      </Typography>
      <h1 style={{ marginBottom: 0 }}>OPPS! PAGE NOT FOUND</h1>
      <h3 style={{ paddingBottom: "1%" }}>
        The page you're looking for does not exist.
      </h3>
      <Link to={"/"}>
        <Button
          style={{
            background:
              "linear-gradient(to right,  #f35491, #fe5356,  #ee7724)",
            borderRadius: 5,
            width: 250,
            height: 50,
            textTransform: "none",
          }}
        >
          <Typography
            style={{ fontSize: 18, fontWeight: "normal", color: "white" }}
          >
            Back to Home Page
          </Typography>
        </Button>
      </Link>
    </Grid>
  );
};

export default NotFoundPage;
