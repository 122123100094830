import { Grid, Typography } from "@mui/material";
import fontStyle from "../../../assets/fontStyle";
import React from "react";

const Content = () => {
  return (
    <Grid container sx={{  display: "flex", justifyContent: "center", backgroundColor: "rgba(248, 248, 248, 1)",  margin: "0 auto" }}>
    <Grid
      container
      style={{
        // height: 420,
        height: "auto", // Set height to auto to adjust based on content
        maxHeight: 50000, // Limit the maximum height of the container
        backgroundColor: "rgba(248, 248, 248, 1)",
        display: "flex",
        alignContent: "center",
        flexDirection: "column",
        maxWidth: "1400px",
        // justifyContent: "center",
        padding:  window.innerWidth > 1400 ? "40px 0" : "40px 7% 40px 7%",
        // padding:
        //   window.innerWidth < 300
        //     ? "10px 7%"
        //     : window.innerWidth > 600
        //     ? "40px 7%"
        //     : "10px 7%",
      }}
    >
      {/* <div style={{ marginBottom: 10 }}>
      
        <div>
          
          <Typography style={{ fontSize: 16, color: "#A5A4A4" }}>
            These terms and conditions outline the rules and regulations for the
            use of makemybiodata.in's Website, located at
            www.makemybiodata.in. By accessing this website we assume you
            accept these terms and conditions. Do not continue to use Make My
            BioData if you do not agree to take all of the terms and conditions
            stated on this page.
          </Typography>
        </div>
      </div> */}

      <div style={{ marginBottom: 10 }}>
        {/* <h2 style={{ color: "black" }}>Types of Data Collected</h2> */}
        <div>
          {/* <h3 style={{ color: "black" }}>Personal Data</h3> */}
          <Typography style={fontStyle.Contentfont1}>
            The following terminology applies to these Terms and Conditions,
            Privacy Statement and Disclaimer Notice and all Agreements:
            "Client", "You" and "Your" refers to you, the person log on this
            website and compliant to the Company's terms and conditions. "The
            Company", "Ourselves", "We", "Our" and "Us", refers to our Company.
            "Party", "Parties", or "Us", refers to both the Client and
            ourselves. All terms refer to the offer, acceptance and
            consideration of payment necessary to undertake the process of our
            assistance to the Client in the most appropriate manner for the
            express purpose of meeting the Client's needs in respect of
            provision of the Company's stated services, in accordance with and
            subject to, prevailing law of in. Any use of the above terminology
            or other words in the singular, plural, capitalization and/or he/she
            or they, are taken as interchangeable and therefore as referring to
            same.
          </Typography>
        </div>
      </div>

      <div style={{ marginBottom: 10 }}>
        <h2 style={fontStyle.ContentHeading}>Cookies</h2>
        <div>
          {/* <h3 style={{ color: "black" }}>Personal Data</h3> */}
          <Typography style={fontStyle.Contentfont1}>
            We employ the use of cookies. By accessing Make My BioData, you
            agreed to use cookies in agreement with the makemybiodata.in's
            Privacy Policy. Most interactive websites use cookies to let us
            retrieve the user's details for each visit. Cookies are used by our
            website to enable the functionality of certain areas to make it
            easier for people visiting our website. Some of our
            affiliate/advertising partners may also use cookies.
          </Typography>
        </div>
      </div>

      <div style={{ marginBottom: 10 }}>
        <h2 style={fontStyle.ContentHeading}>License</h2>
        <div>
          {/* <h3 style={{ color: "black" }}>Personal Data</h3> */}
          <Typography
            style={fontStyle.Contentfont1}
          >
            Unless otherwise stated, makemybiodata.in and/or its licensors own
            the intellectual property rights for all material on Make My
            BioData. All intellectual property rights are reserved. You may
            access this from Make My BioData for your own personal use subjected
            to restrictions set in these terms and conditions.
          </Typography>
          <Typography
            style={fontStyle.Contentfont1}
          >
            You must not:
            <Typography
              style={fontStyle.Contentfont2}
            >
              <span style={{ fontWeight: "bold", color: "black" }}>•</span>{" "}
              Republish material from Make My BioData
              <br />
              <span style={{ fontWeight: "bold", color: "black" }}>•</span>{" "}
              Sell, rent or sub-license material from Make My BioData
              <br />
              <span style={{ fontWeight: "bold", color: "black" }}>•</span>{" "}
              Reproduce, duplicate or copy material from Make My BioData
              <br />
              <span style={{ fontWeight: "bold", color: "black" }}>•</span>{" "}
              Redistribute content from Make My BioData
            </Typography>
          </Typography>
          <Typography
            style={fontStyle.Contentfont1}
          >
            This Agreement shall begin on the date hereof. Our Terms and
            Conditions were created with the help of the Free Terms and
            Conditions Generator.
            <br />
            Parts of this website offer an opportunity for users to post and
            exchange opinions and information in certain areas of the website.
            makemybiodata.in does not filter, edit, publish or review Comments
            prior to their presence on the website. Comments do not reflect the
            views and opinions of makemybiodata.in,its agents and/or
            affiliates. Comments reflect the views and opinions of the person
            who post their views and opinions. To the extent permitted by
            applicable laws, makemybiodata.in shall not be liable for the
            Comments or for any liability, damages or expenses caused and/or
            suffered as a result of any use of and/or posting of and/or
            appearance of the Comments on this website.
            <br />
            makemybiodata.in reserves the right to monitor all Comments and to
            remove any Comments which can be considered inappropriate, offensive
            or causes breach of these Terms and Conditions.
            <br />
          </Typography>
          <Typography
            style={fontStyle.Contentfont1}
          >
            You warrant and represent that:
            <Typography
              style={fontStyle.Contentfont2}
            >
              <span style={{ fontWeight: "bold", color: "black" }}>•</span> You
              are entitled to post the Comments on our website and have all
              necessary licenses and consents to do so;
              <br />
              <span style={{ fontWeight: "bold", color: "black" }}>•</span> The
              Comments do not invade any intellectual property right, including
              without limitation copyright, patent or trademark of any third
              party;
              <br />
              <span style={{ fontWeight: "bold", color: "black" }}>•</span> The
              Comments do not contain any defamatory, libelous, offensive,
              indecent or otherwise unlawful material which is an invasion of
              privacy
              <br />
              <span style={{ fontWeight: "bold", color: "black" }}>•</span> The
              Comments will not be used to solicit or promote business or custom
              or present commercial activities or unlawful activity.
              <br />
            </Typography>
          </Typography>
          <Typography
            style={fontStyle.Contentfont1}
          >
            You hereby grant makemybiodata.in a non-exclusive license to use,
            reproduce, edit and authorize others to use, reproduce and edit any
            of your Comments in any and all forms, formats or media.
          </Typography>
        </div>
      </div>

      <div style={{ marginBottom: 10 }}>
        <h2 style={fontStyle.ContentHeading}>Hyperlinking to our Content</h2>
        <div>
          {/* <h3 style={{ color: "black" }}>Personal Data</h3> */}
          <Typography
            style={fontStyle.Contentfont1}
          >
            The following organizations may link to our Website without prior
            written approval:
            <Typography
              style={fontStyle.Contentfont2}
            >
              <span style={{ fontWeight: "bold", color: "black" }}>•</span>{" "}
              Government agencies;
              <br />
              <span style={{ fontWeight: "bold", color: "black" }}>•</span>{" "}
              Search engines;
              <br />
              <span style={{ fontWeight: "bold", color: "black" }}>•</span> News
              organizations;
              <br />
              <span style={{ fontWeight: "bold", color: "black" }}>•</span>{" "}
              Online directory distributors may link to our Website in the same
              manner as they hyperlink to the Websites of other listed
              businesses; and <br />
              <span style={{ fontWeight: "bold", color: "black" }}>•</span>{" "}
              System wide Accredited Businesses except soliciting non-profit
              organizations, charity shopping malls, and charity fundraising
              groups which may not hyperlink to our Web site.
            </Typography>
          </Typography>
          <Typography
            style={fontStyle.Contentfont1}
          >
            These organizations may link to our home page, to publications or to
            other Website information so long as the link: (a) is not in any way
            deceptive; (b) does not falsely imply sponsorship, endorsement or
            approval of the linking party and its products and/or services; and
            (c) fits within the context of the linking party's site.
          </Typography>

          <Typography
            style={fontStyle.Contentfont1}
          >
            We may consider and approve other link requests from the following
            types of organizations:
            <Typography
              style={fontStyle.Contentfont2}
            >
              <span style={{ fontWeight: "bold", color: "black" }}>•</span>{" "}
              commonly-known consumer and/or business information sources;
              <br />
              <span style={{ fontWeight: "bold", color: "black" }}>•</span>{" "}
              dot.com community sites;
              <br />
              <span style={{ fontWeight: "bold", color: "black" }}>•</span>{" "}
              associations or other groups representing charities;
              <br />
              <span style={{ fontWeight: "bold", color: "black" }}>•</span>{" "}
              online directory distributors;
              <br />
              <span style={{ fontWeight: "bold", color: "black" }}>•</span>{" "}
              internet portals;
              <br />
              <span style={{ fontWeight: "bold", color: "black" }}>•</span>{" "}
              accounting, law and consulting firms; and
              <br />
              <span style={{ fontWeight: "bold", color: "black" }}>•</span>{" "}
              educational institutions and trade associations.
              <br />
            </Typography>
          </Typography>
          <Typography
            style={fontStyle.Contentfont1}
          >
            We will approve link requests from these organizations if we decide
            that: (a) the link would not make us look unfavorably to ourselves
            or to our accredited businesses; (b) the organization does not have
            any negative records with us; (c) the benefit to us from the
            visibility of the hyperlink compensates the absence of
            makemybiodata.in; and (d) the link is in the context of general
            resource information.
            <br />
            These organizations may link to our home page so long as the link:
            (a) is not in any way deceptive; (b) does not falsely imply
            sponsorship, endorsement or approval of the linking party and its
            products or services; and (c) fits within the context of the linking
            party's site.
            <br /> If you are one of the organizations listed in paragraph 2
            above and are interested in linking to our website, you must inform
            us by sending an e-mail to makemybiodata.in. Please include your
            name, your organization name, contact information as well as the URL
            of your site, a list of any URLs from which you intend to link to
            our Website, and a list of the URLs on our site to which you would
            like to link. Wait 2-3 weeks for a response.
          </Typography>

          <Typography
            style={fontStyle.Contentfont1}
          >
            Approved organizations may hyperlink to our Website as follows:
            <Typography
              style={fontStyle.Contentfont2}
            >
              <span style={{ fontWeight: "bold", color: "black" }}>•</span> By
              use of our corporate name; or
              <br />
              <span style={{ fontWeight: "bold", color: "black" }}>•</span> By
              use of the uniform resource locator being linked to; or
              <br />
              <span style={{ fontWeight: "bold", color: "black" }}>•</span> By
              use of any other description of our Website being linked to that
              makes sense within the context and format of content on the
              linking party's site.
              <br />
            </Typography>
          </Typography>
          <Typography
            style={fontStyle.Contentfont1}
          >
            No use of makemybiodata.in's logo or other artwork will be allowed
            for linking absent a trademark license agreement.
          </Typography>
        </div>
      </div>

      <div style={{ marginBottom: 10 }}>
        <h2 style={fontStyle.ContentHeading}>iFrames</h2>
        <div>
          {/* <h3 style={{ color: "black" }}>Personal Data</h3> */}
          <Typography style={fontStyle.Contentfont1}>
            Without prior approval and written permission, you may not create
            frames around our Webpages that alter in any way the visual
            presentation or appearance of our Website.
          </Typography>
        </div>
      </div>

      <div style={{ marginBottom: 10 }}>
        <h2 style={fontStyle.ContentHeading}>Content Liability</h2>
        <div>
          {/* <h3 style={{ color: "black" }}>Personal Data</h3> */}
          <Typography style={fontStyle.Contentfont1}>
            We shall not be hold responsible for any content that appears on
            your Website. You agree to protect and defend us against all claims
            that is rising on your Website. No link(s) should appear on any
            Website that may be interpreted as libelous, obscene or criminal, or
            which infringes, otherwise violates, or advocates the infringement
            or other violation of, any third party rights.{" "}
          </Typography>
        </div>
      </div>

      <div style={{ marginBottom: 10 }}>
        <h2 style={fontStyle.ContentHeading}>Reservation of Rights</h2>
        <div>
          {/* <h3 style={{ color: "black" }}>Personal Data</h3> */}
          <Typography style={fontStyle.Contentfont1}>
            We reserve the right to request that you remove all links or any
            particular link to our Website. You approve to immediately remove
            all links to our Website upon request. We also reserve the right to
            amen these terms and conditions and it's linking policy at any time.
            By continuously linking to our Website, you agree to be bound to and
            follow these linking terms and conditions.{" "}
          </Typography>
        </div>
      </div>

      

      <div style={{ marginBottom: 10 }}>
        <h2 style={fontStyle.ContentHeading}>Removal of links from our website</h2>
        <div>
          
          <Typography style={fontStyle.Contentfont1}>
            If you find any link on our Website that is offensive for any
            reason, you are free to contact and inform us any moment. We will
            consider requests to remove links but we are not obligated to or so
            or to respond to you directly.
            <br />
            We do not ensure that the information on this website is correct, we
            do not warrant its completeness or accuracy; nor do we promise to
            ensure that the website remains available or that the material on
            the website is kept up to date.
          </Typography>
        </div>
      </div>

      {/* <div style={{ marginBottom: 10 }}>
        <h2 style={{ color: "black" }}>iFrames</h2>
        <div>
          <Typography style={fontStyle.Contentfont1}>
            Without prior approval and written permission, you may not create
            frames around our Webpages that alter in any way the visual
            presentation or appearance of our Website.
          </Typography>
        </div>
      </div>

      <div style={{ marginBottom: 10 }}>
        <h2 style={{ color: "black" }}>Content Liability</h2>
        <div>
          <Typography style={fontStyle.Contentfont1}>
            We shall not be hold responsible for any content that appears on
            your Website. You agree to protect and defend us against all claims
            that is rising on your Website. No link(s) should appear on any
            Website that may be interpreted as libelous, obscene or criminal, or
            which infringes, otherwise violates, or advocates the infringement
            or other violation of, any third party rights.
          </Typography>
        </div>
      </div>

      <div style={{ marginBottom: 10 }}>
        <h2 style={{ color: "black" }}>Reservation of Rights</h2>
        <div>
          
          <Typography style={fontStyle.Contentfont1}>
            We reserve the right to request that you remove all links or any
            particular link to our Website. You approve to immediately remove
            all links to our Website upon request. We also reserve the right to
            amen these terms and conditions and it's linking policy at any time.
            By continuously linking to our Website, you agree to be bound to and
            follow these linking terms and conditions.
          </Typography>
        </div>
      </div>

      <div style={{ marginBottom: 10 }}>
        <h2 style={{ color: "black" }}>Removal of links from our website</h2>
        <div>
          
          <Typography style={fontStyle.Contentfont1}>
            If you find any link on our Website that is offensive for any
            reason, you are free to contact and inform us any moment. We will
            consider requests to remove links but we are not obligated to or so
            or to respond to you directly.
            <br />
            We do not ensure that the information on this website is correct, we
            do not warrant its completeness or accuracy; nor do we promise to
            ensure that the website remains available or that the material on
            the website is kept up to date.
          </Typography>
        </div>
      </div> */}

      <div style={{ marginBottom: 10 }}>
        <h2 style={fontStyle.ContentHeading}>Disclaimer</h2>
        <div>
          {/* <h3 style={{ color: "black" }}>Personal Data</h3> */}
          <Typography style={fontStyle.Contentfont1}>
            To the maximum extent permitted by applicable law, we exclude all
            representations, warranties and conditions relating to our website
            and the use of this website. Nothing in this disclaimer will:
            <Typography
              style={fontStyle.Contentfont2}
            >
              <span style={{ fontWeight: "bold", color: "black" }}>•</span>{" "}
              limit or exclude our or your liability for death or personal
              injury;
              <br />
              <span style={{ fontWeight: "bold", color: "black" }}>•</span>{" "}
              limit or exclude our or your liability for fraud or fraudulent
              misrepresentation;
              <br />
              <span style={{ fontWeight: "bold", color: "black" }}>•</span>{" "}
              limit any of our or your liabilities in any way that is not
              permitted under applicable law; or
              <br />
              <span style={{ fontWeight: "bold", color: "black" }}>•</span>{" "}
              exclude any of our or your liabilities that may not be excluded
              under applicable law.
            </Typography>
          </Typography>
          <Typography
            style={fontStyle.Contentfont1}
          >
            The limitations and prohibitions of liability set in this Section
            and elsewhere in this disclaimer: (a) are subject to the preceding
            paragraph; and (b) govern all liabilities arising under the
            disclaimer, including liabilities arising in contract, in tort and
            for breach of statutory duty.
          </Typography>
        </div>
      </div>
    </Grid>
    </Grid>
  );
};

export default Content;
