import * as Yup from "yup";
import { phoneNumber } from "yup-phone-number";

export const signUpSchema = Yup.object({
  username: Yup.string().min(8).max(18).required("Please enter your username"),
  name: Yup.string().min(2).required("Please enter your full name"),
  email: Yup.string()
    .email("Please enter valid email")
    .required("Please enter your email"),
  phone2: Yup.string()
    .required("Phone number is required")
    .matches(/^\d{12,}$/, "Phone number must be exactly 10 digits")
    ,
  password: Yup.string().min(6).max(12).required("Please enter your password"),
});
