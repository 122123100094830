import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import NavBar from "../HomeScreen/NavBar";
import Footer1 from "../HomeScreen/Footer1";
import Footer2 from "../HomeScreen/Footer2";
import Footer3 from "../HomeScreen/Footer3";
import Footer4 from "../HomeScreen/Footer4";
import ContactForm from "./ContactForm";
import Head from "./Header";
import { clearForm } from "../../utils/localStorage";
import FooterNew from "../HomeScreen/FooterNew";


const ContactUs = () => {

  useEffect(() => {
    clearForm();
     // Scroll to the top of the page when the component mounts
     window.scrollTo(0, 0);
  }, []);

  return (
    <Grid
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // maxWidth: "1400px",
        margin: "0 auto", // Center the content horizontally
      }}
    >
      <Grid container style={{ backgroundColor: "", height: "96px" }}></Grid>
      <NavBar />
      <Head/>
     <ContactForm/>
      <Footer1 />
      {/* <Footer2 />
      <Footer4 /> */}
      <FooterNew/>
      <Footer3 />
    </Grid>
  );
};

export default ContactUs;
