import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import NavBar from "../HomeScreen/NavBar";
import Footer1 from "../HomeScreen/Footer1";
import Footer2 from "../HomeScreen/Footer2";
import Footer3 from "../HomeScreen/Footer3";
import Footer4 from "../HomeScreen/Footer4";
import BiodataMaker from "../HomeScreen/BiodataMaker";
import { GlobalStyles, ThemeProvider, createTheme } from '@mui/material';
import { Link } from "react-router-dom";
import fontStyle from "../../assets/fontStyle";
import Form from "./Form";
import { useSelector } from "react-redux";
import { IoMdDownload } from "react-icons/io";
import { Document, Page } from "react-pdf";
import FooterNew from "../HomeScreen/FooterNew";

const theme = createTheme();

const PersonalDetails = () => {
  const TemplateHTML = useSelector((store) => store.reducer.template);
  const isFormSubmit = useSelector((store) => store.reducer.submit);
  const bioid = useSelector((store) => store.reducer.biodataId);
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  // const pdfUrl = `https://drive.google.com/viewerng/viewer?embedded=true&url=${TemplatePdf}`;
  // function onDocumentLoadSuccess({ numPages }) {
  //   setNumPages(numPages);
  // }
  // const onButtonClick = () => {
  //   const link = document.createElement("a");
  //   link.href = pdfUrl;
  //   link.setAttribute("download", "document.pdf"); // This attribute may not work in all browsers
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  return (
    <ThemeProvider theme={theme}>
    <GlobalStyles styles={{ body: { overflow: 'auto !important' } }} />
    <Grid
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // maxWidth: "1400px",
        margin: "0 auto", // Center the content horizontally
      }}
    >
      <NavBar />
      <BiodataMaker />
      <div style={{ position: "relative", width: "100%" }}>
      <Link to={"/personaldetails"} style={{ textDecoration: "none" }}>
          <Button
          variant="contained"
            sx={{
              position: "absolute",
              color: "white",
              textTransform: "none",
              background: "#EC0673",
              top: "50%",
              width: 240,
              height: 50,
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 999, // Ensure the button appears above other components
              "&:hover": {
                    color: "black",
                    backgroundColor: "rgb(255, 210, 235)"
                  }
            }}
          >
            <Typography
              style={{
                fontSize: 20,
                fontFamily: fontStyle.HeadingFont,
                fontWeight: 400,
              }}
            >
              Make My BioData
            </Typography>
          </Button>
        </Link>
        </div>
      <Form />
      <Footer1 />
      {/* <Footer2 />
      <Footer4 /> */}
      <FooterNew/>
      <Footer3 />
    </Grid>
    </ThemeProvider>
  );
};

export default PersonalDetails;
