import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import ImagePath from "../../../assets/images";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import GradeIcon from "@mui/icons-material/Grade";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import fontStyle from "../../../assets/fontStyle";
import Style from "./style";
import React from "react";
import GradeOutlinedIcon from "@mui/icons-material/GradeOutlined";
import Avatar from "@mui/material/Avatar";

// function SampleNextArrow(props) {
//   const { className, style, onClick } = props;
//   return (
//     <ArrowForwardIosIcon
//       className={className}
//       style={{
//         ...style,
//         display: "block",
//         backgroundColor: "rgba(248, 248, 248, 1)",
//         borderRadius: 5,
//         color: "black",
//         fontSize:"1.5rem",
//         padding: "4px",
//         cursor: "pointer",
//       }}
//       onClick={onClick}
//     />
//   );
// }

// function SamplePrevArrow(props) {
//   const { className, style, onClick } = props;
//   return (
//     <ArrowBackIosNewIcon
//       className={className}
//       style={{
//         ...style,
//         display: "block",
//         backgroundColor: "rgba(248, 248, 248, 1)",
//         borderRadius: 5,
//         color: "black",
//         fontSize:"1.5rem",
//         padding: "4px",
//         cursor: "pointer",
//       }}
//       onClick={onClick}
//     />
//   );
// }

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <ArrowForwardIosIcon
      className={className}
      style={{
        ...style,
        display: "block",
        backgroundColor: "rgba(248, 248, 248, 1)",
        borderRadius: 5,
        fontSize:"1.6rem",
        color: "black",
        padding: "5px",
        cursor: "pointer",
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <ArrowBackIosNewIcon
      className={className}
      style={{
        ...style,
        display: "block",
        backgroundColor: "rgba(248, 248, 248, 1)",
        borderRadius: 5,
        fontSize:"1.6rem",
        color: "black",
        padding: "5px",
        cursor: "pointer",
      }}
      onClick={onClick}
    />
  );
}

const ClientData = () => {
  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerPadding: "60px",
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 855,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1030,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Grid container sx={{  display: "flex", justifyContent: "center", backgroundColor: "white",  margin: "0 auto" }}>
    <Grid
      container
      sx={{
        height: "auto",
        maxHeight: 1000,
        maxWidth: "1400px",
        padding: window.innerWidth > 1400 ? "0% 0 0% 0" : "0% 7% 0 7%",
        backgroundColor: "white",
      }}
    >
      <Grid
        sm={12}
        ls={12}
        md={12}
        xs={12}
        style={{
          height: "20%",
          // padding:
          //   window.innerWidth < 300
          //     ? "10px 0px"
          //     : window.innerWidth > 600
          //     ? "20px 00px"
          //     : "10px 0px",
          marginLeft: 15,
        }}
      >
        <h1 style={{fontFamily: "Enoway",
    color: "#EC0673",
    fontSize: 35,
    fontWeight: 400,
    letterSpacing: "0.1rem",textAlign:"center"}}>Testimonial</h1>
        <Typography style={{fontFamily: "Avenir", textAlign:"center"}}>
          See how MakeMyBioData helped real people find their perfect match.
          Read their stories below!
        </Typography>
      </Grid>

      <Grid sm={12} ls={12} md={12} xs={12} style={{ height: "80%" }}>
        <Grid
          sm={12}
          ls={12}
          md={12}
          xs={12}
          style={{
            // backgroundColor: "white",
            height: "95%",
            padding: window.innerWidth > 1400 ? "50px 4%" : "50px 0",
            // marginLeft: 15,
          }}
        >
          <Slider {...settings} style={{ paddingLeft: 20, paddingRight: 0 }}>
            <Box sx={{ width: 250, height: 300 }}>
              <Card style={Style.card}>
                <CardContent style={Style.cardContent}>
                  <Typography style={Style.cardText}>
                    “makemybiodata.in's biodata helped me find Sameer, my
                    travel buddy for life! We wouldn't have connected without
                    it. Highly recommend!”
                  </Typography>
                  <div style={{ margin: 10 }}>
                    <GradeIcon style={{ color: "gold" }} />
                    <GradeIcon style={{ color: "gold" }} />
                    <GradeIcon style={{ color: "gold" }} />
                    <GradeIcon style={{ color: "gold" }} />
                    <GradeIcon style={{ color: "gold" }} />
                  </div>
                  {/* <img
                    style={{
                      width: 40,
                      height: 40,
                      borderRadius: "50%",
                      margin: 7,
                    }}
                    src={ImagePath.Client1}
                  ></img> */}
                  <Avatar
                    alt="Priya"
                    src="/static/images/avatar/2.jpg"
                    style={Style.avatar}
                  />
                  <Typography style={Style.avatarName}>Priya</Typography>
                  <Typography style={Style.avatarCity}>Mumbai</Typography>
                </CardContent>
              </Card>
            </Box>

            <Box sx={{ width: 250, height: 300 }}>
              <Card style={Style.card}>
                <CardContent style={Style.cardContent}>
                  <Typography style={Style.cardText}>
                    “MakeMyBioData's stylish templates landed me Anjali, the
                    musician of my dreams. Thanks for the perfect match!”
                  </Typography>
                  <div style={{ margin: 10 }}>
                    <GradeIcon style={{ color: "gold" }} />
                    <GradeIcon style={{ color: "gold" }} />
                    <GradeIcon style={{ color: "gold" }} />
                    <GradeIcon style={{ color: "gold" }} />
                    <GradeOutlinedIcon style={{ color: "gold" }} />
                  </div>
                  <Avatar
                    alt="Rahul"
                    src="/static/images/avatar/2.jpg"
                    style={Style.avatar}
                  />
                  <Typography style={Style.avatarName}>Rahul</Typography>
                  <Typography style={Style.avatarCity}>Bangalore</Typography>
                </CardContent>
              </Card>
            </Box>

            <Box sx={{ width: 250 }}>
              <Card style={Style.card}>
                <CardContent style={Style.cardContent}>
                  <Typography style={Style.cardText}>
                    "MakeMyBioData let me showcase my passion for teaching.
                    That's how I met Arjun, my supportive partner in love and
                    social work."
                  </Typography>
                  <div style={{ margin: 10 }}>
                    <GradeIcon style={{ color: "gold" }} />
                    <GradeIcon style={{ color: "gold" }} />
                    <GradeIcon style={{ color: "gold" }} />
                    <GradeIcon style={{ color: "gold" }} />
                    <GradeOutlinedIcon style={{ color: "gold" }} />
                  </div>
                  <Avatar
                    alt="Aisha"
                    src="/static/images/avatar/2.jpg"
                    style={Style.avatar}
                  />
                  <Typography style={Style.avatarName}>Aisha</Typography>
                  <Typography style={Style.avatarCity}>Hyderabad</Typography>
                </CardContent>
              </Card>
            </Box>

            <Box sx={{ width: 250 }}>
              <Card style={Style.card}>
                <CardContent style={Style.cardContent}>
                  <Typography style={Style.cardText}>
                    “MakeMyBioData helped me find Riya, my cricket-loving
                    teammate for life. Thanks for the online matchmaking win!”
                  </Typography>
                  <div style={{ margin: 10 }}>
                    <GradeIcon style={{ color: "gold" }} />
                    <GradeIcon style={{ color: "gold" }} />
                    <GradeIcon style={{ color: "gold" }} />
                    <GradeIcon style={{ color: "gold" }} />
                    <GradeIcon style={{ color: "gold" }} />
                  </div>
                  <Avatar
                    alt="Darshan"
                    src="/static/images/avatar/2.jpg"
                    style={Style.avatar}
                  />
                  <Typography style={Style.avatarName}>Darshan</Typography>
                  <Typography style={Style.avatarCity}>Chennai</Typography>
                </CardContent>
              </Card>
            </Box>

            <Box sx={{ width: 250 }}>
              <Card style={Style.card}>
                <CardContent style={Style.cardContent}>
                  <Typography style={Style.cardText}>
                    “Found the perfect balance! MakeMyBioData's biodata
                    highlighted my tradition and love for art. That's how I met
                    Akash, my artistic partner.”
                  </Typography>
                  <div style={{ margin: 10 }}>
                    <GradeIcon style={{ color: "gold" }} />
                    <GradeIcon style={{ color: "gold" }} />
                    <GradeIcon style={{ color: "gold" }} />
                    <GradeIcon style={{ color: "gold" }} />
                    <GradeIcon style={{ color: "gold" }} />
                  </div>
                  <Avatar
                    alt="Swati"
                    src="/static/images/avatar/2.jpg"
                    style={Style.avatar}
                  />
                  <Typography style={Style.avatarName}>Swati</Typography>
                  <Typography style={Style.avatarCity}>Ahmedabad</Typography>
                </CardContent>
              </Card>
            </Box>

            <Box sx={{ width: 250 }}>
              <Card style={Style.card}>
                <CardContent style={Style.cardContent}>
                  <Typography style={Style.cardText}>
                    “MakeMyBioData's biodata formats helped me find Priya, the
                    family-oriented woman I was looking for. We prioritize
                    family, thanks to MakeMyBioData!”
                  </Typography>
                  <div style={{ margin: 10 }}>
                    <GradeIcon style={{ color: "gold" }} />
                    <GradeIcon style={{ color: "gold" }} />
                    <GradeIcon style={{ color: "gold" }} />
                    <GradeIcon style={{ color: "gold" }} />
                    <GradeIcon style={{ color: "gold" }} />
                  </div>
                  <Avatar
                    alt="Amit"
                    src="/static/images/avatar/2.jpg"
                    style={Style.avatar}
                  />
                  <Typography style={Style.avatarName}>Amit</Typography>
                  <Typography style={Style.avatarCity}>Pune</Typography>
                </CardContent>
              </Card>
            </Box>
          </Slider>
        </Grid>
      </Grid>
    </Grid>
    </Grid>
  );
};

export default ClientData;
