import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import NavBar from "./NavBar";
import AboutBioData from "./AboutBioData";
import WelcomeBioData from "./WelcomeBioData";
import BioDataTemplate from "./BioDataTemplate";
import ClientData from "./ClientData";
import Footer1 from "./Footer1";
import Features from "./Features";
import Footer2 from "./Footer2";
import Footer3 from "./Footer3";
import Footer4 from "./Footer4";
import BiodataMaker from "./BiodataMaker";
import fontStyle from "../../assets/fontStyle";
import { Link } from "react-router-dom";
import { clearForm, getDeleteUser } from "../../utils/localStorage";
import { storeAuthData } from "../../Redux/action";
import { useDispatch } from "react-redux";
import FooterNew from "./FooterNew";
import CircularProgress from "../CircularProgress";

const HomeScreen = () => {
  const dispatch = useDispatch();
  const isUserDelete = getDeleteUser();
  console.log(isUserDelete);
  useEffect(() => {
    if(isUserDelete){
      dispatch(storeAuthData(false));
    }
  }, [])

  useEffect(() => {
    clearForm();
     // Scroll to the top of the page when the component mounts
     window.scrollTo(0, 0);
  }, []);

  const [progress, setProgress] = useState(0);

  const updateProgress = () => {
    const scrollTop = window.scrollY;
    const docHeight = document.body.scrollHeight - window.innerHeight;
    const scrolled = (scrollTop / docHeight) * 100;
    setProgress(scrolled);
  };

  useEffect(() => {
    window.addEventListener('scroll', updateProgress);
    return () => window.removeEventListener('scroll', updateProgress);
  }, []);

  const circumference = 2 * Math.PI * 25; // Assuming radius is 25
  const offset = circumference - (progress / 100) * circumference;

  return (
    
    <Grid
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // maxWidth: "1400px",
        margin: "0 auto", // Center the content horizontally
      }}
    >
      <NavBar />
      <BiodataMaker />
       <div style={{ position: "relative", width: "100%" }}>
      <Link to={"/personaldetails"} style={{ textDecoration: "none" }}>
          <Button
          variant="contained"
            sx={{
              position: "absolute",
              color: "white",
              textTransform: "none",
              background: "#EC0673",
              top: "50%",
              width: 240,
              height: 50,
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 999, // Ensure the button appears above other components
              "&:hover": {
                    color: "black",
                    backgroundColor: "rgb(255, 210, 235)"
                  }
            }}
          >
            <Typography
              style={{
                fontSize: 20,
                fontFamily: fontStyle.HeadingFont,
                fontWeight: 400,
              }}
            >
              Make My BioData
            </Typography>
          </Button>
        </Link>
        </div>
      <BioDataTemplate />
      <AboutBioData />
      <Features />
      <WelcomeBioData />
      <ClientData />
      <Footer1 />
      
      <FooterNew/>
      <Footer3 />
      {/* <CircularProgress/> */}
    </Grid>
  );
};

export default HomeScreen;
