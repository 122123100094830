import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RiDeleteBin6Fill } from "react-icons/ri";
import Cropper from "react-easy-crop";
import Slider from "@mui/material/Slider";
import { generateDownload } from "../../../utils/cropImage";
import ImagePath from "../../../assets/images";
import { FaPencilAlt } from "react-icons/fa";
import Modal from "react-modal";
import { MdCancel } from "react-icons/md";
import { BiSolidSelectMultiple } from "react-icons/bi";
import { MdOutlineDelete } from "react-icons/md";
import Swal from "sweetalert2";
import Avatar from "@mui/material/Avatar";
import { format } from "date-fns";
import {
  clearProPass,
  getUserId1,
  isLoggedOut,
  loginUserAt,
  loginUserCcode,
  loginUserEmail,
  loginUserName,
  loginUserPhone,
  setDeleteUser,
} from "../../../utils/localStorage";
import {
  proPassChange,
  storeAuthData,
  storeUserInfo,
} from "../../../Redux/action";
import { RiLockPasswordLine } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { deleteAccService } from "../../../services/ApiService";
import toast from "react-hot-toast";

const UserData = () => {
  const User = useSelector((store) => store.reducer.user);
  const [user, setUser] = React.useState(loginUserName());
  const [email, setEmail] = React.useState(loginUserEmail());
  const [userAt, setUserAt] = React.useState(loginUserAt());
  const [code, setCode] = React.useState(loginUserCcode());
  const [phone, setPhone] = React.useState(loginUserPhone());
  const [id, setId] = React.useState(getUserId1());

  console.log("pro id:", id);

  // Formatting the date using date-fns
  const formattedDate = format(userAt, "dd-MM-yyyy");
  console.log("userAt", userAt);

  //   const isAuthenticated = useSelector((store) => store.reducer.isAuth);
  //   const dispatch = useDispatch();
  //   const isUserData = useMemo( () => {
  //     const isLoginData = loginUserData();
  //     if (User) {
  //       return User;
  //     } else {
  //       isLoginData && dispatch(storeUserInfo(isLoginData));
  //       console.log(isLoginData);
  //       console.log(User);
  //       return isLoginData;
  //     }
  //   }, [User]);

  console.log("checkUser data", user);

  const [imageNew, setImageNew] = useState(null);
  const [croppedArea, setCroppedArea] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [selectedImage, setSelectedImage] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inputRef = useRef();
  const handleImageClick = () => {
    inputRef.current.click();
  };

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    console.log(croppedAreaPercentage, croppedAreaPixels);
    setCroppedArea(croppedAreaPixels);
  };

  const triggerFileSelectPopup = () => inputRef.current.click();

  const onSelectFile = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setModalIsOpen(true);
      setSelectedImage(null);
      const render = new FileReader();
      render.readAsDataURL(event.target.files[0]);
      render.addEventListener("load", () => {
        console.log(render.result);
        setImageNew(render.result);
      });
    }
  };

  const handleSelectCroppedImage = () => {
    if (imageNew) {
      // Create a canvas to draw the cropped image
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      // Set canvas dimensions to match the cropped area
      canvas.width = crop.width;
      canvas.height = crop.height;

      // Draw the cropped image onto the canvas
      ctx.drawImage(
        imageNew, // Ensure imageNew is not null
        crop.x,
        crop.y,
        crop.width,
        crop.height,
        0,
        0,
        crop.width,
        crop.height
      );

      // Convert the canvas content to a data URL representing the cropped image
      const croppedImageUrl = canvas.toDataURL("image/jpeg");

      // Update the state with the selected cropped image URL
      setSelectedImage(croppedImageUrl);
    }
  };

  const onDownload = async () => {
    const { imageURL, imageBlob } = await generateDownload(
      imageNew,
      croppedArea
    );
    setSelectedImage(imageURL);
    setImageNew(null);
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);

  // Function to handle opening the modal
  const openModal = () => {
    setModalIsOpen(true);
  };

  // Function to handle closing the modal
  const closeModal = () => {
    setModalIsOpen(false);
    setImageNew(null);
  };

  const resetPass = () => {
    navigate("/userresetpassword");
    dispatch(proPassChange(false));
    clearProPass();
  };

  const deleteAcc = async () => {
    try {
      await new Promise((resolve, reject) => {
        Swal.fire({
          title:
            "Are you sure you want to delete your account? This action cannot be undo!",
          showDenyButton: true,
          confirmButtonText: "Yes",
          customClass: {
            confirmButton: "btnlogoutconfirm",
            denyButton: "btnlogoutdeny",
          },
        }).then(async (result) => {
          if (result.isConfirmed) {
            // Use await inside the then block
            const response = await deleteAccService(id);
            if (response.status === "success") {
              isLoggedOut();
              setDeleteUser(response);
              // dispatch(storeAuthData(false));
              navigate("/");
              console.log(response);
              resolve(); // Resolve the Promise if the action is confirmed
            }
          } else if (result.isDenied) {
            reject(); // Reject the Promise if the action is denied
          }
        });
      });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <Grid
      container
      sx={{
        display: "flex",
        justifyContent: "center",
        backgroundImage: "url(" + ImagePath.Desing1 + ")",
        backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        margin: "0 auto",
      }}
    >
      <Grid
        container
        style={{
          // height: 420,
          height: "auto", // Set height to auto to adjust based on content
          // maxHeight: 1200000, // Limit the maximum height of the container
          // background:
          //   "linear-gradient(to left,  rgba(255, 155, 214, 1), rgba(255, 228, 243, 1))",
          
          padding: window.innerWidth > 1400 ? "15px 0" : "15px 0 15px 0",
          display: "flex",

          maxWidth: "1200px",
          // justifyContent: "center",
          // padding:
          //   window.innerWidth < 300
          //     ? "10px 7%"
          //     : window.innerWidth > 600
          //     ? "20px 7%"
          //     : "10px 7%",
        }}
      >
        <Grid
          container
          style={{
            // border: "1px solid black",
            padding: "10px 10px",
            background: "rgb(255, 210, 235)",
          }}
        >
          <Grid
            item
            style={{
              // backgroundColor: "red",
              //   height: "200px",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              padding: window.innerWidth > 900 ? "100px 0px 100px 0" : "40px 0px 40px 0",
              flexDirection: "column",
              alignItems: "center",
            }}
            lg={6}
            md={6}
            sm={12}
            xs={12}
          >
            {/* <Avatar
          //   alt={User.name}
          src="/static/images/avatar/2.jpg"
          style={{ height: 100, width: 100 }}
        /> */}
            {/* <div>
            <div>
              {imageNew ? (
                <>
                  <div>
                    <Modal
                      isOpen={modalIsOpen}
                      onRequestClose={closeModal}
                      contentLabel="Crop Image Modal"
                      ariaHideApp={false}
                      style={{
                        overlay: {
                          position: "fixed",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          backgroundColor: "rgba(0, 0, 0, 0.7)",
                          zIndex: 9999, // Make sure this is higher than other elements
                        },
                        content: {
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          width: "100%",
                          maxWidth: "550px",
                          maxHeight: "100%",
                          overflow: "auto",
                          backgroundColor: "white",
                          borderRadius: "5px",
                          padding: "20px",
                          zIndex: 10000, // Make sure this is higher than other elements
                        },
                      }}
                    >
                      <div>
                        <Cropper
                          style={{ width: "100%", height: "80vh" }}
                          image={imageNew}
                          crop={crop}
                          zoom={zoom}
                          aspect={1}
                          onCropChange={setCrop}
                          onZoomChange={setZoom}
                          onCropComplete={onCropComplete}
                        />
                        
                        <Button
                          style={{
                            backgroundColor: "white",
                            width: "30px",
                          }}
                          onClick={closeModal}
                        >
                          <MdCancel
                            style={{ fontSize: "20px", color: "red" }}
                          />
                        </Button>
                        <Button
                          style={{
                            backgroundColor: "white",
                            width: "30px",
                          }}
                          onClick={onDownload}
                        >
                          <BiSolidSelectMultiple style={{ fontSize: "20px" }} />
                        </Button>
                      </div>
                    </Modal>
                  </div>
                </>
              ) : (
                !selectedImage && (
                  <div onClick={handleImageClick}>
                    <Avatar
                      //   alt={user}
                      src="/static/images/avatar/2.jpg"
                      style={{ height: 100, width: 100, marginBottom: -20 }}
                    ></Avatar>
                    <button
                      style={{
                        backgroundColor: "blueviolet",
                        height: "30px",
                        borderRadius: "50%",
                        position: "absolute",
                        border: "none",
                        width: "30px",
                        marginLeft: 70,
                        zIndex: 10,

                        cursor: "pointer",
                      }}
                    >
                      <FaPencilAlt style={{ color: "white", fontSize: 15 }} />
                    </button>
                    <input
                      type="file"
                      accept="image/*"
                      ref={inputRef}
                      onChange={onSelectFile}
                      style={{ display: "none" }}
                    />
                  </div>
                )
              )}
            </div>
            {selectedImage && (
              <>
                <div onClick={handleImageClick}>
                  <Avatar
                    //   alt={User.name}
                    src={selectedImage}
                    style={{ height: 100, width: 100, marginBottom: -20 }}
                  ></Avatar>
                
                  <button
                    style={{
                      backgroundColor: "blueviolet",
                      height: "30px",
                      borderRadius: "50%",
                      position: "absolute",
                      border: "none",
                      width: "30px",
                      marginLeft: 70,
                      zIndex: 10,

                      cursor: "pointer",
                    }}
                  >
                    <FaPencilAlt style={{ color: "white", fontSize: 15 }} />
                  </button>
                  <input
                    type="file"
                    accept="image/*"
                    ref={inputRef}
                    onChange={onSelectFile}
                    style={{ display: "none" }}
                  />
                </div>
              </>
            )}
          </div> */}
            <div style={{ marginTop: 0 }}>
              <Typography
                variant="h3"
                style={{
                  marginTop: 0,
                  fontWeight: "BOLD",
                  fontFamily: "Enoway",
                  fontWeight: 400,
                  letterSpacing: "0.1rem",
                }}
              >
                Hello!
              </Typography>
              <Typography
                variant="h2"
                style={{
                  fontWeight: "BOLD",
                  color: "#EC0673",
                  fontFamily: "Enoway",

                  fontWeight: 400,
                  letterSpacing: "0.1rem",
                }}
              >
                {user}
              </Typography>
            </div>
          </Grid>

          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            style={{
              // backgroundColor: "blue",
              //   height: "200px",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              padding: "30px 0px",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Paper
              style={{
                width: "90%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignContent: "start",
              }}
            >
              {/* <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "end",
                paddingBottom: 30,
              }}
            >
              <Typography variant="h5" style={{ marginTop: 10 }}>
                User Profile Details
              </Typography>
            </div> */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "center",
                  paddingTop: 40,
                }}
              >
                <Table style={{ padding: 50 }}>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ fontFamily: "Avenir" }}
                      >
                        Created At:
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ color: "red", fontFamily: "Avenir" }}
                      >
                        {formattedDate}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="tr"
                        style={{ fontFamily: "Avenir" }}
                      >
                        Phone No:
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontFamily: "Avenir" }}
                      >
                        {code} {phone}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ fontFamily: "Avenir" }}
                      >
                        Email ID:
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontFamily: "Avenir" }}
                      >
                        {email}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    padding: "20px 50px",
                  }}
                >
                  {/* <Link to="/userresetpassword">
                  <Button
                    // fullWidth
                    variant="contained"
                    color="primary"
                    sx={{
                      color: "white",
                      borderRadius: 4,
                      textTransform: "NONE",
                      width: "24rem",
                      //   fontSize: 15,
                      background:
                        "linear-gradient(to right,  #f35491, #fe5356,  #ee7724)",
                      fontWeight: "normal",
                      color: "grey",
                    }}
                  >
                    
                    <Typography
                      style={{ color: "white", margin: 2, fontSize: 13 }}
                    >
                      <RiLockPasswordLine
                        style={{
                          fontSize: 18,
                          marginRight: 10,
                          marginBottom: -4,
                        }}
                      />
                      Change Password
                    </Typography>
                  </Button>
                </Link> */}

                  <Grid
                    container
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      // alignContent: "center",
                      // alignItems: "center"
                    }}
                  >
                    <Grid item xs={12} style={{ marginBottom: "1rem" }}>
                      {/* Set xs={12} to make the button take full width on extra small devices */}
                      {/* <Link to="/userresetpassword"> */}
                      <Button
                        onClick={resetPass}
                        fullWidth // Make the button take full width
                        variant="contained"
                        color="primary"
                        sx={{
                          // color: "white",
                          borderRadius: 4,
                          textTransform: "NONE",
                          background: "#EC0673",
                          fontWeight: "normal",
                          "&:hover": {
                            backgroundColor: "rgb(255, 210, 235)", // Change background color on hover
                            color: "black", // Change text color on hover
                          },
                        }}
                        onMouseOver={(e) =>
                          (e.currentTarget.style.backgroundColor =
                            "rgb(255, 210, 235)")
                        }
                        onMouseOut={(e) =>
                          (e.currentTarget.style.backgroundColor = "#EC0673")
                        }
                      >
                        <Typography
                          style={{
                            margin: 2,
                            fontSize: 13,
                            fontFamily: "Avenir",
                          }}
                        >
                          <RiLockPasswordLine
                            style={{
                              fontSize: 18,
                              marginRight: 10,
                              marginBottom: -4,
                            }}
                          />
                          Change Password
                        </Typography>
                      </Button>

                      {/* </Link> */}
                    </Grid>
                    <Typography
                      component="a"
                      onClick={deleteAcc}
                      sx={{
                        // display: "flex",
                        alignItems: "center",
                        textDecoration: "none",
                        color: "red",
                        cursor: "pointer",
                        // "&:hover": {
                        //   color: "rgb(255, 210, 235)", // Change text color on hover
                        // },
                      }}
                    >
                      <RiDeleteBin6Fill
                        style={{
                          fontSize: 18,
                          marginRight: 10,
                          marginBottom: -4,
                        }}
                      />
                      Delete Account
                    </Typography>
                  </Grid>
                </div>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserData;
