import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cropper from "react-easy-crop";
import Slider from "@mui/material/Slider";
import { generateDownload } from "../../../utils/cropImage";
import ImagePath from "../../../assets/images";
import { FaPencilAlt } from "react-icons/fa";
import Modal from "react-modal";
import { MdCancel } from "react-icons/md";
import { BiSolidSelectMultiple } from "react-icons/bi";
import Avatar from "@mui/material/Avatar";
import { format } from "date-fns";
import { FaPhoneVolume, FaEnvelope } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { FaMapMarkerAlt } from "react-icons/fa";
import {
  getUserId1,
  loginUserAt,
  loginUserCcode,
  loginUserEmail,
  loginUserName,
  loginUserPhone,
} from "../../../utils/localStorage";
import { storeUserInfo } from "../../../Redux/action";
import { RiLockPasswordLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Styles from "./style";
import { useFormik } from "formik";
import { signUpSchema } from "../../../schemas/signup";
import { postContactUs, registerService } from "../../../services/ApiService";
import { FaFacebookSquare } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
import { FaXTwitter } from "react-icons/fa6";
import { IoLogoYoutube } from "react-icons/io";
import { FaLinkedin } from "react-icons/fa";
import { contactUsSchema } from "../../../schemas/contactUsSchema";
import toast, { Toaster } from "react-hot-toast";

const CssTextField1 = styled(TextField)({
  "& label.Mui-focused": {
    color: "grey",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "red",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "lightgrey",
      borderRadius: 5,
      borderWidth: 1,
      background: "#ffd2eb33",
    },
    "&:hover fieldset": {
      borderColor: "#f35491",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#f35491",
      // backgroundColor: "grey",
    },
  },
});

const ContactForm = () => {
  const [error, setError] = React.useState(null);

  const [focusedField, setFocusedField] = useState("");
  const [initialValues, setInitialValues] = useState({
    user_id: getUserId1(),
    name: "",
    email_id: "",
    subject: "",
    message: "",
  });
  // const navigate = useNavigate();

  // Use useFormik hook to manage form state and validation
  const {
    values,
    errors,
    handleSubmit,
    handleBlur,
    handleChange,
    touched,
    isValid,
    resetForm
  } = useFormik({
    initialValues: initialValues,
    validationSchema: contactUsSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const response = await postContactUs(values);
        if (response.status === "success") {
          toast.success(response.message, {
            duration: 5000, // Duration in milliseconds
            position: "top-right", // Position of the toast
          });
          console.log(values);
          setError(null);
          console.log("User registered successfully:", response);
           // Reset form values here
        resetForm();
        } else if (response.status === "error") {
          console.log("error in create user:", response);
          setError(response.message);
        } else if (response.status === "fail") {
          console.log("error in create user:", response);
          setError(response.message);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  return (
    <Grid container sx={{  display: "flex", justifyContent: "center",  margin: "0 auto" }}>
    <Grid
      container
      style={{
        // height: 420,
        height: "auto", // Set height to auto to adjust based on content
        // maxHeight: 1200000, // Limit the maximum height of the container
        // background:
        //   "linear-gradient(to left,  rgba(255, 155, 214, 1), rgba(255, 228, 243, 1))",
        // backgroundColor: "rgba(124, 0, 31, 1)",
        display: "flex",
        padding:  window.innerWidth > 1400 ? "50px 0" : "50px 7% 50px 7%",
        maxWidth: "1400px",
        // justifyContent: "center",
        // padding:
        //   window.innerWidth < 300
        //     ? "50px 7%"
        //     : window.innerWidth > 600
        //     ? "50px 7%"
        //     : "50px 7%",
      }}
    >
      <Grid
        container
        style={{
          //   border: "1px solid black",
          //   padding: "10px 10px",
          //   background:
          //     "linear-gradient(to left,  rgba(255, 155, 214, 1), rgba(255, 228, 243, 1))",
          backgroundColor: "white",
          boxShadow:
            "4.4px 4.4px 2.2px rgba(0, 0, 0, 0.031), " +
            "9.6px 9.6px 5.3px rgba(0, 0, 0, 0.042), " +
            "16.3px 16.3px 10px rgba(0, 0, 0, 0.05), " +
            "26.4px 26.4px 17.9px rgba(0, 0, 0, 0.057), " +
            "45.1px 45.1px 33.4px rgba(0, 0, 0, 0.066), " +
            "100px 100px 80px rgba(0, 0, 0, 0.09)",
          borderRadius: 15,
        }}
      >
        <Grid
          item
          style={{
            // backgroundColor: "red",
            background:
              "rgb(255, 210, 235)",
            //   height: "200px",
            display: "flex",
            justifyContent: "start",
            alignContent: "start",
            padding: "35px 60px",
            flexDirection: "column",
            alignItems: "left",
            borderRadius: window.innerWidth > 900 ? "15px 0  0 15px" : "15px 15px 0 0",
            boxShadow:
              "2.8px 2.8px 2.2px rgba(0, 0, 0, -0.032)" +
              "6.7px 6.7px 5.3px rgba(0, 0, 0, -0.033)" +
              "12.5px 12.5px 10px rgba(0, 0, 0, -0.018)" +
              "22.3px 22.3px 17.9px rgba(0, 0, 0, 0.006)" +
              "41.8px 41.8px 33.4px rgba(0, 0, 0, 0.036)" +
              "100px 100px 80px rgba(0, 0, 0, 0.07)",
          }}
          lg={6}
          md={6}
          sm={12}
          xs={12}
        >
          {/* <h1>Contact Information</h1> */}
          <div style={{ marginBottom: 40 }}>
            <Typography
              style={{ fontSize: 30, fontWeight: "bold", color: "#EC0673", fontFamily: 'Enoway', 
              fontWeight: 400,
                  letterSpacing: "0.1rem", }}
            >
              Contact Information
            </Typography>
            {/* <Typography style={{ fontSize: 14, color: "white" }}>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s,
            </Typography> */}
          </div>

          <div>

          <div style={{ marginBottom: 30, display: "flex", alignItems: "center" }}>
            <Typography style={{ color: "#EC0673", fontFamily: 'Avenir', display: "flex", alignItems: "center"}}>
              <FaEnvelope style={{ fontSize: 17, marginRight: 10 }} />{" "}
              contact@makemybiodata.in
            </Typography>
          </div>

            <div
              style={{
                display: "flex",
                justifyContent: "start",
              }}
            >
              {/* <Link
                to={"/"}
                style={{
                  "&:hover": {
                    color: "pink",
                  },
                }}
              > */}
              <FaFacebookSquare
                style={{
                  marginRight: 25,
                  fontSize: 25,
                  color: "#EC0673",
                  display: "flex",
                  justifyContent: "center",
                  transition: "color 0.3s ease",
                }}
                onMouseOver={(e) =>
                  (e.currentTarget.style.color = "rgba(124, 0, 31, 1)")
                }
                onMouseOut={(e) => (e.currentTarget.style.color = "#EC0673")}
              />

              <FaXTwitter
                style={{
                  marginRight: 25,
                  fontSize: 25,
                  color: "#EC0673",
                  transition: "color 0.3s ease",
                }}
                onMouseOver={(e) =>
                  (e.currentTarget.style.color = "rgba(124, 0, 31, 1)")
                }
                onMouseOut={(e) => (e.currentTarget.style.color = "#EC0673")}
              />
              <BsInstagram
                style={{
                  marginRight: 25,
                  fontSize: 25,
                  color: "#EC0673",
                  transition: "color 0.3s ease",
                }}
                onMouseOver={(e) =>
                  (e.currentTarget.style.color = "rgba(124, 0, 31, 1)")
                }
                onMouseOut={(e) => (e.currentTarget.style.color = "#EC0673")}
              />
              <IoLogoYoutube
                style={{
                  marginRight: 25,
                  fontSize: 25,
                  color: "#EC0673",
                  transition: "color 0.3s ease",
                }}
                onMouseOver={(e) =>
                  (e.currentTarget.style.color = "rgba(124, 0, 31, 1)")
                }
                onMouseOut={(e) => (e.currentTarget.style.color = "#EC0673")}
              />
              <FaLinkedin
                style={{
                  marginRight: 25,
                  fontSize: 25,
                  color: "#EC0673",
                  transition: "color 0.3s ease",
                }}
                onMouseOver={(e) =>
                  (e.currentTarget.style.color = "rgba(124, 0, 31, 1)")
                }
                onMouseOut={(e) => (e.currentTarget.style.color = "#EC0673")}
              />
            </div>
          </div>
        </Grid>

        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          style={{
            display: "flex",
            //   justifyContent: "center",
            // alignContent: "center",
            
            borderRadius: window.innerWidth > 900 ? "0 15px 15px 0" : "0 0 15px 15px",
            padding: "40px 40px",
            flexDirection: "column",
            // alignItems: "center",
          }}
        >
          <Toaster toastOptions={{ duration: 4000 }} />
          <form
            autocomplete="false"
            onSubmit={(e) => {
              e.preventDefault(); // Prevent default form submission
              handleSubmit(); // Submit the form
            }}
          >
            {error && <h5 style={{ color: "red" }}>{error}</h5>}
            <div style={{ marginBottom: 25 }}>
              <CssTextField1
                variant="outlined"
                label="Your name"
                fullWidth
                margin="normal"
                type="text"
                name="name"
                id="name"
                autoComplete="new-password"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                style={{ height: "5px" }}
                inputProps={{
                  style: { zIndex: 1, fontSize: 14 },
                }}
                InputLabelProps={{
                  style: {
                    fontSize: 14,
                    alignItems: "center",
                    display: "flex",
                  },
                }}
              />

              {touched.name && errors.name && (
                <h5 style={{ marginBottom: -24 }} className="form-error">
                  {errors.name}
                </h5>
              )}
            </div>

            <div style={{ marginBottom: 25 }}>
              <CssTextField1
                variant="outlined"
                label="Your email"
                fullWidth
                type="email"
                margin="normal"
                name="email_id"
                id="email_id"
                autoComplete="false"
                value={values.email_id}
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                style={{ height: "6px" }}
                inputProps={{
                  style: { zIndex: 1, fontSize: 14 },
                }}
                InputLabelProps={{
                  style: {
                    fontSize: 14,
                    alignItems: "center",
                    display: "flex",
                  },
                }}
              />
              {touched.email_id && errors.email_id && (
                <h5 style={{ marginBottom: -24 }} className="form-error">
                  {errors.email_id}
                </h5>
              )}
            </div>

            <div style={{ marginBottom: 25 }}>
              <CssTextField1
                variant="outlined"
                label="Subject"
                fullWidth
                margin="normal"
                name="subject"
                id="subject"
                style={{ height: "7px" }}
                autoComplete="new-password"
                value={values.subject}
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                inputProps={{
                  style: { zIndex: 1, fontSize: 14 },
                }}
                InputLabelProps={{
                  style: {
                    fontSize: 14,
                    alignItems: "center",
                    display: "flex",
                  },
                }}
              />
              {touched.subject && errors.subject && (
                <h5 className="form-error" style={{ marginBottom: -21 }}>
                  {errors.subject}
                </h5>
              )}
            </div>

            <div style={{ marginBottom: 25 }}>
              <CssTextField1
                // id="standard-multiline-static"
                fullWidth
                label="Your message (optional)"
                multiline
                rows={5}
                variant="outlined"
                margin="normal"
                name="message"
                id="message"
                autoComplete="new-password"
                value={values.message}
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{
                  style: { zIndex: 1, fontSize: 14 },
                }}
                InputLabelProps={{
                  style: {
                    fontSize: 15,
                    alignItems: "center",
                    display: "flex",
                  },
                }}
              />
            </div>

            <Button
              variant="contained"
              color="primary"
              type="submit"
              // fullWidth
              sx={Styles.button}
            >
              <Typography style={{ fontSize: 20, fontWeight: "normal", fontFamily: 'Avenir' }}>
                Send Message
              </Typography>
            </Button>
          </form>
        </Grid>
      </Grid>
    </Grid>
    </Grid>
  );
};

export default ContactForm;
