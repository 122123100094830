import { Grid, Typography } from "@mui/material";
import ImagePath from "../../../assets/images";
import React from "react";

const Head = () => {
  return (
    <Grid
      container
      sx={{
        display: "flex",
        justifyContent: "center",
        backgroundImage: "url(" + ImagePath.Desing3 + ")",
        backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        margin: "0 auto",
      }}
    >
      <Grid
        container
        style={{
          // height: 420,
          height: "auto", // Set height to auto to adjust based on content
          // maxHeight: 1200000, // Limit the maximum height of the container
          display: "flex",
          alignContent: "center",
          flexDirection: "column",

          minHeight: "20rem",
          maxWidth: "1400px",
          justifyContent: "center",
          padding: window.innerWidth > 1400 ? 0 : "0 7% 0 7%",
          // padding:
          //   window.innerWidth < 300
          //     ? "10px 7%"
          //     : window.innerWidth > 600
          //     ? "20px 7%"
          //     : "10px 7%",
        }}
      >
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* <h5 style={{ color: "#EC0673", paddingBottom: "4%", fontFamily: 'Avenir' }}>
        Home> Contact Us
        </h5> */}
          <h1
            style={{
              color: "white",
              fontFamily: "Enoway",
              fontSize: 35,
              fontWeight: 400,
              letterSpacing: "0.1rem",
            }}
          >
            Contact Us
          </h1>

          <Typography
            style={{
              fontSize: "18px",
              fontFamily: "Avenir",
              textAlign: "center",
              color: "white",
            }}
          >
            Contact us- because carrier pigeons are so last century Got
            questions or just want to chat about Biodata? Reach out! <br />
            Fill the form below.
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};

export default Head;
