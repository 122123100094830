import { Grid, Typography } from "@mui/material";
import fontStyle from "../../../assets/fontStyle";
import React from "react";

const WelcomeBioData = () => {
  return (
    <Grid container sx={{ display: "flex", justifyContent: "center", backgroundColor: "white", margin: "0 auto" }}>

    </Grid>
  );
};

export default WelcomeBioData;
