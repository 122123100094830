import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@mui/material";
import fontStyle from "../../../assets/fontStyle";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TiMinus } from "react-icons/ti";
import { TiPlus } from "react-icons/ti";
import { faqService } from "../../../services/ApiService";

const Content = () => {

    const [faq, setFaq] = useState([]);
    const [expandedFirst, setExpandedFirst] = useState(0);
    const [expandedSecond, setExpandedSecond] = useState(false);
    useEffect(() => {
        const fetchFaq = async () => {
            try {
                const response = await faqService();
                if (response.status === "success") {
                    setFaq(response.data); // Set the FAQ data from the response
                    console.log(faq);
                } else {
                    console.error("Failed to fetch FAQs:", response.message);
                }
            } catch (error) {
                console.error("Error fetching FAQs:", error);
            }
        };
        fetchFaq();
    }, []);

    const half = Math.ceil(faq.length / 2);
    const firstColumn = faq.slice(0, half);
    const secondColumn = faq.slice(half);


    const handleChangeFirst = (panel) => (event, isExpanded) => {
        setExpandedFirst(isExpanded ? panel : false);
    };

    const handleChangeSecond = (panel) => (event, isExpanded) => {
        setExpandedSecond(isExpanded ? panel : false);
    };

  return (
    <Grid container sx={{  display: "flex", justifyContent: "center", backgroundColor: "rgba(248, 248, 248, 1)",  margin: "0 auto" }}>

    <Grid
      style={{
        // height: 420,
        height: "auto", // Set height to auto to adjust based on content
        maxHeight: 50000, // Limit the maximum height of the container
        backgroundColor: "rgba(248, 248, 248, 1)",
        display: "flex",
        alignContent: "center",
        flexDirection: "column",
        maxWidth: "1400px",
padding:  window.innerWidth > 1400 ? "40px 0" : "40px 7% 40px 7%",
       
      }}
    >
<Grid container spacing={{ xs: 0, sm: 0, md: 4 }}>
            <Grid item xs={12} sm={12} md={6}>
                {firstColumn.map((item, index) => (
                    <Accordion key={index} style={{ marginBottom: "20px", padding: "11px", border: "1px solid #e9ebed", boxShadow:"none" }} expanded={expandedFirst === index} 
                    onChange={handleChangeFirst(index)}>
                        <AccordionSummary
                            expandIcon={expandedFirst === index ?  <TiMinus style={{color:"black", fontSize:"20px"}}/> : <TiPlus style={{color:"#EC0673", fontSize:"20px"}}/>}
                            aria-controls={`panel${index + 1}a-content`}
                            id={`panel${index + 1}a-header`}
                            sx={{
                                color: expandedFirst === index ? '#EC0673' : '#242629'
                            }}
                        >
                            <Typography style={{fontFamily:"Enoway",fontWeight: 700, letterSpacing: "0.1rem",}}>{item.question}</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{lineHeight:"36px"}}>
                            <Typography style={{color:"#777a7e", fontFamily:"Avenir", fontSize:"16px", lineHeight:"36px"}}>{item.answer}</Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                {secondColumn.map((item, index) => (
                    <Accordion key={index} style={{ marginBottom: "20px", padding: "11px", border: "1px solid #e9ebed", boxShadow:"none" }} expanded={expandedSecond === index} 
                    onChange={handleChangeSecond(index)}>
                        <AccordionSummary
                           expandIcon={expandedSecond === index ?  <TiMinus style={{color:"black", fontSize:"20px"}}/> : <TiPlus style={{color:"#EC0673", fontSize:"20px"}}/>}
                            aria-controls={`panel${index + half + 1}a-content`}
                            id={`panel${index + half + 1}a-header`}
                            sx={{
                                color: expandedSecond === index ? '#EC0673' : '#242629'
                            }}
                        >
                            <Typography style={{fontFamily:"Enoway",fontWeight: 700, letterSpacing: "0.1rem",}}>{item.question}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography style={{color:"#777a7e", fontFamily:"Avenir", fontSize:"16px", lineHeight:"36px"}}>{item.answer}</Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Grid>
        </Grid>
    </Grid>
    </Grid>
  );
};

export default Content;
