const styles = {
  container: {
    height: "100vh",
    // maxWidth: "1400px",
  },

  gridWrapper: {
    position: "relative",
  },

  grid1: {
    backgroundColor: "#FFD2EB",
    // display: { sm: "none", xs: "none" },
    // backgroundImage: "linear-gradient(to bottom(#FFD2EB, transparent)",
    zIndex: 1,
  },

  image1: {
    width: "250px",
    height: "130px",
    display: "flex",
    marginLeft: -10,
    marginTop: -15,
  },

  grid11: {
    alignItems: "end",
    justifyContent: "center",
    display: "flex",
  },

  // image2: {
  //   height: "540px",
  //   position: "relative",
  // },

  image2: {
    height:
      window.innerWidth > 1400
        ? "650px"
        : window.innerWidth > 1290
        ? "600px"
        : window.innerWidth > 1030
        ? "550px"
        : window.innerWidth > 1010 && window.innerWidth < 1030
        ? "550px"
        : window.innerWidth > 900 && window.innerWidth < 1010
        ? "500px"
        : window.innerWidth > 950 &&
          window.innerWidth < 1030 &&
          window.innerHeight > 650
        ? "550px"
        : window.innerWidth > 850 && window.innerWidth < 900
        ? "570px"
        : window.innerWidth > 800 && window.innerWidth < 850
        ? "550px"
        : window.innerWidth > 720 && window.innerWidth < 800
        ? "540px"
        : window.innerWidth > 656 && window.innerWidth < 720
        ? "500px"
        : "450px",
    position: "relative",
  },

  // blurOverlay: {
  //   position: "absolute",
  //   display: "flex",
  //   // bottom: 0,
  //   // left: 0,
  //   top: window.innerWidth > 1200 ? -55 : 80,
  //   width:
  //     window.innerWidth < 550 ? 0 : window.innerWidth > 860 ? "50%" : "54.5%",
  //   // height:  window.innerWidth > 1290 ? "425px" : "108%",
  //   height:
  //     window.innerWidth == 1280 && window.innerHeight == 551
  //       ? "130vh"
  //       : window.innerWidth > 1020 && window.innerWidth < 1275
  //       ? window.innerHeight == 1366
  //         ? "94vh"
  //         : "96vh"
  //       : window.innerWidth > 1279
  //       ? "107vh"
  //       : "94vh",
  //   // backgroundColor: "red",
  //   background:
  //     window.innerWidth > 730
  //       ? "linear-gradient(to bottom,  transparent, transparent,  white)"
  //       : 0,
  //   zIndex: 10,
  // },

  blurOverlay: {
    position: "absolute",
    display: "flex",
    // backgroundColor: "red",
    background:
      window.innerWidth > 550
        ? "linear-gradient(to bottom,  transparent, white)"
        : 0,
    zIndex: 10,
    // width: "200px",
    height: "300px",
    // top: window.innerWidth > 1200 ? -55 : 80,
    width:
      window.innerWidth < 550 ? 0 : window.innerWidth > 900 ? "50%" : "54.5%",
    // height: "100%",
    justifyContent: "end",
    justifySelf: "flex-end",
    alignItems: "start",
    alignSelf: "end",
  },

  grid2: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    padding:
      window.innerWidth >= 1000
        ? 125
        : window.innerWidth > 900 && window.innerWidth < 1000
        ? 90
        : window.innerWidth < 350
        ? 15
        : 50,
  },

  form: {
    textAlign: "left",
    height: "425px",
    width: 500,
    marginTop: -100,
  },

  link1: {
    color: "grey",
    border: "none",
    textDecoration: "none",
    display: "flex",
    justifyContent: "right",
    height: "50px",
    fontSize: 14,
  },

  button: {
    height: 45,
    borderRadius: 5,
    background: "#EC0673",
    textDecoration: "none",
    boxShadow: "none",
    textTransform: "NONE",
    marginBottom: 15,
  },

  link2: {
    color: "#1E232C",
    border: "none",
    textDecoration: "none",
    display: "flex",
    justifyContent: "center",
    height: "35%",
    fontSize: 14,
  },

  link2div: {
    display: "flex",
    justifyContent: "center",
    height: "50px",
  },
};

export default styles;
