import React from "react";
import Card from "../images/MakemyBiodata_card.png";
import Iphone from "../images/iphone14.webp";
import Icon from "../images/MakemyBiodata_icon.png";
import Success from "../images/checked_password.png";
import ImageCard1 from "../images/imageCard1.png";
import ImageCard2 from "../images/imageCard2.png";
import ImageCard3 from "../images/imageCard3.jpg";
import ImageCard4 from "../images/imageCard4.jpg";
import ImageCard5 from "../images/imageCard5.png";
import ImageCard6 from "../images/imageCard6.png";
import Client1 from "../images/Client1.png";
import Client2 from "../images/Client2.png";
import Client3 from "../images/Client3.png";
import Client4 from "../images/Client4.png";
import BackDesign1 from "../images/backDesing1.png";
import TwoPhone from "../images/twoPhone.png";
import AppIcon from "../images/AppIcon.png";
import AppIcon1 from "../images/AppIcon1.png";
import AppStoreIcon from "../images/AppStoreIcon.png";
import BackDesign2 from "../images/backDesing2.png";
import Flower1 from "../images/flower1.png";
import Flower2 from "../images/flower2.png";
import Flower3 from "../images/flower3.png";
import Flower4 from "../images/flower4.jpeg";
import Flower5 from "../images/flower5.jpeg";
import Flower6 from "../images/flower6.jpeg";
import Flower7 from "../images/flower7.jpeg";
import Flower8 from "../images/flower8.jpeg";
import Picon from "../images/premiumIcon.png";
import ImageUpload from "../images/imageUpload.png";
import appstore from "../images/appstore.png";
import playstore from "../images/playstore.png";
import backDesingNew from "../images/backDesingNew.jpeg";
import backDesingNew1 from "../images/backDesingNew1.jpeg";
import Desing1 from "../images/Desing1.svg";
import Desing2 from "../images/Desing2.svg";
import Desing3 from "../images/Desing3.svg";


const ImagePath = {
  Card,
  Iphone,
  Icon,
  Success,
  ImageCard1,
  ImageCard2,
  ImageCard3,
  ImageCard4,
  ImageCard5,
  ImageCard6,
  Client1,
  Client2,
  Client3,
  Client4,
  BackDesign1,
  TwoPhone,
  AppIcon,
  AppIcon1,
  AppStoreIcon,
  BackDesign2,
  Flower1,
  Flower2,
  Flower3,
  Flower4,
  Flower5,
  Flower6,
  Flower7,
  Flower8,
  Picon, 
  ImageUpload,
  appstore,
  playstore,
  backDesingNew,
  backDesingNew1,
  Desing1,
  Desing2,
  Desing3
};
export default ImagePath;
