import { Grid, Typography } from "@mui/material";
import fontStyle from "../../../assets/fontStyle";
import ImagePath from "../../../assets/images";
import React from "react";

const Header = () => {
  return (
    <Grid
      container
      sx={{
        display: "flex",
        justifyContent: "center",
        backgroundImage: "url(" + ImagePath.Desing1 + ")",
        backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        margin: "0 auto",
      }}
    >
      <Grid
        container
        style={{
          // height: 420,
          height: "auto", // Set height to auto to adjust based on content
          // maxHeight: 1200000, // Limit the maximum height of the container
          
          display: "flex",
          minHeight: "20rem",
          alignContent: "center",

          flexDirection: "column",
          maxWidth: "1400px",
          justifyContent: "center",
          padding: window.innerWidth > 1400 ? 0 : "0 7% 0 7%",
          // padding:
          //   window.innerWidth < 300
          //     ? "10px 7%"
          //     : window.innerWidth > 600
          //     ? "20px 7%"
          //     : "10px 7%",
        }}
      >
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h1
            style={{
              fontFamily: "Enoway",
              color: "white",
              fontSize: 35,
              fontWeight: 400,
              letterSpacing: "0.1rem",
            }}
          >
            FAQ's
          </h1>
        </div>
      </Grid>
    </Grid>
  );
};

export default Header;
