import axios from "axios";

const BASE_URL = "https://includetechnology.com/MarriageBiodata/api/";

export const getAllDropdown = async () => {
  try {
    const response = await axios.get(
      `${BASE_URL}getAllDropdown`
      // {
      //   headers: {
      //     "Content-Type": "multipart/form-data",
      //   },
      // }
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const registerService = async (formData) => {
  try {
    const response = await axios.post(`${BASE_URL}register`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const SubmitBioDataService = async (formData) => {
  try {
    const response = await axios.post(`${BASE_URL}create`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const postContactUs = async (formData) => {
  try {
    const response = await axios.post(`${BASE_URL}contactUs/create`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const loginService = async (formData) => {
  try {
    const response = await axios.post(`${BASE_URL}login`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const checkMobileUserService = async (phone) => {
  try {
    const response = await axios.post(`${BASE_URL}checkMobileUser`, phone, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const resetPasswordService = async (data) => {
  try {
    const response = await axios.post(`${BASE_URL}setPassword`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const profileResetPasswordService = async (data) => {
  try {
    const response = await axios.post(`${BASE_URL}changePassword`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchBiodataTemplates = async () => {
  try {
    const response = await axios.post(`${BASE_URL}getAllResumeTemplates`);
    console.log(response.data.data[0].name);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const getAllBiodata = async (user_id) => {
  try {
    console.log(user_id);
    const formData = new FormData();
    formData.append("user_id", user_id);
    const response = await axios.post(`${BASE_URL}getAllResume`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log(response.data.data);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const getBiodata = async (id) => {
  try {
    console.log(id);
    const formData = new FormData();
    formData.append("id", id);
    const response = await axios.post(`${BASE_URL}view`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createOrderService = async (formData) => {
  try {
    const response = await axios.post(`${BASE_URL}razorpay/order`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const successOrderService = async (formData) => {
  try {
    const response = await axios.post(
      `${BASE_URL}razorpay/orderPayment`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteAccService = async (id) => {
  try {
    console.log(id);
    const formData = new FormData();
    formData.append("id", id);
    const response = await axios.post(`${BASE_URL}deletAccount`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const faqService = async () => {
  try {
    const response = await axios.get(`${BASE_URL}faq`);
    console.log(response.data.data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAllLangauge = async (language) => {
  try {
    const formData = new FormData();
    formData.append('language', language); 
    const response = await axios.post(`${BASE_URL}getAllDropdownLanguage`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};