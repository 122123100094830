import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ImagePath from "../../../assets/images";
import { Link } from "react-router-dom";
import fontStyle from "../../../assets/fontStyle";
import Styles from "./style";
import "../../../fonts/fonts.css";


const BiodataMaker = () => {
  const [index, setIndex] = useState(0);
  const [fade, setFade] = useState(true);

  const texts = [
    "Stand Out from the Crowd with Make My BioData Maker",
    "Crafting Perfect Biodata for the Perfect Person",
  ];

  const content = [
    `First impressions matter, especially when it comes to your biodata.
    Our Biodata Maker Easy to use, create a professional and
    eye-catching biodata with just a few clicks, Easy Customization,
    Beautiful Results.`,
    `We are MakeMyBioData, a unique wedding Biodata designer in India crafting enchanting wedding Biodata for your perfect love story.`,
  ];

  const backgrounds = [
    "url(" + ImagePath.backDesingNew + ")",
    "url(" + ImagePath.backDesingNew1 + ")",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setIndex((prevIndex) => (prevIndex + 1) % texts.length);
        setFade(true);
      }, 100); // 1s for fade-out transition
    }, 5000);

    return () => clearInterval(interval);
  }, [texts.length]);

  const handleDotClick = (i) => {
    setFade(false);
    setTimeout(() => {
      setIndex(i);
      setFade(true);
    }, 100); // 1s for fade-out transition
  };

  return (
    <Grid container>
      <Grid container style={{ backgroundColor: "", height: "96px" }}></Grid>
      <Grid
        container
        sx={{
          position: "relative",
          overflow: "hidden",
          height: "auto",
          maxHeight: 5000,
          minHeight: "33rem",
          // maxWidth: "1400px",
          // paddingLeft: "7%",
          // paddingRight: "7%",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          boxShadow: "inset 0 0 0 2000px rgb(0 0 0 / 70%)",
          opacity: fade ? 1 : 0,
          transition: "opacity 1.5s ease-in-out, background-image 1.5s ease-in-out",
          backgroundImage: backgrounds[index],
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            opacity: 1,
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            zIndex: -10,
          },
        }}
      >
        <Grid container sx={{maxWidth:"1400px", display:"flex", justifyContent:"center", padding:  window.innerWidth > 1400 ? 0 : "0 7% 0 7%"}}>
          <div style={{maxWidth:"1000px"}}>
        <div
          style={{
            opacity: fade ? 1 : 0,
            transition: "opacity 1s ease-in-out",
            animation: fade ? "slideIn 1s ease-in-out" : "",
          }}
        >
        <h1
          style={{
            fontFamily: "Enoway",
            textAlign:"center",
            color: "white",
            fontSize: window.innerWidth > 950 ? "38px" :  window.innerWidth > 900 ? "30px" : window.innerWidth > 600 ? "25px" : "18px",
            fontWeight: 400,
            letterSpacing: "0.1rem",
            animation: "fadeInUp 1.5s ease forwards",
          }}
        >
          {texts[index]}
        </h1>
        </div>
        <Typography style={{ fontFamily: "Avenir", textAlign: "center", fontSize: "16px", color: "white" }}>
          {content[index]}
        </Typography>
        </div>

        <Grid container style={{display: 'flex', justifyContent: "center", flexDirection:"", padding: "1.5rem 0rem"}}>
        <div className="pagination" style={{display: 'flex', justifyContent: "center", flexDirection:""}}>
        {texts.map((_, i) => (
          <div
            key={i}
            className={`pagination-dot ${i === index ? "active" : ""}`}
            onClick={() => handleDotClick(i)}
            style={{ animationDelay: `${i * 0.5}s`, cursor: 'pointer' }}
          ></div>
        ))}
      </div>
      <style jsx>{`
        .pagination-container {
          position: absolute;
          bottom: 20px;
          left: 50%;
          transform: translateX(-50%);
          display: flex;
        }
        .pagination-dot {
          width: 10px;
          height: 10px;
          background-color: white;
          border-radius: 50%;
          margin: 0 5px;
          animation: slideInPagination 0.5s forwards;
        }
        .pagination-dot.active {
          background-color: #EC0673;
        }
        @keyframes slideInPagination {
          0% {
            transform: scale(0);
          }
          100% {
            transform: scale(1);
          }
        }
      `}</style>
      </Grid>
      </Grid>
      
      </Grid>
      <style jsx global>{`
        @keyframes slideIn {
          0% {
            transform: translateY(10vh); /* Start from below the screen */
            opacity: 0;
          }
          100% {
            transform: translateY(0);
            opacity: 1;
          }
        }
      `}</style>
    </Grid>
  );
}

export default BiodataMaker;