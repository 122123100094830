import React, { useState } from "react";
import {
  Button,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Styles from "./style";
import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import ImagePath from "../../assets/images";
import { UseMediaQuery } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { passChange } from "../../Redux/action";
import { clearPass } from "../../utils/localStorage";
import NavBar from "../HomeScreen/NavBar";

const ChangedPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const backLogin = () => {
    navigate("/login");
    clearPass();
    dispatch(passChange(false));
  }
  return (
    <>
    {/* <Grid container style={{ backgroundColor: "", height: "96px" }}></Grid> */}
    <Grid container style={Styles.container}>
      {/* <NavBar/> */}
      <Grid
        container
        style={Styles.grid1}
        lg={6}
        md={6}
        sm={6.5}
        sx={{ display: { xs: "none", sm: "flex" } }}
      >
        <Grid lg={12} md={12} sm={12} style={{}}>
          <img src={ImagePath.Icon} style={Styles.image1}></img>
        </Grid>
        <Grid lg={12} md={12} sm={12} style={Styles.grid12}></Grid>
      </Grid>

      <Grid lg={6} md={6} sm={5.5} xs={12} style={Styles.grid2}>
        <form style={Styles.form}>
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img src={ImagePath.Success} style={Styles.image4}></img>
          </Grid>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              style={{
                alignContent: "center",
                justifyContent: "center",
                display: "flex",
                //   backgroundColor: "red",
              }}
            >
              <h1
                style={{
                  alignContent: "center",
                  fontWeight: "bolder",
                  marginBottom: -18,
                  fontFamily: "Enoway"
                  ,
                  fontWeight: 400,
                      letterSpacing: "0.1rem",
                }}
              >
                Password Changed!
              </h1>
            </Typography>
            <Typography
              style={{
                alignContent: "left",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <h5 style={{ fontWeight: "bold", color: "grey", fontFamily: "Avenir" }}>
                Your password has been changed successfully.
              </h5>
            </Typography>
          </div>

          {/* <Link to={"/login"}> */}
            <Button
              variant="contained"
              onClick={backLogin}
              color="primary"
              to={`/`}
              fullWidth
              style={Styles.Button}
            >
              <Typography style={{ fontSize: 20, fontWeight: "normal", fontFamily: "Avenir" }}>
                Back to Login
              </Typography>
            </Button>
          {/* </Link> */}
        </form>
      </Grid>
    </Grid>
    </>
  );
};

export default ChangedPassword;
