import { useSelector } from "react-redux";

export const setUserId = (response) => {
  localStorage.setItem("data", response.data);
  console.log(localStorage.getItem("data"));
};

export const getUserId = () => {
  const data = localStorage.getItem("data");
  return data;
};

export const setUserId1 = (response) => {
  localStorage.setItem("id", response);
  console.log(localStorage.getItem("id"));
};

export const getUserId1 = () => {
  const data = localStorage.getItem("id");
  return data;
};

export const setLoggedInUser = (data) => {
  localStorage.setItem("user", data);
  console.log(data);
};

export const setLoggedInUserName = (data) => {
  localStorage.setItem("userData", data);
  console.log(data);
};

export const setLoggedInUserEmail = (data) => {
  localStorage.setItem("userEmail", data);
  console.log(data);
}

export const loginUserEmail = () => {
  const email = localStorage.getItem("userEmail");
  console.log("user email: ", email);
  return email;
};

export const setLoggedInUserId = (username) => {
  localStorage.setItem("username", username);
 console.log(username);
}

export const setLoggedInUserCcode = (code) => {
  localStorage.setItem("Ccode", code);
 console.log(code);
}

export const setLoggedInUserPhone = (phone) => {
  localStorage.setItem("phone", phone);
 console.log(phone);
}

export const loginUserCcode = () => {
  const code = localStorage.getItem("Ccode");
  console.log("user code: ", code);
  return code;
};

export const loginUserPhone = () => {
  const phone = localStorage.getItem("phone");
  console.log("user email: ", phone);
  return phone;
};

export const loginUserIdName = () => {
    const userId = localStorage.getItem("username");
    return userId;
}

export const setLoggedInUserAt = (data) => {
  localStorage.setItem("userCreatedAt", data);
  console.log(data);
}

export const loginUserAt = () => {
  const userAt = localStorage.getItem("userCreatedAt");
  console.log("check user: ", userAt);
  return userAt;
};
 
export const isLoggedIn = () => {
  const user = localStorage.getItem("user");
  return user ? true : false;
};

//delete account
export const setDeleteUser = (data) => {
  localStorage.setItem("acc", data);
  console.log(data);
};

export const getDeleteUser = () => {
  const user = localStorage.getItem("acc");
  return user ? true : false;
};

//profile reset password
export const setProPass = (data) => {
  localStorage.setItem("propass", data);
  console.log(data);
}

export const clearProPass = () => {
  const data = localStorage.removeItem("propass");
  console.log(data);
};

export const getProPass = () => {
   const propass  = localStorage.getItem("propass");
   return propass ? true : false;
}


//reset password
export const setPass = (data) => {
  localStorage.setItem("pass", data);
  console.log(data);
}

export const clearPass = () => {
  const data = localStorage.removeItem("pass");
  console.log(data);
};

export const getPass = () => {
   const pass  = localStorage.getItem("pass");
   return pass ? true : false;
}

export const loginUserName = () => {
  const user = localStorage.getItem("userData");
  console.log("check user: ", user);
  return user;
};

export const isLoggedOut = () => {
  localStorage.clear();
};

export const setPdf = (res) => {
  localStorage.setItem("pdf", res);
  console.log(localStorage.getItem("pdf"));
};

export const getPdf = () => {
  const data = localStorage.getItem("pdf");
  return data;
};

export const setResume = (res) => {
  localStorage.setItem("resume", res);
  console.log(localStorage.getItem("resume"));
};

export const getResume = () => {
  const data = localStorage.getItem("resume");
  return data;
};

export const setTemp = (res) => {
  localStorage.setItem("Temp", res);
  console.log(localStorage.getItem("Temp"));
};

export const getTemp = () => {
  const data = localStorage.getItem("Temp");
  return data;
};

export const setIsPayment = (res) => {
  localStorage.setItem("pid", res);
  console.log(localStorage.getItem("pid"));
};

export const getIsPayment = () => {
  const data = localStorage.getItem("pid");
  return data;
};

export const setBioId = (bio_id) => {
  localStorage.setItem("bio_id", bio_id);
}

export const getBioId = () => {
  const bioId = localStorage.getItem("bio_id");
  return bioId;
}

export const setPreview = (temp) => {
  localStorage.setItem("preview", temp);
}

export const getPreview = () => {
  const temp = localStorage.getItem("preview");
  return temp;
}

//formdata
export const setForm = (formdata) => {
  const formDataString = JSON.stringify(formdata);
  localStorage.setItem("formdata", formDataString);
}

export const getForm = () => {
  const formDataString = localStorage.getItem("formdata");
  if (formDataString) {
    console.log("byy from local", formDataString);
    return JSON.parse(formDataString);
  }
  return null; // or you can return an empty object {} if you prefer
}

export const clearForm = () => {
  const data = localStorage.removeItem("formdata");
  console.log(data);
};