import * as Yup from "yup";

export const UsernewPasswordSchema = Yup.object({
    old_password: Yup.string().required("Please enter your Old password"),  
  password: Yup.string().min(6).max(12).required("Please enter your New password"),
  password_confirmation: Yup.string()
    .required("Please enter your confirm password")
    .oneOf([Yup.ref("password"), null], "Password must match"),
  // data: Yup.string().required("id not find")
});
