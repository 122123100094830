const fontStyle = {
  HeadingFont: {
    fontFamily: "Enoway",
    color: "#EC0673",
    fontSize: 35,
    fontWeight: 400,
    letterSpacing: "0.1rem",
  },
  HeadingFont1: {
    fontFamily: "Enoway",
    color: "white",
    fontSize: 35,
    marginBottom: 10,
    fontWeight: 400,
    letterSpacing: "0.1rem",
  },
  HeadingFont2: {
    fontFamily: "Enoway",
    color: "white",
    paddingLeft: window.innerWidth > 600 ? 70 : 20,
    fontSize: 30,
    paddingBottom: 20,
    fontWeight: 750,
    fontWeight: 400,
    letterSpacing: "0.1rem",
  },
  HeadingFont3: {
    fontFamily: "Enoway",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: window.innerWidth > 700 ? 20 : 14,
    marginLeft: window.innerWidth > 700 ? 0 : 18,
    fontWeight: 400,
    letterSpacing: "0.1rem",
  },
  FormHead: {
    fontFamily: "Enoway",
    textAlign: "left",
    fontSize: window.innerWidth > 500 ? "28px" : "20px",
    color: "rgba(124, 0, 31, 1)",
    fontWeight: "bolder",
    fontWeight: 400,
    letterSpacing: "0.1rem",
  },
  FormHead1: {
    fontFamily: "Enoway",
    color: "white",
    fontSize: 27,
    fontWeight: "bold",
    fontWeight: 400,
    letterSpacing: "0.1rem",
  },
  FormHead2: {
    fontFamily: "Enoway",
    color: "white",
    fontSize: 33,
    fontWeight: "bold",
    fontWeight: 400,
    letterSpacing: "0.1rem",
    
  },
  Head: {
    fontFamily: "Enoway",
    fontSize: "20px",
    marginBottom: "2%",
    color: "#EC0673",
    fontWeight: 400,
    letterSpacing: "0.1rem",
  },


  ContentHeading: { fontFamily: "Avenir", color: "black" },
  ContentFont: { fontFamily: "Avenir" },
  Content1: {
    fontFamily: "Avenir",
    fontSize: "18px",
    marginBottom: "2%",
  },
  Content: {
    fontFamily: "Avenir",
    color: "#EC0673",
    paddingBottom: "4%",
  },
  ContentHead: {
    fontFamily: "Avenir",
    fontSize: 16,
    color: "grey",
    fontWeight: "bold",
    marginTop: 15,
  },
  ContentHead1: { fontFamily: "Avenir" },
  ContentHead2: {
    fontFamily: "Avenir",
    color: "white",
    paddingLeft: window.innerWidth > 600 ? 70 : 20,
    fontSize: 17,
    paddingBottom: 20,
  },
  ContentFont1: {
    fontFamily: "Avenir",
    fontSize: 16,
    color: "#A5A4A4",
    marginTop: 20,
  },
  ContentFontH1: {
    fontFamily: "Avenir",
    fontSize: 16,
    color: "black",
    marginTop: 20,
    textAlign:"center"
  },
  Contentfont1: {
    fontFamily: "Avenir",
    fontSize: 16,
    color: "black",
    paddingBottom: "2%",
  },
  ContentFontH3: {
    fontFamily: "Avenir",
    display: "flex",
    textAlign: "center",
    marginTop: 15,
    color: "BLACK",
    textAlign:"center",
    fontSize: 16,
  },
  ContentFontH3: {
    fontFamily: "Avenir",
    fontSize: 16,
    color: "black",
    textAlign:"center",
    
  },
  Contentfont2: {
    fontFamily: "Avenir",
    fontSize: 16,
    color: "black",
    paddingLeft: "5%",
  },
  Contentfont3: {
    fontFamily: "Avenir",
    fontSize: 16,
    color: "black",
    paddingBottom: "2%",
  },
  ContentFont2: {
    fontFamily: "Avenir",
    fontSize: window.innerWidth < 300 ? 15 : window.innerWidth < 1245 ? 17 : 20,
    color: "rgba(255, 255, 255, 1)",
    marginTop: 3,
  },
  ContentFont3: {
    fontFamily: "Avenir",
    display: "flex",
    textAlign: "center",
    marginTop: 15,
    color: "rgba(255, 255, 255, 0.6)",
    fontSize: 16,
  },
  ContentFont3: {
    fontFamily: "Avenir",
    fontSize: 16,
    color: "#A5A4A4",
  },
  ContentFont4: {
    fontFamily: "Avenir",
    color: "white",
    paddingLeft: window.innerWidth > 600 ? 70 : 20,
    fontSize: 15,
    fontWeight: "lighter",
  },
  FormLabel: {
    fontFamily: "Avenir",
    textAlign: "left",
    fontSize: window.innerWidth > 900 ? "23px" : "19px",
    fontWeight: 500,
    color: "black",
  },
  FormContent: {
    fontFamily: "Avenir",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: 15,
    fontWeight: "lighter",
    marginBottom: 50,
  },
  FooterContent: {
    fontFamily: "Avenir",
    textDecoration: "none",
    color: "black",
    fontSize: 20,
    fontWeight: 600,
    marginRight: window.innerWidth > 700 ? 0 : 0,
  },
  FooterContentN: {
    fontFamily: "Avenir",
    textDecoration: "none",
    color: "black",
    fontSize: "15px",
    marginBottom:"1rem"
    // fontWeight: 600,
    // marginRight: window.innerWidth > 700 ? 0 : 0,
  },
};

export default fontStyle;
