import { PASS_CHANGE, PRO_PASS_CHANGE, RESETPASS_ID, STORE_RESUME_ID } from "./constants";
import { AUTH_DATA } from "./constants";
import { GET_TEMPLATE } from "./constants";
import { FORM_SUBMIT, USER_INFO, SELECTED_BIO_Id, GOT_BIO } from "./constants";

export const storeResetPassId = (data) => {
  console.log("Data received in storeResetPassId action:", data); // Log data received
  return {
    type: RESETPASS_ID,
    payload: data,
  };
};

export const storeAuthData = (isAuth) => {
  // console.log("in action");
  console.log("login user: ", isAuth);
  return {
    type: AUTH_DATA,
    payload: isAuth,
  };
};


export const storeTemplate = (template) => {
  console.log("temp data: ", template);
  return {
    type: GET_TEMPLATE,
    payload: template
    }
}

export const storeSubmit = (submit) => {
  // console.log("in action");
  console.log("login user: ", submit);
  return {
    type: FORM_SUBMIT,
    payload: submit,
  };
};

export const storeUserInfo = (user) => {
    console.log("user info: ", user);
    return {
      type: USER_INFO,
      payload: user
    }
}


export const storeSelectedId = (data) => {
  console.log("selected:", data)
  return {
    type: SELECTED_BIO_Id,
    payload: data
  }
}

export const storeBioClick = (bio) => {
  // console.log("selected:", bio)
  return {
    type: GOT_BIO,
    payload: bio
  }
}


export const passChange = (value) => {
  console.log("pass change: ", value);
  return {
    type: PASS_CHANGE,
    payload: value
  }
}

export const proPassChange = (provalue) => {
  return {
    type: PRO_PASS_CHANGE,
    payload: provalue
  }
}

export const storeResumeId = (resumeId) => {
  return {
    type: STORE_RESUME_ID,
    Payload: resumeId
  }
}