import { Grid, Typography } from "@mui/material";
import { FaHandsClapping } from "react-icons/fa6";
import { BsStars } from "react-icons/bs";
import { RiQuillPenFill } from "react-icons/ri";
import fontStyle from "../../../assets/fontStyle";
import React from "react";

const Features = () => {
  return (
    <Grid container sx={{ display: "flex", justifyContent: "center", backgroundColor: "#EC0673", margin: "0 auto" }}>
      <Grid
        container
        sx={{
          height: "auto",
          maxWidth: "1400px",
          padding: window.innerWidth > 1400 ? "0 0" : "0 7%",
          paddingBottom:"2.5%"
        }}
      >
        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{
           
            padding: "20px 30px",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
           
          }}
        >
          <Typography sx={{ fontFamily: "Enoway", color: "white", fontSize: 35, marginBottom: 0, fontWeight: 400, letterSpacing: "0.1rem", textAlign: "center" }}>
            Features You'll Love of our Effortless Biodata maker app
          </Typography>
        </Grid>

        <Grid
          container
          spacing={3}
        
        >
          {[{
            icon: <FaHandsClapping style={{ color: "#ee7724", fontSize: "33px" }} />,
            title: "Biodata in Minutes",
            description: "No lengthy sign-ups required! Simply enter your details, choose your favorite template, and your biodata is ready in PDF format – it's that easy!"
          }, {
            icon: <RiQuillPenFill style={{ color: "#ee7724", fontSize: "33px" }} />,
            title: "Pre-Defined Sections",
            description: "Our expertly designed templates include a comprehensive range of relevant sections to showcase your education, professional background, family details, and interests."
          }, {
            icon: <BsStars style={{ color: "#ee7724", fontSize: "33px" }} />,
            title: "Stunning Template Collection",
            description: "Our team of design professionals has crafted a variety of unique, clean, and elegant templates specifically for matrimonial biodata."
          }].map((feature, index) => (
            <Grid
              key={index}
              item
              lg={4}
              md={4}
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                padding: "0 25px",
                // marginBottom: { lg: 5, md: 3, sm: 2, xs: 1 },
              }}
            >
              <div
                style={{
                  border: "1px solid white",
                  borderRadius: "50%",
                  width: "60px",
                  height: "60px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "white",
                  marginTop: 5,
                }}
              >
                {feature.icon}
              </div>
              <div
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  justifyContent:"center",
                  alignContent:"start",
                  marginTop: 0
                }}
              >
                <Typography sx={fontStyle.ContentFont2}>
                  {feature.title}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Avenir",
                    marginTop: 1,
                    color: "white",
                    fontSize: 16,
                    textAlign: "center",
                  }}
                >
                  {feature.description}
                </Typography>
              </div>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Features;
