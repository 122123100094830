import * as Yup from "yup";
import "yup-phone";
import { phoneNumber } from "yup-phone-number";
import "yup-phone-lite";

export const forgetPasswordSchema = Yup.object({
  phone2: Yup.string()
    .required("Phone number is required")
    .matches(/^\d{12,}$/, "Phone number must be exactly 10 digits")
  // phone: Yup.string()
  // .required("Please enter your phone number")
  // .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
  // .matches(/^\d{8}$/, {
  //   message: "Only 10 digit numbers are allowed",
  //   excludeEmptyString: true,
  // })
  // .test("country-code-validation", "Please enter a valid phone number", function (value) {
  //   // Extract last 8 digits
  //   const lastEightDigits = value.slice(-10);
  //   // Check if last 8 digits are numeric
  //   return /^\d+$/.test(lastEightDigits);
  // }),
});
