import { Grid, Typography } from "@mui/material";
import { FaUserTie } from "react-icons/fa";
import React, { useEffect, useState } from "react";
import { fetchBiodataTemplates } from "../../../services/ApiService";
import { FaEye } from "react-icons/fa";
import { GiArrowScope } from "react-icons/gi";
import { FaUserGraduate } from "react-icons/fa";
import fontStyle from "../../../assets/fontStyle";

const Content = () => {
  const [tempOp, setTempOp] = useState([]);
  useEffect(() => {
    const fetchTemp = async () => {
      try {
        const temp = await fetchBiodataTemplates();
        setTempOp(temp);
        console.log(tempOp);
      } catch (error) {
        console.error("Error fetching templates:", error);
      }
    };
    fetchTemp();
  }, []);

  const firstFour = tempOp.slice(0, 4);
  const half = Math.ceil(firstFour.length / 2);
  const first = firstFour.slice(0, half);
  const second = firstFour.slice(half);

  const firstTwo = tempOp.slice(3, 6);

  return (
    <Grid
      container
      sx={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: "rgba(248, 248, 248, 1)",
        margin: "0 auto",
      }}
    >
      <Grid
        container
        style={{
          // height: 420,
          height: "auto", // Set height to auto to adjust based on content
          maxHeight: 50000, // Limit the maximum height of the container
          backgroundColor: "rgba(248, 248, 248, 1)",
          display: "flex",
          alignContent: "center",
          flexDirection: "column",
          maxWidth: "1400px",
          // justifyContent: "center",
          padding: window.innerWidth > 1400 ? "30px 0" : "30px 7% 30px 7%",
          // padding:
          //   window.innerWidth < 300
          //     ? "10px 7%"
          //     : window.innerWidth > 600
          //     ? "40px 7%"
          //     : "10px 7%",
        }}
      >
        <Grid container>
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              flexDirection: "column",
              justifyItems: "center",
              alignItems: "center",
              padding: "3rem 4rem",
            }}
          >
            <FaUserGraduate style={{ fontSize: "50px" }} />
            <Typography
              style={{
                color: "#EC0673",
                fontFamily: "Enoway",
                fontWeight: 400,
                fontSize: "45px",
                letterSpacing: "0.1rem",
              }}
            >
              <span style={{ color: "black" }}>Why</span>&nbsp;Us
            </Typography>
            <div
              style={{
                width: "60px",
                height: "0.25rem",
                backgroundColor: "#EC0673",
                marginBottom: "0rem",
                marginLeft: "0.8rem",
              }}
            />
          </Grid>

          <Grid
            container
            style={{
              height: window.innerWidth > 600 ? "500px" : "550px",
              backgroundColor: "rgb(255, 210, 235)",
              position: "relative",
              marginTop: "1rem",
            }}
          >
            <Grid
              item
              lg={5.5}
              md={5.3}
              sm={3.75}
              xs={4}
              style={{
                position: "absolute",
                display: window.innerWidth > 540 ? "flex" : "none",
                justifyContent: "flex-end",

                alignContent: "end",
                top: 0,
                left: 0,
                width: "100%", // Adjust the width of the first image as needed
                height: "100%",
              }}
            >
              {firstTwo.length > 0 && (
                <img
                  src={`https://includetechnology.com/MarriageBiodata/public/img/templates/${firstTwo[2].sample_path}`}
                  alt={firstTwo[2].name}
                  style={{
                    width: "auto",
                    height:
                      window.innerWidth > 900
                        ? "420px"
                        : window.innerWidth > 850
                        ? "300px"
                        : window.innerWidth > 760
                        ? "270px"
                        : "220px",
                    objectFit: "cover",
                    margin:
                      window.innerWidth > 700
                        ? "4rem 1.5rem 0 0"
                        : "0.7rem 0 0 0",
                  }}
                />
              )}
            </Grid>

            <Grid
              item
              lg={6.5}
              md={6.8}
              sm={8.25}
              xs={8}
              style={{
                position: "absolute",
                display: window.innerWidth > 540 ? "flex" : "none",
                justifyContent: "flex-start",

                alignContent: "end",
                top: 0,
                right: 0,
                width: window.innerWidth > 700 ? "100%" : "100%",
                height: "50%",
              }}
            >
              {firstTwo.length > 1 && (
                <img
                  src={`https://includetechnology.com/MarriageBiodata/public/img/templates/${firstTwo[1].sample_path}`}
                  alt={firstTwo[1].name}
                  style={{
                    width: "auto",
                    height:
                      window.innerWidth > 800
                        ? "300px"
                        : window.innerWidth > 650
                        ? "220px"
                        : "200px",
                    objectFit: "cover",
                    margin:
                      window.innerWidth > 700
                        ? "4rem 1.5rem 0 0rem"
                        : "0.7rem 1rem 0 1rem",
                  }}
                />
              )}
              {firstTwo.length > 2 && (
                <img
                  src={`https://includetechnology.com/MarriageBiodata/public/img/templates/${firstTwo[0].sample_path}`}
                  alt={firstTwo[0].name}
                  style={{
                    width: "auto",
                    height:
                      window.innerWidth > 800
                        ? "300px"
                        : window.innerWidth > 650
                        ? "220px"
                        : "200px",
                    objectFit: "cover",
                    margin:
                      window.innerWidth > 700
                        ? "4rem 1rem 0rem 0rem"
                        : "0.7rem 1rem 0 0rem",
                  }}
                />
              )}
            </Grid>
            <Grid
              item
              lg={7}
              md={9}
              sm={11}
              xs={12}
              style={{
                backgroundColor: "white",
                zIndex: 1000,
                position: "absolute",
                bottom: 0,
                right: 0,
                width: window.innerWidth > 900 ? "70%" : "100%",

                // height: "300px" // Optional: Adjust height if necessary, otherwise it will be auto
              }}
            >
              <div
                style={{
                  maxWidth: "770px",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  justifyItems: "center",
                }}
              >
                <Grid lg={12} md={12} sm={12} sx={{ padding: "1rem 1.2rem" }}>
                  <Typography
                    style={{
                      color: "black",
                      fontFamily: "Enoway",
                      fontSize: "1.3rem",
                    }}
                  >
                    Choose{" "}
                    <span style={{ color: "#EC0673" }}>Make My BioData </span>
                    for your wedding Biodata.
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "1rem",
                      color: "black",
                      fontFamily: "Avenir",
                      marginTop: "0.5rem",
                    }}
                  >
                    Immerse in creativity with our vast range of free and paid
                    templates, customizable in Hindi, English, and Gujarati.
                    We’re not just crafting Biodata; we’re curating your love
                    story. Experience premium quality. Join us in making your
                    'big day' truly grand, with wedding biodata that echo your
                    unique style and story.{" "}
                  </Typography>
                </Grid>
                <Grid container sx={{ padding: "1rem 1.2rem" }}>
                  <Grid
                    lg={6}
                    md={6}
                    sm={6}
                    sx={{ padding: "0rem 1rem 0rem 0rem" }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        style={{
                          color: "black",
                          fontFamily: "Enoway",
                          fontSize: "1.3rem",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <GiArrowScope
                          style={{ fontSize: "1.3rem", marginRight: "0.5rem" }}
                        />
                        Our&nbsp;
                        <span style={{ color: "#EC0673" }}>Mission</span>
                      </Typography>
                    </div>
                    <Typography
                      style={{
                        fontSize: "0.9rem",
                        color: "black",
                        fontFamily: "Avenir",
                        marginTop: "0.5rem",
                      }}
                    >
                      We are here to turn your love story into art. Crafting
                      traditional and trendy wedding biodata in Hindi, English,
                      and Gujarati to make your special day unforgettable.{" "}
                    </Typography>
                  </Grid>
                  <Grid
                    lg={6}
                    md={6}
                    sm={6}
                    sx={{
                      padding:
                        window.innerWidth > 600
                          ? "0rem 1rem 0rem 0rem"
                          : "1rem 1rem 0rem 0rem",
                    }}
                  >
                    <Typography
                      style={{
                        color: "black",
                        fontFamily: "Enoway",
                        fontSize: "1.3rem",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <FaEye
                        style={{ fontSize: "1.3rem", marginRight: "0.5rem" }}
                      />
                      Our&nbsp;<span style={{ color: "#EC0673" }}>Vision</span>
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "0.9rem",
                        color: "black",
                        fontFamily: "Avenir",
                        marginTop: "0.5rem",
                      }}
                    >
                      At MakeMyBioData, we craft your love story into your
                      biodatas. Our mission is to make your 'big day'
                      extraordinary.{" "}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>

          <Grid
            lg={6}
            md={6}
            sx={{
              marginTop: "2%",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              flexDirection: "column",
              justifyItems: "center",
              alignItems: "center",
              padding: window.innerWidth > 500 ? "5rem 4rem" : "5rem 0rem",
            }}
          >
            <FaUserTie style={{ fontSize: "40px" }} />
            <Typography
              style={{
                color: "#EC0673",
                fontFamily: "Enoway",
                fontWeight: 400,
                fontSize: "45px",
                letterSpacing: "0.1rem",
              }}
            >
              <span style={{ color: "black" }}>Who We</span>&nbsp;Are
            </Typography>

            <div
              style={{
                width: "60px",
                height: "0.25rem",
                backgroundColor: "#EC0673",
                marginBottom: "3rem",
                marginLeft: "0.8rem",
              }}
            />

            <div
              style={{ marginBottom: 10, display: "flex", textAlign: "center" }}
            >
              <Typography
                style={{ fontSize: 16, color: "black", fontFamily: "Avenir" }}
              >
                We, at MakeMyBioData, craft wedding Biodata that mirror your
                love story. From free templates to premium designs, customize
                and celebrate your special day with us in Hindi, English, or
                Gujarati.{" "}
              </Typography>
            </div>
          </Grid>

          <Grid lg={6} md={6} sm={12} sx={{ marginTop: "2%" }}>
            <Grid container spacing={{ lg: 0, md: 2, sm: 0, xs: 0 }}>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                {first.map((image) => (
                  <Grid
                    container
                    justifyContent="center"
                    key={image.id}
                    sx={{ padding: "10px" }}
                  >
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      justifyContent="center"
                      sx={{
                        display: "flex",
                        justifyContent:
                          window.innerWidth > 600 ? "flex-end" : "center",
                      }}
                    >
                      <img
                        src={`https://includetechnology.com/MarriageBiodata/public/img/templates/${image.sample_path}`}
                        alt={image.name}
                        style={{
                          width: window.innerWidth < 900 ? "70%" : "220px",
                          height: window.innerWidth < 900 ? "auto" : "300px",
                        }}
                      />
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                sm={6}
                xs={12}
                sx={{ backgroundColor: "" }}
              >
                {second.map((image) => (
                  <Grid
                    container
                    justifyContent="center"
                    key={image.id}
                    sx={{ padding: "10px" }}
                  >
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyContent:
                          window.innerWidth > 600 ? "flex-start" : "center",
                      }}
                    >
                      <img
                        src={`https://includetechnology.com/MarriageBiodata/public/img/templates/${image.sample_path}`}
                        alt={image.name}
                        style={{
                          width: window.innerWidth < 900 ? "70%" : "220px",
                          height: window.innerWidth < 900 ? "auto" : "300px",
                        }}
                      />
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid>
          <div style={{ marginBottom: 30, marginTop: "2.5%" }}>
            <h1
              style={{
                fontFamily: "Enoway",
                color: "black",
                fontSize: 35,
                fontWeight: 400,
                letterSpacing: "0.1rem",
                textAlign: "center",
              }}
            >
              Welcome On <span style={{ color: "#EC0673" }}>Biodata</span> For
              Marriage
            </h1>
            <Typography style={fontStyle.ContentFontH1}>
              Welcome on one of the best Marriage Biodata Maker website. Finding
              your perfect match starts with a biodata that captures attention.
              Here at Make My BioData, we empower you to create a stunning and
              informative marriage biodata that reflects your unique truly
              shines.
            </Typography>

            <Typography
              style={{
                fontFamily: "Avenir",
                fontSize: 16,
                color: "black",
                fontWeight: "bold",
                marginTop: 15,
                textAlign: "center",
              }}
            >
              Why Choose Us?
            </Typography>
            <Typography style={fontStyle.ContentFontH1}>
              Unlimited Biodata Variations: Go beyond traditional templates. Our
              platform offers a diverse range of customization options, allowing
              you to create a biodata that reflects your unique personality.
              Simple & Efficient: No design expertise needed! Our user-friendly
              interface makes creating a beautiful biodata a breeze.
            </Typography>

            <Typography
              style={{
                fontFamily: "Avenir",
                fontSize: 16,
                color: "black",
                fontWeight: "bold",
                marginTop: 15,
                textAlign: "center",
              }}
            >
              More than just a Biodata Maker!
            </Typography>
            <Typography style={fontStyle.ContentFontH1}>
              In addition to our pre-designed templates, We understand that a
              biodata is more than just aesthetics. That's why we offer
              informative content about the purpose and importance of a marriage
              biodata, along with tips for creating a compelling one.
            </Typography>

            <Typography style={fontStyle.ContentFontH1}>
              Ready to take the first step towards your happily ever after?
              Let's create a biodata with Make My BioData!
            </Typography>
          </div>

          <div style={{ marginBottom: 30 }}>
            <h1
              style={{
                fontFamily: "Enoway",
                color: "#EC0673",
                fontSize: 35,
                fontWeight: 400,
                letterSpacing: "0.1rem",
                paddingTop: "2.5%",
                textAlign: "center",
              }}
            >
              <span style={{ color: "black" }}>How to Make a </span>Good
              Marriage Biodata
            </h1>
            <Typography style={fontStyle.ContentFontH1}>
              MakeMyBioData takes the stress out of creating a stunning wedding
              biodata. Our user-friendly platform offers a variety of
              beautifully pre-designed templates to showcase your unique
              personality. We understand that everyone is different. Whether
              it's your hobbies, interests, or life goals, your biodata should
              reflect who you are and what you're looking for in a partner.
              MakeMyBioData makes it simple to include all the essential details
              like education, career, and family background. You can also
              personalize your biodata to highlight your interests and
              aspirations for a fulfilling marriage.
            </Typography>
          </div>

          <div
            style={{
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
              alignContent: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h3 style={{ fontFamily: "Avenir", textAlign: "center" }}>
              The Ultimate Guide to making a good marriage biodata
            </h3>
            <Typography style={{ display: "flex", textAlign: "CENTER" }}>
              {/* <span
            style={{
              paddingLeft: 6,
              paddingRight: 7,
              fontSize: 18,
              color: "grey",
            }}
          >
            •
          </span> */}
              <Typography style={fontStyle.ContentFontH3}>
                <span
                  style={{
                    fontFamily: "Avenir",
                    fontSize: 16,
                    color: "black",
                    // fontWeight: "bold",
                    marginTop: 15,
                  }}
                >
                  Honesty is Key,
                </span>{" "}
                Be truthful and accurate in your information. Misrepresentation
                can lead to future problems.
              </Typography>
            </Typography>
            <Typography sx={{ display: "flex", marginTop: 2 }}>
              {/* <span
            style={{
              paddingLeft: 6,
              paddingRight: 7,
              fontSize: 18,
              color: "grey",
            }}
          >
            •
          </span> */}
              <Typography style={fontStyle.ContentFontH3}>
                <span
                  style={{
                    fontFamily: "Avenir",
                    fontSize: 16,
                    color: "black",
                    // fontWeight: "bold",
                    marginTop: 15,
                  }}
                >
                  {" "}
                  Keep it Short & Sweet,
                </span>
                Focus on the most important details about yourself. Brevity
                keeps readers engaged.
              </Typography>
            </Typography>
            <Typography sx={{ display: "flex", marginTop: 2 }}>
              {/* <span
            style={{
              paddingLeft: 6,
              paddingRight: 7,
              fontSize: 18,
              color: "grey",
            }}
          >
            •
          </span> */}
              <Typography style={fontStyle.ContentFontH3}>
                <span
                  style={{
                    fontFamily: "Avenir",
                    fontSize: 16,
                    color: "black",
                    // fontWeight: "bold",
                    marginTop: 15,
                  }}
                >
                  {" "}
                  Mind Your Manners,
                </span>
                Use proper grammar and avoid offensive language. Show your best
                communication skills.
              </Typography>
            </Typography>
            <Typography sx={{ display: "flex", marginTop: 2 }}>
              {/* <span
            style={{
              paddingLeft: 6,
              paddingRight: 7,
              fontSize: 18,
              color: "grey",
            }}
          >
            •
          </span> */}
              <Typography style={fontStyle.ContentFontH3}>
                <span
                  style={{
                    fontFamily: "Avenir",
                    fontSize: 16,
                    color: "black",
                    // fontWeight: "bold",
                    marginTop: 15,
                  }}
                >
                  Show Your Smile,
                </span>
                Include a recent photo that reflects your personality. A good
                first impression is key!
              </Typography>
            </Typography>
            <Typography sx={{ display: "flex", marginTop: 2 }}>
              {/* <span
            style={{
              paddingLeft: 6,
              paddingRight: 7,
              fontSize: 18,
              color: "grey",
            }}
          >
            •
          </span> */}
              <Typography style={fontStyle.ContentFontH3}>
                <span
                  style={{
                    fontFamily: "Avenir",
                    fontSize: 16,
                    color: "black",
                    // fontWeight: "bold",
                    marginTop: 15,
                  }}
                >
                  {" "}
                  Respectful Representation,
                </span>
                Be considerate of cultural and social norms. Tailor your biodata
                to resonate with potential partners.
              </Typography>
            </Typography>
            <Typography sx={{ display: "flex", marginTop: 2 }}>
              {/* <span
            style={{
              paddingLeft: 6,
              paddingRight: 7,
              fontSize: 18,
              color: "grey",
            }}
          >
            •
          </span> */}
              <Typography style={fontStyle.ContentFontH3}>
                <span
                  style={{
                    fontFamily: "Avenir",
                    fontSize: 16,
                    color: "black",
                    // fontWeight: "bold",
                    marginTop: 15,
                  }}
                >
                  Proofread with Care,
                </span>
                Typos and errors create a negative impression. Double-check your
                biodata for a polished presentation.
              </Typography>
            </Typography>
          </div>
        </Grid>

        <Grid
          container
          style={{ display: "flex", justifyContent: "center", marginTop: "2%" }}
        >
          <Typography
            style={{
              fontSize: "20px",
              marginBottom: "2%",
              color: "#EC0673",
              fontWeight: 500,
              fontFamily: "Avenir",
            }}
          >
            Let Make My BioData help you craft the perfect biodata today!
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Content;
