import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Styles from "./style";
import { styled } from "@mui/material/styles";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import ImagePath from "../../assets/images";
import { UseMediaQuery } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useFormik } from "formik";
import { newPasswordSchema } from "../../schemas/newPassword";
import { UsernewPasswordSchema } from "../../schemas/userresetPassword";
import { useNavigate } from "react-router-dom";
import { getUserId, getUserId1, setProPass } from "../../utils/localStorage";
import {
  profileResetPasswordService,
  resetPasswordService,
} from "../../services/ApiService";

import { useSelector, useDispatch } from "react-redux";
import { proPassChange } from "../../Redux/action";
const theme = createTheme();

const CustomFormControl = styled(FormControl)({
  "& label.Mui-focused": {
    color: "grey",
  },
  "& .MuiInput-underline:after": {
    // borderBottomColor: "red",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "lightgrey",
      borderRadius: 5,
      borderWidth: 1,
      background: "#ffd2eb33",
    },
    "&:hover fieldset": {
      borderColor: "#f35491",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#f35491",
    },
  },
});

const ProfileChangePassword = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [showOldPassword, setShowOldPassword] = React.useState(false);
  const [confirmPassword, setConfirmPassword] = React.useState(false);
  const [error, setError] = useState(null);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowOldPassword = () => setShowOldPassword((show) => !show);
  const handleClickConfirmPassword = () => setConfirmPassword((show) => !show);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const idData = useSelector((store) => store.reducer.data);
  const id = getUserId1();

  const initialValues = {
    old_password: "",
    password: "",
    password_confirmation: "",
    user_id: id,
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const { values, errors, handleSubmit, handleBlur, handleChange, touched } =
    useFormik({
      initialValues: initialValues,
      validationSchema: UsernewPasswordSchema,
      onSubmit: async (values) => {
        console.log("values: ", values);

        try {
          console.log("in service");
          const response = await profileResetPasswordService(values);
          if (response.status === "success") {
            console.log("successfully changed password: ", response);
            navigate("/profile");
            setProPass(response);
            dispatch(proPassChange(true));
          } else if (
            response.status === "fail" ||
            response.status === "error"
          ) {
            console.log("error in change password: ", response);
            setError(response.message);
          }
        } catch (error) {
          console.log("error in change password: ", error);
        }
      },
    });

  // console.log(Formik);

  // const disableBack = () => {
  //   window.history.go(1);
  // };

  // useEffect(() => {
  //   disableBack();
  // }, []);

  return (
    <Grid container style={Styles.container}>
      <Grid
        container
        style={Styles.grid1}
        lg={6}
        md={6}
        sm={6.5}
        sx={{ display: { xs: "none", sm: "flex" } }}
      >
        <Grid lg={12} md={12} sm={12} style={{}}>
          <img src={ImagePath.Icon} style={Styles.image1}></img>
        </Grid>
        <Grid lg={12} md={12} sm={12} style={Styles.grid12}>
          <img src={ImagePath.Iphone} style={Styles.image2}></img>
          <img src={ImagePath.Card} style={Styles.image3}></img>
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          style={Styles.blurOverlay}
          sx={{ display: { xs: "none", sm: "flex", md: "flex" } }}
        ></Grid>
      </Grid>

      <Grid lg={6} md={6} sm={5.5} xs={12} style={Styles.grid2}>
        <form onSubmit={handleSubmit} style={Styles.form}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              style={{
                alignContent: "left",
                //   display: "flex",
                //   backgroundColor: "red",
              }}
            >
              <h1
                style={{
                  alignContent: "left",
                  fontWeight: "bolder",
                  marginBottom: -18,
                  fontFamily: "Enoway",

                  fontWeight: 400,
                  letterSpacing: "0.1rem",
                }}
              >
                Create New <span style={{ color: "#EC0673" }}>Password</span>
              </h1>
            </Typography>
            <Typography style={{ alignContent: "left", display: "flex" }}>
              <h5
                style={{
                  fontWeight: "normal",
                  color: "grey",
                  fontFamily: "Avenir",
                }}
              >
                Your new password must be unique from those previously used.{" "}
              </h5>
            </Typography>
            {error && <h5 style={{ color: "red" }}>{error}</h5>}
          </div>

          <CustomFormControl
            size="small"
            variant="outlined"
            fullWidth
            id="old_password"
            name="old_password"
            value={values.old_password}
            onChange={handleChange}
            onBlur={handleBlur}
            style={{ height: "6px" }}
          >
            <InputLabel
              htmlFor="old_password"
              style={{ fontSize: 14, alignItems: "center", display: "flex" }}
            >
              Old Password
            </InputLabel>
            <OutlinedInput
              inputProps={{
                style: { zIndex: 1, fontSize: 14 },
              }}
              id="old_password"
              type={showOldPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowOldPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    style={{ zIndex: 1 }}
                  >
                    {showOldPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              label="Enter your password"
            />
          </CustomFormControl>
          {touched.old_password && errors.old_password && (
            <h5 style={{ fontFamily: "Avenir" }} className="form-error">
              {errors.old_password}
            </h5>
          )}

          <div
            style={{
              marginTop: errors.old_password ? "30px" : "35px",
            }}
          >
            <CustomFormControl
              size="small"
              variant="outlined"
              fullWidth
              id="password"
              name="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              style={{ height: "6px" }}
            >
              <InputLabel
                htmlFor="password"
                style={{ fontSize: 14, alignItems: "center", display: "flex" }}
              >
                New Password
              </InputLabel>
              <OutlinedInput
                inputProps={{
                  style: { zIndex: 1, fontSize: 14 },
                }}
                id="password"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      style={{ zIndex: 1 }}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Enter your password"
              />
            </CustomFormControl>
            {touched.password && errors.password && (
              <h5 style={{ fontFamily: "Avenir" }} className="form-error">
                {errors.password}
              </h5>
            )}
          </div>

          <div
            style={{
              marginTop: errors.password ? "30px" : "35px",
            }}
          >
            <CustomFormControl
              size="small"
              variant="outlined"
              fullWidth
              id="password_confirmation"
              name="password_confirmation"
              value={values.password_confirmation}
              onChange={handleChange}
              onBlur={handleBlur}
              style={{ height: "6px" }}
            >
              <InputLabel
                htmlFor="password_confirmation"
                style={{ fontSize: 14, alignItems: "center", display: "flex" }}
              >
                Confirm Password
              </InputLabel>
              <OutlinedInput
                inputProps={{
                  style: { zIndex: 1, fontSize: 14 },
                }}
                id="password_confirmation"
                type={confirmPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      style={{ zIndex: 1 }}
                    >
                      {confirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Enter your password"
              />
            </CustomFormControl>
            {touched.password_confirmation && errors.password_confirmation && (
              <h5
                className="form-error"
                style={{ marginBottom: -5, fontFamily: "Avenir" }}
              >
                {errors.password_confirmation}
              </h5>
            )}
          </div>

          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            style={Styles.Button}
          >
            <Typography
              style={{
                fontSize: window.innerWidth < 300 ? 15 : 20,
                fontWeight: "normal",
                fontFamily: "Avenir",
              }}
            >
              Reset Password
            </Typography>
          </Button>
          {/* <a href="/signup" style={Styles.link1}>
            Don't Have an account? &nbsp;
            <span style={{ color: "#86191b", fontWeight: "bold" }}>
              Register Now
            </span>
          </a> */}
        </form>
      </Grid>
    </Grid>
  );
};

export default ProfileChangePassword;
