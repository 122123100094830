import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Styles from "./style";
import Styles1 from "./style1";
import { styled } from "@mui/material/styles";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import ImagePath from "../../assets/images";
import { UseMediaQuery } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { newPasswordSchema } from "../../schemas/newPassword";
import PhoneInput from "react-phone-number-input";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase.config";
//import OtpInput from "otp-input-react";
import OtpInput from "react-otp-input";
import { OtpSchema } from "../../schemas/otpSchema";
import { useFormik } from "formik";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { Toaster, toast } from "react-hot-toast";
import PropTypes from "prop-types";
import { Input as BaseInput } from "@mui/base/Input";
import { Box } from "@mui/system";
import "react-international-phone/style.css";
import { storeResetPassId } from "../../Redux/action";
import { useDispatch } from "react-redux";
import {
  defaultCountries,
  FlagImage,
  parseCountry,
  usePhoneInput,
} from "react-international-phone";
import { forgetPasswordSchema } from "../../schemas/forgetPassword";
import { Link, resolvePath } from "react-router-dom";
import { checkMobileUserService } from "../../services/ApiService";
import { setUserId } from "../../utils/localStorage";
import NavBar from "../HomeScreen/NavBar";

const CssPhoneField = styled(TextField)({
  "& label.Mui-focused": {
    color: "grey",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "red",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "lightgrey",
      borderRadius: 5,
      borderWidth: 1,
      background: "#ffd2eb33",
    },
    "&:hover fieldset": {
      borderColor: "#f35491",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#f35491",
    },
  },
});

const initialValues = {
  phone: "",
  phone2: "",
};

const initialValues1 = {
  otp: "",
};

const ForgetPassword = ({ value, ...restProps }) => {
  const [error, setError] = useState(null);
  const [otpSend, setOtpSend] = useState(false);
  const [otp, setOtp] = useState("");
  const [ph, setPh] = useState("");
  const navigate = useNavigate();
  const [min, setMin] = useState(0);
  const [sec, setSec] = useState(60);
  const [showResendLink, setShowResendLink] = useState(false);

  const dispatch = useDispatch();
  const { values, errors, handleChange, handleSubmit, handleBlur, touched } =
    useFormik({
      initialValues: initialValues,
      validationSchema: forgetPasswordSchema,
      onSubmit: async (values) => {
        // console.log("values:", values);
        try {
          const response = await checkMobileUserService(values);
          if (response.status === "success") {
            console.log("successfully find mobile user:", response);
            setUserId(response);
            dispatch(storeResetPassId(response.data));
            setError(null);
            onCall();
            // setTimeout(() => {
            // navigate("/resetpassword");
            // }, 10000);
          } else if (response.status === "error") {
            console.log("user not found: ", response);
            setError(response.message);
          }
        } catch (error) {
          console.log("error in finding user: ", error);
        }
      },
    });

  const { inputValue, handlePhoneValueChange, inputRef, country, setCountry } =
    usePhoneInput({
      defaultCountry: "in",
      value: initialValues.phone.replace(/\D/g, "").slice(-10),
      countries: defaultCountries,
      // onChange: (data) => {
      //   onChange(data.phone);
      // },
    });

  React.useEffect(() => {
    handleChange({
      target: {
        name: "phone",
        value: inputValue.replace(/\D/g, "").slice(-10),
      },
    });
  }, [inputValue]);

  React.useEffect(() => {
    handleChange({
      target: {
        name: "phone2",
        value: inputValue.replace(/\D/g, ""),
      },
    });
  }, [inputValue]);

  // Initialize reCAPTCHA verifier
  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            console.log("reCAPTCHA verified successfully");
            // You can optionally trigger the verification process here
          },
          "expired-callback": () => {
            console.log("reCAPTCHA expired");
            // Handle expired reCAPTCHA if needed
          },
        }
      );
    }
  }

  function onCaptchVerify1() {
    console.log("195");

    window.recaptchaVerifier = new RecaptchaVerifier(
      auth,
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          console.log("reCAPTCHA verified successfully");
          onCall();
          // You can optionally trigger the verification process here
        },
        "expired-callback": () => {
          console.log("reCAPTCHA expired");
          // Handle expired reCAPTCHA if needed
        },
      }
    );
  }

  // Function to initiate phone number verification
  function onCall() {
    onCaptchVerify();
    const appVerifier = window.recaptchaVerifier;
    const formatPh = inputValue;

    signInWithPhoneNumber(auth, formatPh, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        toast.success("OTP sent successfully!", {
          duration: 5000,
          position: "top-right",
        });
        setSec(60);
        setTimeout(() => {
          setOtpSend(true);
        }, 2500);
      })
      .catch((error) => {
        console.error("Error during phone number verification:", error);
        // Handle error
      });
  }

  // Function to initiate phone number verification
  function onCall1() {
    // onCaptchVerify();
    const appVerifier = window.recaptchaVerifier;
    const formatPh = inputValue;
    console.log(formatPh);
    signInWithPhoneNumber(auth, formatPh, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        toast.success("OTP re-sent successfully!", {
          duration: 5000,
          position: "top-right",
        });
        setTimeout(() => {
          setOtpSend(true);
        }, 2500);
      })
      .catch((error) => {
        console.error("Error during phone number verification:", error);
        // Handle error
      });
  }

  function onOTPVerify(e) {
    e.preventDefault();
    console.log(otp);
    window.confirmationResult
      .confirm(otp)
      .then(async (res) => {
        toast.success("Your OTP is correct, you can change your password!", {
          duration: 5000, // Duration in milliseconds
          position: "top-right", // Position of the toast
        });
        setTimeout(() => {
          navigate("/resetpassword");
        }, 2000);
      })
      .catch((error) => {
        toast.error("Please provide correct OTP!", {
          duration: 5000, // Duration in milliseconds
          position: "top-right", // Position of the toast
        });
        console.log(error);
      });
  }

  const handleResendClick = () => {
    setOtpSend(false);
  };

  const resendOTP = () => {
    onCall1(); // Initialize reCAPTCHA verifier again
    setMin(0);
    setSec(60);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (sec > 0) {
        setSec(sec - 1);
      }

      if (sec === 0) {
        if (min === 0) {
          clearInterval(interval);
        } else {
          setSec(59);
          setMin(min - 1);
        }
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [sec]);

  // Prevent focus on the last input field after entering the full OTP
  useEffect(() => {
    if (otp.length === 6) {
      document.activeElement.blur();
    }
  }, [otp]);


  if (!otpSend) {
    return (
      <>
      <Grid container style={{ backgroundColor: "", height: "96px" }}></Grid>
        <Grid container style={Styles.container}>
          <NavBar/>
          <Grid
            container
            style={Styles.grid1}
            lg={6}
            md={6}
            sm={6.5}
            sx={{ display: { xs: "none", sm: "flex" } }}
          >
            {/* <Grid lg={12} md={12} sm={12} style={{}}>
              <img src={ImagePath.Icon} style={Styles.image1}></img>
            </Grid> */}
            <Grid lg={12} md={12} sm={12} style={Styles.grid12}>
              <img src={ImagePath.Iphone} style={Styles.image2}></img>
              <img src={ImagePath.Card} style={Styles.image3}></img>
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              style={Styles.blurOverlay}
              sx={{ display: { xs: "none", sm: "flex", md: "flex" } }}
            ></Grid>
          </Grid>

          <Grid lg={6} md={6} sm={5.5} xs={12} style={Styles.grid2}>
            <Toaster toastOptions={{ duration: 4000 }} />

            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
              style={Styles.form}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 15,
                }}
              >
                <Typography
                  style={{
                    alignContent: "left",
                    //   display: "flex",
                    //   backgroundColor: "red",
                  }}
                >
                  <h1
                    style={{
                      alignContent: "left",
                      fontWeight: "bolder",
                      marginBottom: -18,
                      fontFamily: "Enoway",
                      color:"black",               
fontWeight: 400,
letterSpacing: "0.1rem",
                    }}
                  >
                    Forgot Password?
                  </h1>
                </Typography>
                <Typography style={{ alignContent: "left", display: "flex" }}>
                  <h5 style={{ fontWeight: "normal", color: "grey", fontFamily: "Avenir" }}>
                    Don't worry! it occurs. Please enter the Phone number linked
                    with your account.
                  </h5>
                </Typography>
                {error && (
                  <h5 style={{ color: "red", marginTop: -5, marginBottom: 0 }}>
                    {error}
                  </h5>
                )}
              </div>
              <CssPhoneField
                variant="outlined"
                label="Enter phone number"
                color="primary"
                size="small"
                id="phone2"
                name="phone2"
                autoComplete="off"
                fullWidth
                placeholder="Enter phone number"
                value={inputValue}
                onChange={handlePhoneValueChange}
                onBlur={handleBlur}
                type="tel"
                inputRef={inputRef}
                // style={{ backgroundColor: "#f4f4f4" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      style={{
                        marginRight: "2px",
                        marginLeft: "-8px",
                        zIndex: 1,
                        color: "black",
                      }}
                    >
                      <Select
                        style={{ zIndex: 1 }}
                        MenuProps={{
                          style: {
                            height: "300px",
                            width: "360px",
                            top: "10px",
                            left: "-34px",
                            overflow: "visible "
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                        }}
                        sx={{
                          width: "max-content",
                          // Remove default outline (display only on focus)
                          fieldset: {
                            display: "none",
                            border: "none",
                          },
                          '&.Mui-focused:has(div[aria-expanded="false"])': {
                            fieldset: {
                              display: "block",
                            },
                          },
                          // Update default spacing
                          ".MuiSelect-select": {
                            padding: "8px",
                            paddingRight: "24px !important",
                          },
                          svg: {
                            right: 0,
                          },
                        }}
                        value={country.iso2}
                        onChange={(e) => {
                          setCountry(e.target.value);
                          handleChange({
                            target: {
                              name: "phone",
                              value: `${country.dialCode}${inputValue}`,
                            },
                          });
                        }}
                        renderValue={(value) => (
                          <FlagImage
                            iso2={value}
                            style={{ display: "flex", zIndex: 1 }}
                          />
                        )}
                      >
                        {defaultCountries.map((c) => {
                          const country = parseCountry(c);
                          return (
                            <MenuItem key={country.iso2} value={country.iso2}>
                              <FlagImage
                                iso2={country.iso2}
                                style={{ marginRight: "8px", zIndex: 1 }}
                              />
                              <Typography marginRight="8px">
                                {country.name}
                              </Typography>
                              <Typography color="gray">
                                +{country.dialCode}
                              </Typography>
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </InputAdornment>
                  ),
                }}
                {...restProps}
              />
              {touched.phone2 && errors.phone2 && (
                <h5 style={{ marginTop: 0 }}>{errors.phone2}</h5>
              )}

              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                // onClick={onCall}
                style={Styles.Button}
              >
                <Typography style={{ fontSize: 20, fontWeight: "normal", fontFamily: "Avenir" }}>
                  Send Code
                </Typography>
              </Button>
              <div style={Styles.link2div}>
                <Typography style={{fontFamily: "Avenir"}}>
                {/* <a href="/login" style={Styles.link2}>
                  Remember Password? &nbsp;
                  <span style={{ color: "#EC0673", fontWeight: "bold" }}>
                    Login
                  </span>
                </a> */}
                <Link
              to={"/login"}
              style={Styles.link2}
              
            >
              Remember Password? &nbsp;
                  <span style={{ color: "#EC0673", fontWeight: "bold" }}>
                    Login
                  </span>
            </Link>
                </Typography>
              </div>
            </form>
          </Grid>
        </Grid>
      </>
    );
  } else if (otpSend) {
    return (
      <>
      <Grid container style={{ backgroundColor: "", height: "96px" }}></Grid>
      <Grid container style={Styles1.container}>
        <NavBar/>
        <Grid
          container
          style={Styles1.grid1}
          lg={6}
          md={6}
          sm={6.5}
          sx={{ display: { xs: "none", sm: "flex" } }}
        >
          {/* <Grid lg={12} md={12} sm={12} style={{}}>
            <img src={ImagePath.Icon} style={Styles1.image1}></img>
          </Grid> */}
          <Grid lg={12} md={12} sm={12} style={Styles1.grid11}>
            <img src={ImagePath.Card} style={Styles1.image2}></img>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            style={Styles.blurOverlay}
            sx={{ display: { xs: "none", sm: "flex", md: "flex" } }}
          ></Grid>
        </Grid>

        <Grid lg={6} md={6} sm={5.5} xs={12} style={Styles1.grid2}>
          <div
            style={
              {
                // marginLeft:
                //   window.innerWidth < 600
                //     ? 10
                //     : window.innerWidth > 860
                //     ? -50
                //     : -15,
              }
            }
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                // marginLeft: 10,
              }}
            >
              <Typography
                style={{
                  alignContent: "left",
                  //   display: "flex",
                  //   backgroundColor: "red",
                }}
              >
                <h1
                  style={{
                    alignContent: "left",
                    fontWeight: "bolder",
                    marginBottom: -10,
                    fontFamily: "Enoway",               
fontWeight: 400,
letterSpacing: "0.1rem",
                  }}
                >
                  <span style={{color:"#EC0673"}}>OTP</span> Verification
                </h1>
              </Typography>
              <Typography style={{ alignContent: "left", display: "flex" }}>
                <h5 style={{ fontWeight: "normal", color: "grey", fontFamily: "Avenir" }}>
                  Enter the verification code we just sent on your phone number.
                </h5>
              </Typography>
            </div>
            <Toaster toastOptions={{ duration: 4000 }} />

            <form
            // onSubmit={handleSubmit1}
            // style={{ marginLeft: window.innerWidth > 800 ? -50 : 0 }}
            >
              <Grid
                item
                container
                spacing={1}
                alignItems="center"
                justifyContent="space-between"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: -10,
                  marginRight: 20,
                }}
              >
                <OtpInput
                  id="otp"
                  name="otp"
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  // renderSeparator={<span>-</span>}
                  renderInput={(props) => <input {...props} />}
                  inputStyle={{
                    width:
                      window.innerWidth > 900
                        ? "43px"
                        : window.innerWidth < 250
                        ? "25px"
                        : window.innerWidth > 660 && window.innerWidth < 730
                        ? "27px"
                        : window.innerWidth > 600 && window.innerWidth < 660
                        ? "23px"
                        : "32px",
                    height: "40px",
                    fontSize: "16px",
                    margin:
                      window.innerWidth > 400
                        ? "0 7px"
                        : window.innerWidth < 250
                        ? "0 2px"
                        : "0 3px",
                    borderRadius: "5px",
                    border: "1px solid #f35491",
                    textAlign: "center",
                    "& : hover": {
                      border: "1px solid #f35491",
                    },
                    background: "#ffd2eb33",
                  }}
                />
              </Grid>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={onOTPVerify}
                disabled={!otpSend} // Disable the button if otpsend is false
                fullWidth
                style={Styles1.button}
              >
                <Typography style={{ fontSize: 20, fontWeight: "normal", fontFamily: "Avenir" }}>
                  Verify
                </Typography>
              </Button>
              {/* <div style={Styles1.link2div}>
                {showResendLink ? (
                  <a style={Styles1.link2} onClick={handleResendClick}>
                    Resend OTP
                  </a>
                ) : (
                  <span>
                    Didn't receive code?&nbsp;
                    <span style={{ color: "#86191b", fontWeight: "bold" }}>
                      {timer} seconds
                    </span>
                  </span>
                )}
              </div> */}

              <div style={Styles1.link2div}>
                <Typography
                  style={{ color: "grey", fontWeight: "lighter", fontSize: 15, fontFamily: "Avenir" }}
                >
                  Resend OTP in &nbsp;
                  <span style={{ fontWeight: 600 }}>
                    {min < 10 ? `0${min}` : min} : {sec < 10 ? `0${sec}` : sec}
                  </span>
                  &nbsp;sec
                </Typography>
                <div style={{ display: "flex" }}>
                <Typography style={{fontFamily: "Avenir"}}>
                  <span style={{ fontWeight: "bold", fontSize: 16 }}>
                    Didn't receive code?&nbsp; &nbsp;
                  </span>
                  {sec === 0 && min === 0 && (
                    <a
                      disabled={sec > 0 || min > 0}
                      style={{
                        color: sec > 0 || min > 0 ? "lightgrey" : "#EC0673",
                        fontWeight: sec > 0 || min > 0 ? "normal" : "bold",
                        fontSize: 16,
                        cursor: sec > 0 || min > 0 ? "not-allowed" : "pointer",
                      }}
                      onClick={resendOTP}
                    >
                      Resend
                    </a>
                  )}
                </Typography>
                </div>
              </div>
            </form>
          </div>
        </Grid>
      </Grid>
      </>
    );
  }
};

export default ForgetPassword;
