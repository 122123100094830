import { Grid, Typography } from "@mui/material";
import fontStyle from "../../../assets/fontStyle";
import React from "react";

const Footer3 = () => {
  return (
    <>
            <Grid container sx={{  display: "flex", justifyContent: "center", backgroundColor: "#FFF9EC",  margin: "0 auto" }}>
      <Grid
        container
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
          // backgroundColor: "#EC0673",
          backgroundColor:" #FFF9EC",
          padding: "10px 0px",
          maxWidth: "1400px",
          paddingLeft: "7%",
          paddingRight: "7%",
        }}
      >
        <Typography
          style={{
            color: "black",
            fontSize: window.innerWidth > 700 ? "15px" : "15px",
            marginLeft: window.innerWidth > 700 ? 0 : 18,
          }}
        >
          Copyright@ 2024 All Rights Reserved by{" "}
          <span style={{ color: "#EC0673" }}>
            Make My BioData
          </span>
        </Typography>
      </Grid>
      </Grid>
    </>
  );
};

export default Footer3;
