import { Grid, Typography } from "@mui/material";
import fontStyle from "../../../assets/fontStyle";
import React from "react";

const AboutBioData = () => {
  return (
    <Grid container sx={{  display: "flex", justifyContent: "center", backgroundColor: "white",  margin: "0 auto" }}>
    <Grid
      style={{
        height: "auto", // Set height to auto to adjust based on content
        maxHeight: 1300, // Limit the maximum height of the container
        backgroundColor: "white",
        display: "flex",
        alignContent: "center",
        padding:  window.innerWidth > 1400 ? 0 : "0 7% 0 7%",
        flexDirection: "column",
        // justifyContent: "center",
        maxWidth: "1400px",
       paddingTop:"2.5%",
       paddingBottom:"2.5%",
        marginBottom: 30,
      }}
    >
      <h1 style={{ fontFamily: "Enoway",
    color: "#EC0673",
    fontSize: 35,
    fontWeight: 400,
    letterSpacing: "0.1rem", textAlign:"center"}}><span style={{color: "black"}}>About</span> Make My BioData</h1>
     <Typography style={{fontFamily: "Avenir",
    fontSize: 16,
    color: "black",
    fontWeight:"bold",
    textAlign:"center"}}>
       For wedding biodata made for you!
      </Typography>
      <Typography style={{fontFamily: "Avenir",
    fontSize: 16,
    color: "black",
    marginTop: 20,
    textAlign:"center"}}>
        We are<span style={{fontWeight:"bold"}}> MakeMyBioData</span>- your go-to crew for the most magical part of your marriage- the wedding Biodata! We are more than just 'around-the-corner' <span style={{fontWeight:"bold"}}>affordable wedding biodata designers in India.</span>  We're wedding biodata masters. With a blend of creativity and finesse, we create bespoke biodata to set the stage for the grand celebration that is your wedding. We know that weddings is your occasion to shine what you hold dear- your parampara and your sanskar. With us you get to customise your biodata in Hindi, English, or Gujarati—it's your love story told your way. Top-tier quality, zero stress. Because at <span style={{fontWeight:"bold"}}>MakeMyBioData</span>, we make "mangalams" unforgettable.
      </Typography>
    </Grid>
    </Grid>
  );
};

export default AboutBioData;