import { Grid, Typography } from "@mui/material";
import React from "react";
import ImagePath from "../../../assets/images";
import { FaFacebookSquare } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
import { FaEnvelope, FaXTwitter } from "react-icons/fa6";
import fontStyle from "../../../assets/fontStyle";
import { IoLogoYoutube } from "react-icons/io";
import { FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";


const FooterNew = () => {
  return (
    <>
        <Grid container sx={{  display: "flex", justifyContent: "center", backgroundColor: "#FFF9EC",  margin: "0 auto" }}>

      <Grid
        container
        style={{
          height: "auto",
          maxWidth: "1400px",
          padding: window.innerWidth > 1400 ? " 0  0" : "0 7% 0 7%",
          backgroundColor:" #FFF9EC"
        }}
      >
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "start",
            // height:"200px",
            // backgroundColor:"white",
            padding:"0 0 2rem 0"
            // alignContent: "center",
            // alignItems: "center",
          }}
        >

          {/* <div>
            <img
              src={ImagePath.Icon}
              style={{
                width: 350,
                display: "flex",
                justifyContent: "center",
              }}
            ></img>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                // alignContent: "center",
                // alignItems: "center",
                marginTop: -30,
                marginLeft: 30,
              }}
            >
              <Link
                to={"/"}
                style={{
                  "&:hover": {
                    color: "pink",
                  },
                }}
              >
                <FaFacebookSquare
                  style={{
                    marginRight: 25,
                    fontSize: 30,
                    color: "#EC0673",
                    transition: "color 0.3s ease",
                  }}
                  onMouseOver={(e) => (e.currentTarget.style.color = "black")}
                  onMouseOut={(e) =>
                    (e.currentTarget.style.color = "#EC0673")
                  }
                />
              </Link>
              <FaXTwitter
                style={{
                  marginRight: 25,
                  fontSize: 30,
                  color: "#EC0673",
                  transition: "color 0.3s ease",
                }}
                onMouseOver={(e) => (e.currentTarget.style.color = "black")}
                onMouseOut={(e) =>
                  (e.currentTarget.style.color = "#EC0673")
                }
              />
              <BsInstagram
                style={{
                  marginRight: 25,
                  fontSize: 30,
                  color: "#EC0673",
                  transition: "color 0.3s ease",
                }}
                onMouseOver={(e) => (e.currentTarget.style.color = "black")}
                onMouseOut={(e) =>
                  (e.currentTarget.style.color = "#EC0673")
                }
              />
              <IoLogoYoutube
                style={{
                  marginRight: 25,
                  fontSize: 30,
                  color: "#EC0673",
                  transition: "color 0.3s ease",
                }}
                onMouseOver={(e) => (e.currentTarget.style.color = "black")}
                onMouseOut={(e) =>
                  (e.currentTarget.style.color = "#EC0673")
                }
              />
              <FaLinkedin
                style={{
                  marginRight: 25,
                  fontSize: 30,
                  color: "#EC0673",
                  transition: "color 0.3s ease",
                }}
                onMouseOver={(e) => (e.currentTarget.style.color = "black")}
                onMouseOut={(e) =>
                  (e.currentTarget.style.color = "#EC0673")
                }
              />
            </div>
          </div> */}
        {/* <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
          >  
        </Grid> */}
        <Grid item lg={4} md={4} sm={12} xs={12} sx={{backgroundColor:""}}>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{backgroundColor:"", display:"flex", justifyContent: window.innerWidth > 950 ? "" : "center", marginBottom:-2}}>   
        <img
              src={ImagePath.Icon}
              style={{
                width: window.innerWidth > 950 ? 280 : 320,
                display: "flex",
                justifyContent: "center",
              }}
            ></img>
            </Grid>
<Grid item lg={12} md={12} sm={12} xs={12} sx={{backgroundColor:"", padding:"0 1rem 0 2rem"}}>
    <Typography style={{fontSize:"15px", fontWeight:500,color:"black",lineHeight:2, fontFamily:"Avenir", textAlign: window.innerWidth > 950 ? "left" : "center"}}><span style={{fontWeight:"bold"}}>Make My BioData</span>: Where Tradition Meets Trend, Crafting Wedding Biodata with a Dash of Creativity. With us, you get to introduce yourself with grace and tradition. Let your story be the beginning of a beautiful love story with our customizable biodata.</Typography>
</Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}
              style={{
                display: "flex",
                justifyContent: window.innerWidth > 950 ? "flex-start" : "center",
                // alignContent: "center",
                // alignItems: "center",
                marginTop: 20,
                padding:"0 2rem"
                // marginLeft: 30,
              }}
            >
              <Link
                to={"/"}
                style={{
                  "&:hover": {
                    color: "pink",
                  },
                }}
              >
                <FaFacebookSquare
                  style={{
                    marginRight: 25,
                    fontSize: 20,
                    color: "#EC0673",
                    transition: "color 0.3s ease",
                  }}
                  onMouseOver={(e) => (e.currentTarget.style.color = "black")}
                  onMouseOut={(e) =>
                    (e.currentTarget.style.color = "#EC0673")
                  }
                />
              </Link>
              <FaXTwitter
                style={{
                  marginRight: 25,
                  fontSize: 20,
                  color: "#EC0673",
                  transition: "color 0.3s ease",
                }}
                onMouseOver={(e) => (e.currentTarget.style.color = "black")}
                onMouseOut={(e) =>
                  (e.currentTarget.style.color = "#EC0673")
                }
              />
              <BsInstagram
                style={{
                  marginRight: 25,
                  fontSize: 20,
                  color: "#EC0673",
                  transition: "color 0.3s ease",
                }}
                onMouseOver={(e) => (e.currentTarget.style.color = "black")}
                onMouseOut={(e) =>
                  (e.currentTarget.style.color = "#EC0673")
                }
              />
              <IoLogoYoutube
                style={{
                  marginRight: 25,
                  fontSize: 20,
                  color: "#EC0673",
                  transition: "color 0.3s ease",
                }}
                onMouseOver={(e) => (e.currentTarget.style.color = "black")}
                onMouseOut={(e) =>
                  (e.currentTarget.style.color = "#EC0673")
                }
              />
              <FaLinkedin
                style={{
                  marginRight: 25,
                  fontSize: 20,
                  color: "#EC0673",
                  transition: "color 0.3s ease",
                }}
                onMouseOver={(e) => (e.currentTarget.style.color = "black")}
                onMouseOut={(e) =>
                  (e.currentTarget.style.color = "#EC0673")
                }
              />
            </Grid>
          
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12} sx={{backgroundColor:""}}>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{backgroundColor:"", padding:"3rem 0 1rem 0", display:"flex", justifyContent:"center"}}>
        <Typography style={{fontSize:"23px", fontWeight: 900, color:"black", fontFamily:"Enoway", letterSpacing: "0.1rem",}}>Quick Links</Typography>
      </Grid>
      <Grid container>
      <Grid item lg={12} md={12} sm={12} xs={12} sx={{backgroundColor:"",padding:"2.5rem 0 1rem 0", display:"flex", justifyContent:"center", flexDirection:"column", textAlign:"center"}}>
      <div style={{ display: 'inline-flex',  justifyContent:"center" }}>
            {/* <a
              href="/aboutus"
              style={fontStyle.FooterContentN}
              onMouseOver={(e) => (e.currentTarget.style.color = "#EC0673")}
              onMouseOut={(e) =>
                (e.currentTarget.style.color = "black")
              }
              
            >
              About Us
            </a> */}
            <Link
              to={"/aboutus"}
              style={fontStyle.FooterContentN}
              onMouseOver={(e) => (e.currentTarget.style.color = "#EC0673")}
              onMouseOut={(e) =>
                (e.currentTarget.style.color = "black")
              }
            >
              About Us
            </Link>
            </div>
         
            <div style={{ display: 'inline-flex',  justifyContent:"center" }}>
            <Link
              to={"/personaldetails"}
              style={fontStyle.FooterContentN}
              onMouseOver={(e) => (e.currentTarget.style.color = "#EC0673")}
              onMouseOut={(e) =>
                (e.currentTarget.style.color = "black")
              }
            >
              Make My BioData
            </Link>
            </div>
          

            <div style={{ display: 'inline-flex',  justifyContent:"center" }}>
            {/* <a
              href="/policy"
              style={fontStyle.FooterContentN}
              onMouseOver={(e) => (e.currentTarget.style.color = "#EC0673")}
              onMouseOut={(e) =>
                (e.currentTarget.style.color = "black")
              }
            >
              Privacy Policy
            </a> */}
            <Link
              to={"/policy"}
              style={fontStyle.FooterContentN}
              onMouseOver={(e) => (e.currentTarget.style.color = "#EC0673")}
              onMouseOut={(e) =>
                (e.currentTarget.style.color = "black")
              }
            >
              Privacy Policy
            </Link>
            </div>

            <div style={{ display: 'inline-flex',  justifyContent:"center" }}>
            {/* <a
              href="/faqs"
              style={fontStyle.FooterContentN}
              onMouseOver={(e) => (e.currentTarget.style.color = "#EC0673")}
              onMouseOut={(e) =>
                (e.currentTarget.style.color = "black")
              }
            >
              FAQ's
            </a> */}
            <Link
              to={"/faqs"}
              style={fontStyle.FooterContentN}
              onMouseOver={(e) => (e.currentTarget.style.color = "#EC0673")}
              onMouseOut={(e) =>
                (e.currentTarget.style.color = "black")
              }
            >
              FAQ's
            </Link>
            </div>
         
            <div style={{ display: 'inline-flex',  justifyContent:"center" }}>
            {/* <a
              href="/contactus"
              style={fontStyle.FooterContentN}
              onMouseOver={(e) => (e.currentTarget.style.color = "#EC0673")}
              onMouseOut={(e) =>
                (e.currentTarget.style.color = "black")
              }
            >
              Contact Us
            </a> */}
            <Link
              to={"/contactus"}
              style={fontStyle.FooterContentN}
              onMouseOver={(e) => (e.currentTarget.style.color = "#EC0673")}
              onMouseOut={(e) =>
                (e.currentTarget.style.color = "black")
              }
            >
              Contact Us
            </Link>
            </div>

            <div style={{ display: 'inline-flex',  justifyContent:"center" }}>
      {/* <a
              href="/termscondition"
              style={{ ...fontStyle.FooterContentN }}
              onMouseOver={(e) => (e.currentTarget.style.color = "#EC0673")}
              onMouseOut={(e) =>
                (e.currentTarget.style.color = "black")
              }
            >
              Terms and Conditions
            </a> */}
            <Link
              to={"/termscondition"}
              style={fontStyle.FooterContentN}
              onMouseOver={(e) => (e.currentTarget.style.color = "#EC0673")}
              onMouseOut={(e) =>
                (e.currentTarget.style.color = "black")
              }
            >
              Terms and Conditions
            </Link>
            </div>
          
      </Grid>
      </Grid>
        </Grid>


        <Grid item lg={4} md={4} sm={12} xs={12} sx={{backgroundColor:"", justifyContent:"center"}}>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{backgroundColor:"", padding:"3rem 0 1rem 0", display:"flex", justifyContent:"center"}}>
        <Typography style={{fontSize:"23px", fontWeight: 900, color:"black", fontFamily:"Enoway", letterSpacing: "0.1rem",}}>Contact Details</Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} sx={{backgroundColor:"", padding:"2rem 0 1rem 0", display:"flex", justifyContent:"center"}}>
      <Typography style={{ marginBottom: 30, color: "#333333", fontFamily: 'Avenir', display: "flex", alignItems: "center" }}>
              <FaEnvelope style={{ fontSize: 17, marginRight: 10 }} />{" "}
              contact@makemybiodata.in
            </Typography>
      </Grid>
      <Grid container>
      
      </Grid>
        </Grid>
        </Grid>
        <hr
          style={{
            backgroundColor: "#EC0673",
            height: 1.5,
            width: 2000,
            display: "flex",
            margin: "15px 10px",
            alignSelf: "center",
            justifySelf: "center",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        />

      </Grid>
      </Grid>
    </>
  );
};

export default FooterNew;