import React, { useEffect, useState } from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const CircularProgress = () => {
  const [progress, setProgress] = useState(0);

  const updateProgress = () => {
    const scrollTop = window.scrollY;
    const docHeight = document.body.scrollHeight - window.innerHeight;
    const scrolled = (scrollTop / docHeight) * 100;
    setProgress(scrolled);
  };

  useEffect(() => {
    window.addEventListener('scroll', updateProgress);
    return () => window.removeEventListener('scroll', updateProgress);
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const radius = 25; // Reduced radius for smaller circle
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (progress / 100) * circumference;

  // Hide progress bar when at the top of the screen
  const isAtTop = progress === 0;

  return (
    !isAtTop && (
      <div 
        onClick={handleScrollToTop} 
        style={{ 
          position: 'fixed', 
          bottom: '20px', 
          right: '20px', 
          zIndex: 1000, 
        //   opacity: 1, 
          cursor: 'pointer' // Add pointer cursor to indicate clickability
        }}
      >
        <svg width="80" height="80" bottom= '25px' right= '25px' >
          <circle
            stroke="rgb(238 119 36)"
            fill="transparent"
            strokeWidth="2"
            strokeDasharray={circumference}
            strokeDashoffset={offset}
            r={radius}
            cx="30"
            cy="30"
          />
          <foreignObject x="15" y="15" width="30" height="30">
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
              <ArrowUpwardIcon
                sx={{
                  color: 'rgb(238 119 36)',
                  transform: 'scale(1.3)', // Adjust the scale to make the icon smaller
                }}
              />
            </div>
          </foreignObject>
        </svg>
      </div>
    )
  );
};

export default CircularProgress;


