import { Grid, Typography } from "@mui/material";
import fontStyle from "../../../assets/fontStyle";
import React from "react";

const Content = () => {
  return (
    <Grid container sx={{  display: "flex", justifyContent: "center", backgroundColor: "rgba(248, 248, 248, 1)",  margin: "0 auto" }}>

    <Grid
      style={{
        // height: 420,
        height: "auto", // Set height to auto to adjust based on content
        maxHeight: 50000, // Limit the maximum height of the container
        backgroundColor: "rgba(248, 248, 248, 1)",
        display: "flex",
        alignContent: "center",
        flexDirection: "column",
        maxWidth: "1400px",
padding:  window.innerWidth > 1400 ? "40px 0" : "40px 7% 40px 7%",
        // justifyContent: "center",
        // padding:
        //   window.innerWidth < 300
        //     ? "10px 7%"
        //     : window.innerWidth > 600
        //     ? "40px 7%"
        //     : "10px 7%",
      }}
    >
      <h1 style={fontStyle.HeadingFont}>Interpretation and Definitions</h1>
      <div style={{ marginBottom: 10 }}>
        <h2 style={fontStyle.ContentHeading}>Interpretation</h2>
        <Typography style={fontStyle.Contentfont1}>
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural.
        </Typography>
      </div>

      <div style={{ marginBottom: 10 }}>
        <h2 style={fontStyle.ContentHeading}>Definitions</h2>
        <Typography sx={{ display: "flex", fontSize: 16 }}>
          For the purposes of this Privacy Policy:
        </Typography>

        <Typography style={fontStyle.Contentfont2}>
          <span style={{ fontWeight: "bold", color: "black" }}>• Account</span>{" "}
          means a unique account created for You to access our Service or parts
          of our Service.
        </Typography>

        <Typography style={fontStyle.Contentfont2}>
          <span style={{ fontWeight: "bold", color: "black" }}>
            • Affiliate
          </span>{" "}
          means an entity that controls, is controlled by or is under common
          control with a party, where "control" means ownership of 50% or more
          of the shares, equity interest or other securities entitled to vote
          for election of directors or other managing authority.
        </Typography>

        <Typography style={fontStyle.Contentfont2}>
          <span style={{ fontWeight: "bold", color: "black" }}>
            • Application
          </span>{" "}
          refers to Make My BioData, the software program provided by the
          Company.
        </Typography>

        <Typography style={fontStyle.Contentfont2}>
          <span style={{ fontWeight: "bold", color: "black" }}>• Company</span>{" "}
          (referred to as either "the Company", "We", "Us" or "Our" in this
          Agreement) refers to Make My BioData.
        </Typography>

        <Typography style={fontStyle.Contentfont2}>
          <span style={{ fontWeight: "bold", color: "black" }}>• Cookies</span>{" "}
          are small files that are placed on Your computer, mobile device or any
          other device by a website, containing the details of Your browsing
          history on that website among its many uses.
        </Typography>

        <Typography style={fontStyle.Contentfont2}>
          <span style={{ fontWeight: "bold", color: "black" }}>• Country</span>{" "}
          refers to: Gujarat, India
        </Typography>

        <Typography style={fontStyle.Contentfont2}>
          <span style={{ fontWeight: "bold", color: "black" }}>• Device </span>{" "}
          means any device that can access the Service such as a computer, a
          cellphone or a digital tablet.
        </Typography>

        <Typography style={fontStyle.Contentfont2}>
          <span style={{ fontWeight: "bold", color: "black" }}>
            • Personal Data{" "}
          </span>{" "}
          is any information that relates to an identified or identifiable
          individual.
        </Typography>

        <Typography style={fontStyle.Contentfont2}>
          <span style={{ fontWeight: "bold", color: "black" }}>• Service </span>{" "}
          refers to the Application or the Website or both.
        </Typography>

        <Typography style={fontStyle.Contentfont2}>
          <span style={{ fontWeight: "bold", color: "black" }}>
            • Service Provider
          </span>{" "}
          means any natural or legal person who processes the data on behalf of
          the Company. It refers to third-party companies or individuals
          employed by the Company to facilitate the Service, to provide the
          Service on behalf of the Company, to perform services related to the
          Service or to assist the Company in analyzing how the Service is used.
        </Typography>

        <Typography style={fontStyle.Contentfont2}>
          <span style={{ fontWeight: "bold", color: "black" }}>
            • Third-party Social Media Service
          </span>{" "}
          refers to any website or any social network website through which a
          User can log in or create an account to use the Service.
        </Typography>

        <Typography style={fontStyle.Contentfont2}>
          <span style={{ fontWeight: "bold", color: "black" }}>
            • Usage Data
          </span>{" "}
          refers to data collected automatically, either generated by the use of
          the Service or from the Service infrastructure itself (for example,
          the duration of a page visit).
        </Typography>

        <Typography style={fontStyle.Contentfont2}>
          <span style={{ fontWeight: "bold", color: "black" }}> • Website</span>{" "}
          refers to Make My BioData, accessible from
          http://www.makemybiodata.in
        </Typography>

        <Typography style={fontStyle.Contentfont2}>
          <span style={{ fontWeight: "bold", color: "black" }}>• You </span>{" "}
          means the individual accessing or using the Service, or the company,
          or other legal entity on behalf of which such individual is accessing
          or using the Service, as applicable.
        </Typography>
      </div>

      <h1 style={fontStyle.HeadingFont}>
        Collecting and Using Your Personal Data
      </h1>
      <div style={{ marginBottom: 10 }}>
        <h2 style={fontStyle.ContentHeading}>Types of Data Collected</h2>
        <div>
          <h3 style={fontStyle.ContentHeading}>Personal Data</h3>
          <Typography style={fontStyle.Contentfont1}>
            While using Our Service, We may ask You to provide Us with certain
            personally identifiable information that can be used to contact or
            identify You. Personally identifiable information may include, but
            is not limited to:
            <br />
            <span style={{ fontWeight: "bold", color: "black" }}>•</span> Email
            address
            <br />
            <span style={{ fontWeight: "bold", color: "black" }}>•</span> First
            name and last name
            <br />
            <span style={{ fontWeight: "bold", color: "black" }}>•</span> Phone
            number
            <br />
            <span style={{ fontWeight: "bold", color: "black" }}>•</span>{" "}
            Address, State, Province, ZIP/Postal code, City
            <br />
            <span style={{ fontWeight: "bold", color: "black" }}>•</span> Usage
            Data
            <br />
          </Typography>
        </div>

        <div>
          <h3 style={fontStyle.ContentHeading}>Usage Data</h3>
          <Typography style={fontStyle.Contentfont1}>
            Usage Data is collected automatically when using the Service. Usage
            Data may include information such as Your Device's Internet Protocol
            address (e.g. IP address), browser type, browser version, the pages
            of our Service that You visit, the time and date of Your visit, the
            time spent on those pages, unique device identifiers and other
            diagnostic data.
          </Typography>
          <Typography style={fontStyle.Contentfont1}>
            When You access the Service by or through a mobile device, We may
            collect certain information automatically, including, but not
            limited to, the type of mobile device You use, Your mobile device
            unique ID, the IP address of Your mobile device, Your mobile
            operating system, the type of mobile Internet browser You use,
            unique device identifiers and other diagnostic data.
          </Typography>
          <Typography style={fontStyle.Contentfont1}>
            We may also collect information that Your browser sends whenever You
            visit our Service or when You access the Service by or through a
            mobile device.
          </Typography>
        </div>

        <div>
          <h3 style={fontStyle.ContentHeading}>
            Information from Third-Party Social Media Services
          </h3>
          <Typography style={fontStyle.Contentfont1}>
            The Company allows You to create an account and log in to use the
            Service through the following Third-party Social Media Services:
            <br />
            <span style={{ fontWeight: "bold", color: "black" }}>•</span> Google
            <br />
            <span style={{ fontWeight: "bold", color: "black" }}>•</span>{" "}
            Facebook
            <br />
            <span style={{ fontWeight: "bold", color: "black" }}>•</span>{" "}
            Instagram
            <br />
            <span style={{ fontWeight: "bold", color: "black" }}>•</span>{" "}
            Twitter
            <br />
            <span style={{ fontWeight: "bold", color: "black" }}>•</span>{" "}
            LinkedIn
            <br />
          </Typography>
          <Typography style={fontStyle.Contentfont1}>
            If You decide to register through or otherwise grant us access to a
            Third-Party Social Media Service, We may collect Personal data that
            is already associated with Your Third-Party Social Media Service's
            account, such as Your name, Your email address, Your activities or
            Your contact list associated with that account.
          </Typography>
          <Typography style={fontStyle.Contentfont1}>
            You may also have the option of sharing additional information with
            the Company through Your Third-Party Social Media Service's account.
            If You choose to provide such information and Personal Data, during
            registration or otherwise, You are giving the Company permission to
            use, share, and store it in a manner consistent with this Privacy
            Policy.
          </Typography>
        </div>

        <div>
          <h3 style={fontStyle.ContentHeading}>
            Information Collected while Using the Application
          </h3>
          <Typography style={fontStyle.Contentfont1}>
            While using Our Application, in order to provide features of Our
            Application, We may{" "}
          </Typography>
          <Typography style={{ fontSize: 16, color: "black" }}>
            collect, with Your prior permission:
            <br />
            <span style={{ fontWeight: "bold", color: "black" }}>•</span>{" "}
            Information regarding your location
            <br />
            <span style={{ fontWeight: "bold", color: "black" }}>•</span>{" "}
            Information from your Device's phone book (contacts list)
            <br />
            <span style={{ fontWeight: "bold", color: "black" }}>•</span>{" "}
            Pictures and other information from your Device's camera and photo
            library
            <br />
          </Typography>
          <Typography style={fontStyle.Contentfont1}>
            We use this information to provide features of Our Service, to
            improve and customize Our Service. The information may be uploaded
            to the Company's servers and/or a Service Provider's server or it
            may be simply stored on Your device.
          </Typography>
          <Typography style={fontStyle.Contentfont1}>
            You can enable or disable access to this information at any time,
            through Your Device settings.
          </Typography>
        </div>

        <div>
          <h3 style={fontStyle.ContentHeading}>Tracking Technologies and Cookies</h3>
          <Typography style={fontStyle.Contentfont1}>
            We use Cookies and similar tracking technologies to track the
            activity on Our Service and store certain information. Tracking
            technologies used are beacons, tags, and scripts to collect and
            track information and to improve and analyze Our Service. The
            technologies We use may include:
            <br />
            <Typography
              style={fontStyle.Contentfont2}
            >
              <span style={{ fontWeight: "bold", color: "black" }}>•</span>{" "}
              Cookies or Browser Cookies. A cookie is a small file placed on
              Your Device. You can instruct Your browser to refuse all Cookies
              or to indicate when a Cookie is being sent. However, if You do not
              accept Cookies, You may not be able to use some parts of our
              Service. Unless you have adjusted Your browser setting so that it
              will refuse Cookies, our Service may use Cookies.
              <br />
              <span style={{ fontWeight: "bold", color: "black" }}>•</span> Web
              Beacons. Certain sections of our Service and our emails may
              contain small electronic files known as web beacons (also referred
              to as clear gifs, pixel tags, and single-pixel gifs) that permit
              the Company, for example, to count users who have visited those
              pages or opened an email and for other related website statistics
              (for example, recording the popularity of a certain section and
              verifying system and server integrity).
            </Typography>
          </Typography>
          <Typography style={fontStyle.Contentfont1}>
            Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
            remain on Your personal computer or mobile device when You go
            offline, while Session Cookies are deleted as soon as You close Your
            web browser. You can learn more about cookies on TermsFeed website
            article.
          </Typography>
          <Typography style={fontStyle.Contentfont1}>
            We use both Session and Persistent Cookies for the purposes set out
            below:
            <Typography
              style={fontStyle.Contentfont2}
            >
              <span style={{ fontWeight: "bold", color: "black" }}>
                • Necessary / Essential Cookies
              </span>{" "}
              <br />
              Type: Session Cookies
              <br />
              Administered by: Us
              <br />
              Purpose: These Cookies are essential to provide You with services
              available through the Website and to enable You to use some of its
              features. They help to authenticate users and prevent fraudulent
              use of user accounts. Without these Cookies, the services that You
              have asked for cannot be provided, and We only use these Cookies
              to provide You with those services.
              <br />
              <span style={{ fontWeight: "bold", color: "black" }}>
                • Cookies Policy / Notice Acceptance Cookies
              </span>{" "}
              <br />
              Type: Persistent Cookies
              <br /> Administered by: Us
              <br /> Purpose: These Cookies identify if users have accepted the
              use of cookies on the Website.
              <br />
              <span style={{ fontWeight: "bold", color: "black" }}>
                • Functionality Cookies
              </span>{" "}
              <br />
              Type: Persistent Cookies
              <br /> Administered by: Us
              <br /> Purpose: These Cookies allow us to remember choices You
              make when You use the Website, such as remembering your login
              details or language preference. The purpose of these Cookies is to
              provide You with a more personal experience and to avoid You
              having to re-enter your preferences every time You use the
              Website.
            </Typography>
          </Typography>
          <Typography style={fontStyle.Contentfont1}>
            For more information about the cookies we use and your choices
            regarding cookies, please visit our Cookies Policy or the Cookies
            section of our Privacy Policy.
          </Typography>
        </div>
      </div>

      <div style={{ marginBottom: 10 }}>
        <h2 style={fontStyle.ContentHeading}>Use of Your Personal Data</h2>
        <div>
          <Typography
           style={fontStyle.Contentfont1}
          >
            The Company may use Personal Data for the following purposes:
            <Typography
              style={fontStyle.Contentfont2}
            >
              <span style={{ fontWeight: "bold", color: "black" }}>
                • To provide and maintain our Service,
              </span>{" "}
              including to monitor the usage of our Service.
              <br />
              <span style={{ fontWeight: "bold", color: "black" }}>
                • To manage Your Account:
              </span>{" "}
              to manage Your registration as a user of the Service. The Personal
              Data You provide can give You access to different functionalities
              of the Service that are available to You as a registered user.
              <br />
              <span style={{ fontWeight: "bold", color: "black" }}>
                • For the performance of a contract:
              </span>{" "}
              the development, compliance and undertaking of the purchase
              contract for the products, items or services You have purchased or
              of any other contract with Us through the Service.
              <br />
              <span style={{ fontWeight: "bold", color: "black" }}>
                • To contact You:
              </span>{" "}
              To contact You by email, telephone calls, SMS, or other equivalent
              forms of electronic communication, such as a mobile application's
              push notifications regarding updates or informative communications
              related to the functionalities, products or contracted services,
              including the security updates, when necessary or reasonable for
              their implementation.
              <br />
              <span style={{ fontWeight: "bold", color: "black" }}>
                • To provide You
              </span>{" "}
              with news, special offers and general information about other
              goods, services and events which we offer that are similar to
              those that you have already purchased or enquired about unless You
              have opted not to receive such information.
              <br />
              <span style={{ fontWeight: "bold", color: "black" }}>
                • To manage Your requests:
              </span>{" "}
              To attend and manage Your requests to Us.
              <br />
              <span style={{ fontWeight: "bold", color: "black" }}>
                • For business transfers:
              </span>{" "}
              We may use Your information to evaluate or conduct a merger,
              divestiture, restructuring, reorganization, dissolution, or other
              sale or transfer of some or all of Our assets, whether as a going
              concern or as part of bankruptcy, liquidation, or similar
              proceeding, in which Personal Data held by Us about our Service
              users is among the assets transferred.
              <br />
              <span style={{ fontWeight: "bold", color: "black" }}>
                • For other purposes:
              </span>{" "}
              We may use Your information for other purposes, such as data
              analysis, identifying usage trends, determining the effectiveness
              of our promotional campaigns and to evaluate and improve our
              Service, products, services, marketing and your experience.
            </Typography>
          </Typography>

          <Typography style={fontStyle.Contentfont1}>
            We may share Your personal information in the following situations:
            <Typography
             style={fontStyle.Contentfont2}
            >
              <span style={{ fontWeight: "bold", color: "black" }}>
                • With Service Providers:
              </span>{" "}
              We may share Your personal information with Service Providers to
              monitor and analyze the use of our Service, to contact You.
              <br />
              <span style={{ fontWeight: "bold", color: "black" }}>
                • For business transfers:
              </span>{" "}
              We may share or transfer Your personal information in connection
              with, or during negotiations of, any merger, sale of Company
              assets, financing, or acquisition of all or a portion of Our
              business to another company.
              <br />
              <span style={{ fontWeight: "bold", color: "black" }}>
                • With Affiliates:
              </span>{" "}
              We may share Your information with Our affiliates, in which case
              we will require those affiliates to honor this Privacy Policy.
              Affiliates include Our parent company and any other subsidiaries,
              joint venture partners or other companies that We control or that
              are under common control with Us.
              <br />
              <span style={{ fontWeight: "bold", color: "black" }}>
                • With business partners:
              </span>{" "}
              We may share Your information with Our business partners to offer
              You certain products, services or promotions.
              <br />
              <span style={{ fontWeight: "bold", color: "black" }}>
                • With other users:
              </span>{" "}
              when You share personal information or otherwise interact in the
              public areas with other users, such information may be viewed by
              all users and may be publicly distributed outside. If You interact
              with other users or register through a Third-Party Social Media
              Service, Your contacts on the Third-Party Social Media Service may
              see Your name, profile, pictures and description of Your activity.
              Similarly, other users will be able to view descriptions of Your
              activity, communicate with You and view Your profile.
              <br />
              <span style={{ fontWeight: "bold", color: "black" }}>
                • With Your consent:
              </span>{" "}
              We may disclose Your personal information for any other purpose
              with Your consent.
              <br />
            </Typography>
          </Typography>
        </div>
      </div>

      <div style={{ marginBottom: 10 }}>
        <h2 style={fontStyle.ContentHeading}>Retention of Your Personal Data</h2>

        <Typography
          style={fontStyle.Contentfont1}
        >
          The Company will retain Your Personal Data only for as long as is
          necessary for the purposes set out in this Privacy Policy. We will
          retain and use Your Personal Data to the extent necessary to comply
          with our legal obligations (for example, if we are required to retain
          your data to comply with applicable laws), resolve disputes, and
          enforce our legal agreements and policies.
        </Typography>
        <Typography style={fontStyle.Contentfont1}>
          The Company will also retain Usage Data for internal analysis
          purposes. Usage Data is generally retained for a shorter period of
          time, except when this data is used to strengthen the security or to
          improve the functionality of Our Service, or We are legally obligated
          to retain this data for longer time periods.
        </Typography>
      </div>

      <div style={{ marginBottom: 10 }}>
        <h2 style={fontStyle.ContentHeading}>Transfer of Your Personal Data</h2>

        <Typography
          style={fontStyle.Contentfont1}
        >
          Your information, including Personal Data, is processed at the
          Company's operating offices and in any other places where the parties
          involved in the processing are located. It means that this information
          may be transferred to — and maintained on — computers located outside
          of Your state, province, country or other governmental jurisdiction
          where the data protection laws may differ than those from Your
          jurisdiction.
        </Typography>
        <Typography
          style={fontStyle.Contentfont1}
        >
          Your consent to this Privacy Policy followed by Your submission of
          such information represents Your agreement to that transfer.
        </Typography>
        <Typography style={fontStyle.Contentfont1}>
          The Company will take all steps reasonably necessary to ensure that
          Your data is treated securely and in accordance with this Privacy
          Policy and no transfer of Your Personal Data will take place to an
          organization or a country unless there are adequate controls in place
          including the security of Your data and other personal information.
        </Typography>
      </div>

      <div style={{ marginBottom: 10 }}>
        <h2 style={fontStyle.ContentHeading}>Delete Your Personal Data</h2>

        <Typography
          style={fontStyle.Contentfont1}
        >
          You have the right to delete or request that We assist in deleting the
          Personal Data that We have collected about You.
        </Typography>
        <Typography
         style={fontStyle.Contentfont1}
        >
          Our Service may give You the ability to delete certain information
          about You from within the Service.
        </Typography>
        <Typography
          style={fontStyle.Contentfont1}
        >
          You may update, amend, or delete Your information at any time by
          signing in to Your Account, if you have one, and visiting the account
          settings section that allows you to manage Your personal information.
          You may also contact Us to request access to, correct, or delete any
          personal information that You have provided to Us.
        </Typography>
        <Typography style={fontStyle.Contentfont1}>
          Please note, however, that We may need to retain certain information
          when we have a legal obligation or lawful basis to do so.
        </Typography>
      </div>

      <div style={{ marginBottom: 10 }}>
        <h2 style={fontStyle.ContentHeading}>Disclosure of Your Personal Data</h2>
        <div>
          <h3 style={fontStyle.ContentHeading}>Business Transactions</h3>
          <Typography style={fontStyle.Contentfont1}>
            If the Company is involved in a merger, acquisition or asset sale,
            Your Personal Data may be transferred. We will provide notice before
            Your Personal Data is transferred and becomes subject to a different
            Privacy Policy.
          </Typography>
        </div>

        <div>
          <h3 style={fontStyle.ContentHeading}>Law enforcement</h3>
          <Typography style={fontStyle.Contentfont1}>
            Under certain circumstances, the Company may be required to disclose
            Your Personal Data if required to do so by law or in response to
            valid requests by public authorities (e.g. a court or a government
            agency).
          </Typography>
        </div>

        <div>
          <h3 style={fontStyle.ContentHeading}>Other legal requirements</h3>
          <Typography style={fontStyle.Contentfont1}>
            The Company may disclose Your Personal Data in the good faith belief
            that such action is necessary to:
            <Typography
              style={fontStyle.Contentfont2}
            >
              <span style={{ fontWeight: "bold", color: "black" }}>•</span>{" "}
              Comply with a legal obligation
              <br />{" "}
              <span style={{ fontWeight: "bold", color: "black" }}>•</span>{" "}
              Protect and defend the rights or property of the Company
              <br />{" "}
              <span style={{ fontWeight: "bold", color: "black" }}>•</span>{" "}
              Prevent or investigate possible wrongdoing in connection with the
              Service
              <br />{" "}
              <span style={{ fontWeight: "bold", color: "black" }}>•</span>{" "}
              Protect the personal safety of Users of the Service or the public
              <br />{" "}
              <span style={{ fontWeight: "bold", color: "black" }}>•</span>{" "}
              Protect against legal liability
            </Typography>
          </Typography>
        </div>
      </div>

      <div style={{ marginBottom: 10 }}>
        <h2 style={fontStyle.ContentHeading}>Security of Your Personal Data</h2>

        <Typography style={fontStyle.Contentfont1}>
          The security of Your Personal Data is important to Us, but remember
          that no method of transmission over the Internet, or method of
          electronic storage is 100% secure. While We strive to use commercially
          acceptable means to protect Your Personal Data, We cannot guarantee
          its absolute security.
        </Typography>
      </div>

      <h1 style={fontStyle.HeadingFont}>Children's Privacy</h1>
      <div style={{ marginBottom: 10 }}>
        <div>
          <Typography
            style={fontStyle.Contentfont1}
          >
            Our Service does not address anyone under the age of 13. We do not
            knowingly collect personally identifiable information from anyone
            under the age of 13. If You are a parent or guardian and You are
            aware that Your child has provided Us with Personal Data, please
            contact Us. If We become aware that We have collected Personal Data
            from anyone under the age of 13 without verification of parental
            consent, We take steps to remove that information from Our servers.
          </Typography>
          <Typography style={fontStyle.Contentfont1}>
            If We need to rely on consent as a legal basis for processing Your
            information and Your country requires consent from a parent, We may
            require Your parent's consent before We collect and use that
            information.
          </Typography>
        </div>
      </div>

      <h1 style={fontStyle.HeadingFont}>Links to Other Websites</h1>
      <div style={{ marginBottom: 10 }}>
        <div>
          <Typography
            style={fontStyle.Contentfont1}
          >
            Our Service may contain links to other websites that are not
            operated by Us. If You click on a third party link, You will be
            directed to that third party's site. We strongly advise You to
            review the Privacy Policy of every site You visit.
          </Typography>
          <Typography style={fontStyle.Contentfont1}>
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
          </Typography>
        </div>
      </div>

      <h1 style={fontStyle.HeadingFont}>Changes to this Privacy Policy</h1>
      <div style={{ marginBottom: 10 }}>
        <div>
          <Typography
            style={fontStyle.Contentfont1}
          >
            We may update Our Privacy Policy from time to time. We will notify
            You of any changes by posting the new Privacy Policy on this page.
          </Typography>
          <Typography
            style={fontStyle.Contentfont1}
          >
            We will let You know via email and/or a prominent notice on Our
            Service, prior to the change becoming effective and update the "Last
            updated" date at the top of this Privacy Policy.
          </Typography>
          <Typography style={fontStyle.Contentfont1}>
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </Typography>
        </div>
      </div>

      <h1 style={fontStyle.HeadingFont}>Contact Us</h1>
      <div style={{ marginBottom: 10 }}>
        <div>
          <Typography
           style={fontStyle.Contentfont3}
          >
            If you have any questions about this Privacy Policy, you can contact
            us:
            <Typography
              style={fontStyle.Contentfont3}
            >
             
              • By visiting this page on our website:
              http://www.makemybiodata.in/contactus
              <br />• By email: contact@makemybiodata.in
            </Typography>
          </Typography>
        </div>
      </div>
    </Grid>
    </Grid>
  );
};

export default Content;
