import { Grid, Typography } from "@mui/material";
import fontStyle from "../../../assets/fontStyle";
import ImagePath from "../../../assets/images";
import React from "react";

const Head = () => {
  return (
    <Grid
      container
      sx={{
        display: "flex",
        justifyContent: "center",
        backgroundImage: "url(" + ImagePath.Desing3 + ")",
        backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        margin: "0 auto",
      }}
    >
      <Grid
        container
        style={{
          // height: 420,
          height: "auto", // Set height to auto to adjust based on content
          // maxHeight: 1200000, // Limit the maximum height of the container
          display: "flex",
          minHeight: "20rem",
          alignContent: "center",

          flexDirection: "column",
          maxWidth: "1400px",
          justifyContent: "center",
          padding: window.innerWidth > 1400 ? 0 : "0 7% 0 7%",
          // padding:
          //   window.innerWidth < 300
          //     ? "10px 7%"
          //     : window.innerWidth > 600
          //     ? "20px 7%"
          //     : "10px 7%",
        }}
      >
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            maxWidth:"1200px"
          }}
        >
          {/* <h5 style={fontStyle.Content}>
          Home> Privacy Policy
        </h5> */}
          <h1
            style={{
              fontFamily: "Enoway",
              color: "white",
              fontSize: 35,
              fontWeight: 400,
              letterSpacing: "0.1rem",
            }}
          >
            Privacy Policy
          </h1>
          <Typography
            style={{
              fontFamily: "Avenir",
              fontSize: "18px",
              marginBottom: "2%",
              textAlign: "center",
              color: "white",
            }}
          >
            This Privacy Policy describes Our policies and procedures on the
            collection, use and disclosure of Your information when You use the
            Service and tells You about Your privacy rights and how the law
            protects You.
          </Typography>
          <Typography
            style={{
              fontFamily: "Avenir",
              fontSize: "18px",
              textAlign: "center",
              color: "white",
            }}
          >
            We use Your Personal data to provide and improve the Service. By
            using the Service, You agree to the collection and use of
            information in accordance with this Privacy Policy. This Privacy
            Policy has been created with the help of the Privacy Policy
            Generator.
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};

export default Head;
