import { RiContactsBookLine } from "react-icons/ri";
import {
  RESETPASS_ID,
  AUTH_DATA,
  GET_TEMPLATE,
  FORM_SUBMIT,
  USER_INFO,
  GOT_BIO,
  SELECTED_BIO_Id,
  PASS_CHANGE,
  PRO_PASS_CHANGE,
  STORE_RESUME_ID
} from "./constants";

const initialState = {
  data: "",
  isAuth: false,
  template: "",
  submit: false,
  user: {},
  biodataId: "",
  bio: false,
  value: false,
  provalue: false,
  resumeId: "" 
};


export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RESETPASS_ID:
      console.log("Payload:", action.payload);
      console.log("Previous State:", state);
      const newState = {
        ...state,
        data: action.payload,
      };
      console.log("New State:", newState);
      return newState;

    case AUTH_DATA:
      console.log("auth: ", action.payload);
      const authState = {
        ...state,
        isAuth: action.payload,
      };
      console.log("Auth State:", authState);
      return authState;

    case GET_TEMPLATE:
      console.log("TEMP DATA: ", action.payload);
      const tempState = {
        ...state,
        template: action.payload,
      };
      return tempState;

    case FORM_SUBMIT:
      console.log("FOrm submittted: ", action.payload);
      const formState = {
        ...state,
        submit: action.payload,
      };
      return formState;

    case USER_INFO: 
    console.log("User: ", action.payload);
    const userState = {
      ...state,
      user: action.payload
    }
    return userState;

    case PASS_CHANGE:
    console.log("pass: ", action.payload);
    const passState = {
      ...state,
      value: action.payload
    } 
    return passState; 
    
    case PRO_PASS_CHANGE:
      console.log("propass: ", action.payload);
    const proPassState = {
      ...state,
      provalue: action.payload
    } 
    return proPassState; 

   case STORE_RESUME_ID:
    console.log("resume_id: ", action.payload);
    const resumeState = {
      ...state,
      resumeId: action.payload
    } 
    return resumeState;


    default:
      return state;
  }
};
