import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getAllBiodata, getBiodata } from "../../../services/ApiService";
import { getResume, getUserId1, setPdf, setPreview, setResume } from "../../../utils/localStorage";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  storeBioClick,
  storeSelectedId,
  storeSubmit,
  storeTemplate,
} from "../../../Redux/action";

const BiodataList = () => {
  const dispatch = useDispatch();
  const [biodata, setBiodata] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchBiodata = async () => {
      try {
        const user_id = getUserId1();
        console.log(user_id);
        const temp = await getAllBiodata(user_id);
        setBiodata(temp);
        setPdf("");
        console.log(biodata);
      } catch (error) {
        console.error("Error fetching biodata:", error);
      }
    };
    fetchBiodata();
  }, []);

  const [select, setSelect] = useState("");
  const handleClick = async (biodata_id) => {
    // setSelect(biodata_id);
    // dispatch(storeBioClick(true));
    console.log(biodata_id);
    try {
      const response = await getBiodata(biodata_id);
      if (response.status === "success") {
        // dispatch(storeSelectedId(response.pdfHTML));
        console.log(response);
        dispatch(storeTemplate(response.pdfHTML));
        dispatch(storeSubmit(true));
        setResume(biodata_id);
        console.log("i am here:",   biodata_id)
        setPreview(biodata_id);
        navigate("/preview");
        console.log(response.pdfHTML);
      } else if (response.status === "error") {
        console.log("error in create user:", response);
      } else if (response.status === "fail") {
        console.log("error in create user:", response);
      }
    } catch (error) {
      console.log("error in: ", error);
    }
  };

  return (
    
 <Grid container sx={{  display: "flex", justifyContent: "center", backgroundColor: "white",  margin: "0 auto" }}>
    <Grid
      container
      style={{
        height: "auto",
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        maxWidth: "1400px",
        padding: biodata.length > 0 ? (window.innerWidth > 1400 ? "20px 0" : "20px 7%") : (window.innerWidth > 1400 ? "0px 0" : "0px 7%"),
      }}
    >
      <Grid item container spacing={2} style={{display:"flex", justifyContent:"flex-start", justifyItems:"center"}}>
        {biodata.map((image) => (
          <Grid
            key={image.id}
            item
            xs={12} // For extra small screens, each card takes full width
            sm={6}  // For small screens, each row contains 2 cards
            md={4}  // For medium screens, each row contains 3 cards
            lg={3}  // For large screens, each row contains 4 cards
            style={{
              display: "flex", 
              justifyContent: "center" // Center each card within its grid item
            }}
          >
            <Card
              onClick={() => handleClick(image.id)}
              sx={{
                maxWidth: "350px",
                  // width: window.innerWidth > 900 ? "200px" : window.innerWidth > 750 && window.innerWidth < 900 ? "160px" : "130px",
                height:  window.innerWidth > 900 ? "350px" : window.innerWidth > 750 && window.innerWidth < 900 ? "350px" : "350px",
                minWidth: "150px",
                width: "80%",
                padding: "10px 0px",
                background: "rgba(255, 228, 243, 1)",
                border: select === image.id ? "2px solid gold" : "none",
              }}

            >
              <CardContent>
                <Typography gutterBottom variant="body1" component="div">
                  Card id: {image.id}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Grid>
    </Grid>
  );
};

export default BiodataList;
