import * as Yup from "yup";


export const contactUsSchema = Yup.object({
  name: Yup.string().required("Please enter your name"),
  email_id: Yup.string()
    .email("Please enter valid email")
    .required("Please enter your email"),
 subject: Yup.string().required("Please enter your subject")
});
