import logo from "./logo.svg";
import "./App.css";
import Login from "./components/Login";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Signup from "./components/Signup";
import Otppage from "./components/OTPpage";
import NewPassword from "./components/newPassword";
import ForgetPassword from "./components/forgetPassword";
import ChangedPassword from "./components/changedPassword.js";
import HomeScreen from "./components/HomeScreen/index.js";
import LandingPage from "./components/LandingPage/index.js";
import PersonalDetails from "./components/PersonalDetailsPage/index.js";
import PaymentPage from "./components/PaymentPage/index.js";
import AboutUs from "./components/AboutUs/index.js";
import PrivacyPolicy from "./components/PrivacyPolicy/index.js";
import Terms from "./components/Terms/index.js";
import ContactUs from "./components/ContactUs/index.js";
import PrivateRoute from "./Auth/PrivateRoute.js";
import { useDispatch, useSelector } from "react-redux";
import NotFoundPage from "./components/NotFoundPage/index.js";
import { useCallback, useEffect, useMemo } from "react";
import { getPass, getProPass, isLoggedIn } from "./utils/localStorage.js";
import { passChange, proPassChange, storeAuthData } from "./Redux/action.js";
import { pdfjs } from "react-pdf";
import Profile from "./components/Profile/index.js";
import ProfileChangePassword from "./components/ProfileChangePassword/index.js";
import PreviewPage from "./components/PreviewPage/index.js";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { GlobalStyles } from '@mui/material';
import './fonts/fonts.css';
import CircularProgress from "./components/CircularProgress/index.js";
import FaQs from "./components/FaQs/index.js";
import '../src/utils/i18n';


pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

const theme = createTheme({
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          overflow: 'auto',
        },
      },
    },
  },
});


function App() {
  const isAuthenticated = useSelector((store) => store.reducer.isAuth);
  const dispatch = useDispatch();
  const isUserAuthenticated = useMemo(() => {
    const isLogin = isLoggedIn();
    if (isAuthenticated) {
      return true;
    } else {
      isLogin && dispatch(storeAuthData(true));
      console.log(isLogin);
      console.log(isAuthenticated);
      return isLogin;
    }
  }, [isAuthenticated]);

  console.log("checkUserLogin", isUserAuthenticated);
  // console.log("iss pass: ", isPassChange);

  const isProPass = useSelector((store) => store.reducer.provalue);
  const isProPassChange = useMemo(() => {
    const isPro = getProPass();
    if(isProPass){
      return true;
    } else {
      isPro && dispatch(proPassChange(true));
      console.log(isPro);
      console.log(isProPass);
      return isPro;
    }
  }, [isProPass]);
  
  const isPass = useSelector((store) => store.reducer.value);
  const isPassChange = useMemo(() => {
    const isP = getPass();
    if(isPass){
      return true;
    } else {
      isP && dispatch(passChange(true));
      console.log("pass: "+isP);
      console.log("pass2: "+isPass);
      return isP;
    }
  }, [isPass]);

  return (
       <ThemeProvider theme={theme}>
         <GlobalStyles styles={{ body: { overflow: 'auto !important', paddingRight: '0 !important' } }} />
    <BrowserRouter >
      <div id="recaptcha-container"></div>
      <Routes>
      <Route
          path="/login"
          element={
            !isUserAuthenticated ? <Login /> : <Navigate to="/" />
          }
        />
        {/* <Route path="/login" element={<Login />} /> */}
        <Route path="/signup" element={<Signup />} />
        {/* <Route path="/resetpassword" element={<NewPassword />} /> */}
        <Route path="/resetpassword" element={!isPassChange ? <NewPassword /> : <Navigate to="/changedpassword"/>} />
        <Route path="/forgetpassword" element={<ForgetPassword />} />
        <Route path="/changedpassword" element={<ChangedPassword />} />
        <Route path="/" element={<HomeScreen />} />
        {/* <Route path="/personaldetails" element={<PersonalDetails/>}/> */}
        <Route
          path="/personaldetails"
          element={
            isUserAuthenticated ? <PersonalDetails /> : <Navigate to="/login" />
          }
        />
        {/* <Route path="/paymentpage" element={<PaymentPage />} /> */}
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/policy" element={<PrivacyPolicy />} />
        <Route path="/termscondition" element={<Terms />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="*" element={<NotFoundPage />} />
        <Route
          path="/profile"
          element={isUserAuthenticated ? <Profile /> : <Navigate to="/login" />}
        />
        <Route
          path="/userresetpassword"
          element={
            !isUserAuthenticated  ? (
              <Navigate to="/login" />
            ) : !isProPassChange ? (
              <ProfileChangePassword />
            ) : (<Navigate to="/profile" />)
          }
        />
        <Route path="/preview" element={<PreviewPage/>}/>
        <Route path="/faqs" element={<FaQs/>}/>
      </Routes>
      <CircularProgress/>
    </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
