import { Grid, Typography } from "@mui/material";
import ImagePath from "../../../assets/images";
import React from "react";

const Head = () => {
  return (
    <Grid
      container
      sx={{
        display: "flex",
        justifyContent: "center",
        // backgroundColor: "#EC0673",
        backgroundImage: "url(" + ImagePath.Desing1 + ")",
        backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          // backgroundAttachment: "fixed",
        margin: "0 auto",
      }}
    >
      <Grid
        container
        style={{
          // height: 420,
          height: "auto", // Set height to auto to adjust based on content
          // maxHeight: 1200000, // Limit the maximum height of the container
          // background: "#EC0673",
          minHeight: "20rem",

          display: "flex",
          alignContent: "center",
          flexDirection: "column",
          maxWidth: "1400px",
          justifyContent: "center",
          padding: window.innerWidth > 1400 ? 0 : "0 7% 0 7%",
          // padding:
          //   window.innerWidth < 300
          //     ? "10px 7%"
          //     : window.innerWidth > 600
          //     ? "20px 7%"
          //     : "10px 7%",
        }}
      >
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* <h5 style={{ color: "#EC0673", paddingBottom: "4%", fontFamily: "Avenir" }}>
        Home> About Us
        </h5> */}
          <h1
            style={{
              color: "white",
              fontFamily: "Enoway",
              fontWeight: 400,
              fontSize: 35,
              letterSpacing: "0.1rem",
            }}
          >
            About Us
          </h1>
          <Typography
            style={{
              fontSize: "18px",
              // marginBottom: "8%",
              color: "white",
              fontWeight: 500,
              fontFamily: "Avenir",
              textAlign: "center",
            }}
          >
            Make My BioData: Where Dreams Unfold on Paper. Our Biodata? They're
            the Talk of Every Shaadi Town! Let's Make Your mangalam Iconic.
          </Typography>
          {/* <Typography style={{ fontSize: "15px", marginBottom: "5%" }}>
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
        </Typography> */}
        </div>
      </Grid>
    </Grid>
  );
};

export default Head;
