function getCanvasImageBinary(imageSrc) {
    const  canvas = document.createElement("canvas"); 
    return new Promise((resolve, reject) => {
      if (!canvas) {
        reject("Canvas element is required.");
        return;
      }
  
      const ctx = canvas.getContext("2d");
      const img = new Image();
      img.crossOrigin = "Anonymous"; // To avoid CORS issues
      img.src = imageSrc;
  
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
  
        canvas.toBlob(
          (blob) => {
            if (blob) {
              const reader = new FileReader();
              reader.onloadend = () => resolve(reader.result); // Resolve the binary data
              reader.readAsArrayBuffer(blob);
            } else {
              reject("Failed to convert canvas content to a blob.");
            }
          },
          "image/png",
          1.0
        );
      };
  
      img.onerror = () => reject("Failed to load image: " + imageSrc);
    });
  }
  
  export default getCanvasImageBinary