import { Grid, Typography } from "@mui/material";
import fontStyle from "../../../assets/fontStyle";
import ImagePath from "../../../assets/images";
import React from "react";

const Head = () => {
  return (
    <Grid
      container
      sx={{
        display: "flex",
        justifyContent: "center",
        backgroundImage: "url(" + ImagePath.Desing2 + ")",
        backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        margin: "0 auto",
      }}
    >
      <Grid
        container
        style={{
          // height: 420,
          height: "auto", // Set height to auto to adjust based on content
          // maxHeight: 1200000, // Limit the maximum height of the container
          display: "flex",
          alignContent: "center",

          flexDirection: "column",
          minHeight: "20rem",
          maxWidth: "1400px",
          justifyContent: "center",
          padding: window.innerWidth > 1400 ? "0px 0" : "0px 7% 0px 7%",
          // padding:
          //   window.innerWidth < 300
          //     ? "10px 7%"
          //     : window.innerWidth > 600
          //     ? "20px 7%"
          //     : "10px 7%",
        }}
      >
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            maxWidth:"1200px"
          }}
        >
          {/* <h5 style={fontStyle.Content}>
        Home> Terms & Condition
        </h5> */}
          <h1
            style={{
              fontFamily: "Enoway",
              color: "white",
              fontSize: 35,
              fontWeight: 400,
              letterSpacing: "0.1rem",
            }}
          >
            Terms and Conditions
          </h1>
          <Typography
            style={{
              fontFamily: "Enoway",
              fontSize: "21px",
              marginBottom: "2%",
              color: "white",
              fontWeight: 400,
              letterSpacing: "0.1rem",
            }}
          >
            Welcome to Make My BioData!
          </Typography>
          <Typography
            style={{
              fontFamily: "Avenir",
              fontSize: "18px",
              textAlign: "center",
              color: "white",
            }}
          >
            These terms and conditions outline the rules and regulations for the
            use of makemybiodata.in's Website, located at
            www.makemybiodata.in. By accessing this website we assume you
            accept these terms and conditions. Do not continue to use Make My
            BioData if you do not agree to take all of the terms and conditions
            stated on this page.
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};

export default Head;
