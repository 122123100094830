import { width } from "@mui/system";

const styles = {
  container: {
    height: "100vh",
    // maxHeight: "1200px",
    // maxWidth: "1400px",
  },

  // gredientblur: {
  //   background:
  //     "linear-gradient(to bottom, Transparente 0%,Transparente 50%,red 50%,red 100%)",
  // },

  gridWrapper: {
    position: "relative",
  },

  grid1: {
    backgroundColor: "#FFD2EB",
    // backdropFilter: "blur(20px)",
  },

  image1: {
    width: "250px",
    height: "130px",
    display: "flex",
    marginLeft: -10,
    marginTop: -15,
  },

  grid12: {
    alignItems: "end",
    justifyContent: "center",
    display: "flex",
  },

  image2: {
    position: "absolute",
    height:
      window.innerWidth > 1400
        ? "600px"
        : window.innerWidth > 1290
        ? "650px"
        : window.innerWidth > 1030
        ? "550px"
        : window.innerWidth > 900 && window.innerWidth < 1400
        ? "500px"
        : window.innerWidth > 950 &&
          window.innerWidth < 1030 &&
          window.innerHeight > 650
        ? "760px"
        : window.innerWidth > 800 && window.innerWidth < 950
        ? "500px"
        : window.innerWidth > 600 && window.innerWidth < 800
        ? "400px"
        : "460px",
    marginRight: window.innerWidth > 1030 ? "140px" : "70px",
  },

  image3: {
    height:
      window.innerWidth > 1400
        ? "500px"
        : window.innerWidth > 1290
        ? "550px"
        : window.innerWidth > 1030
        ? "450px"
        : window.innerWidth > 900 && window.innerWidth < 1400
        ? "400px"
        : window.innerWidth > 950 &&
          window.innerWidth < 1030 &&
          window.innerHeight > 650
        ? "500px"
        : window.innerWidth > 800 && window.innerWidth < 950
        ? "400px"
        : window.innerWidth > 650 && window.innerWidth < 800
        ? "300px"
        : "310px",
    position: "relative",
    marginLeft: window.innerWidth > 1030 ? "120px" : "40px",
  },

  // blurOverlay: {
  //   position: "absolute",
  //   display: "flex",
  //   // bottom: 0,
  //   // left: 0,
  //   top: window.innerWidth > 1200 ? -55 : 80,
  //   width:
  //     window.innerWidth < 550 ? 0 : window.innerWidth > 860 ? "50%" : "54.5%",
  //   // height:  window.innerWidth > 1290 ? "425px" : "108%",
  //   height:
  //     //  window.innerWidth > 1020
  //     //     ? "105vh"
  //     //     : window.innerWidth == 1280 && window.innerHeight == 551
  //     //     ? "142vh"
  //     //     : window.innerWidth > 1020 && window.innerWidth < 1285
  //     //     ? window.innerHeight == 1366
  //     //       ? "94vh"
  //     //       : "108vh"
  //     //     : window.innerWidth > 1282
  //     //     ? "142vh"
  //     //     : "94vh",
  //     // window.innerWidth > 1300
  //     //   ? "105vh"
  //     //   : window.innerWidth == 1280 && window.innerHeight == 551
  //     //   ? "142vh"
  //     //   : window.innerWidth > 1280
  //     //   ? "135vh"
  //     //   : window.innerWidth > 1260
  //     //   ? "110vh"
  //     //   : window.innerWidth > 1150
  //     //   ? "142vh"
  //     //   : window.innerWidth > 1020 && window.innerHeight < 700
  //     //   ? "110vh"
  //     //   : window.innerWidth > 1025
  //     //   ? "102vh"
  //     //   : window.innerWidth > 1000
  //     //   ? "94vh"
  //     //   : window.innerWidth > 750
  //     //   ? "94vh"
  //     //   : window.innerWidth > 700
  //     //   ? "102vh"
  //     //   : "100vh",
  //   // backgroundColor: "red",

  //   background:
  //     window.innerWidth > 730
  //       ? "linear-gradient(to bottom,  transparent, transparent,  white)"
  //       : 0,
  //   zIndex: 10,
  // },

  blurOverlay: {
    position: "absolute",
    display: "flex",
    // backgroundColor: "red",
    background:
      window.innerWidth > 550
        ? "linear-gradient(to bottom,  transparent, white)"
        : 0,
    zIndex: 10,
    // width: "200px",
    height: "300px",
    // top: window.innerWidth > 1200 ? -55 : 80,
    width:
      window.innerWidth < 550 ? 0 : window.innerWidth > 900 ? "50%" : "54.5%",
    // height: "100%",
    justifyContent: "end",
    justifySelf: "flex-end",
    alignItems: "start",
    alignSelf: "end",
  },

  grid2: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    padding:
      window.innerWidth >= 1000
        ? 125
        : window.innerWidth > 900 && window.innerWidth < 1000
        ? 90
        : window.innerWidth < 350
        ? 15
        : 50,
    // paddingTop: window.innerWidth < 300 ? 80 : 164,
  },

  form: {
    textAlign: "left",
    height: "425px",
    width: 500,
  },

  linkDiv: {
    display: "flex",
    justifyContent: "right",
    height: "50px",
    fontSize: 14,
  },

  link: {
    color: "grey",
    border: "none",
    textDecoration: "none",
    height: "40%",
   
},

  Button: {
    height: 45,
    borderRadius: 5,
    background: "#EC0673",
    textDecoration: "none",
    boxShadow: "none",
    textTransform: "NONE",
    marginBottom: 12,
  },

  link1: {
    color: "#1E232C",
    border: "none",
    textDecoration: "none",
    display: "flex",
    justifyContent: "center",
    height: "50px",
    fontSize: 14,
  },

  link2: {
    color: "#1E232C",
    border: "none",
    textDecoration: "none",
    display: "flex",
    justifyContent: "center",
    height: "35%",
    fontSize: 14,
  },

  link2div: {
    display: "flex",
    justifyContent: "center",
    height: "50px",
  },
};

export default styles;
