const Styles = {
 card : {
    display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  padding: "10px 0px",
                  backgroundColor: "rgb(255 212 236 / 50%)",
                  boxShadow: "none",
                  marginRight: 20,
                  height: "330px",
 },

 cardContent: {
    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    alignContent: "center",
                    alignSelf: "center",
                    textAlign: "center",
 },

 cardText: {
    fontSize: "12px",
    fontFamily:"Avenir",
                      padding: 8,
                      alignContent: "center",
                      display: "flex",
                      justifyContent: "center",
 },

 avatar: {
    height: 35, width: 35, background: "#EC0673"
 },

 avatarName: {
    fontWeight: 600,
    fontFamily:"Avenir",
                      fontSize: "14px",
                      color: "rgba(30, 35, 44, 1)",
 },
 avatarCity: {
    fontSize: "9px", color: "rgba(165, 164, 164, 1)", fontFamily:"Avenir",
 } 
}

export default Styles;