import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import ImagePath from "../../../assets/images";
import fontStyle from "../../../assets/fontStyle";

const Footer1 = () => {
  return (
    <>
        <Grid container sx={{  display: "flex", justifyContent: "center", background:
            window.innerWidth > 899 ?
              "linear-gradient(to right, rgba(236, 6, 115), rgba(236, 6, 115, 0.77), rgba(236, 6, 115, 0.47)), url(" +
              ImagePath.BackDesign1 +
              ")" : "linear-gradient(to bottom, rgba(236, 6, 115), rgba(236, 6, 115, 0.77), rgba(236, 6, 115, 0.47)), url(" +
              ImagePath.BackDesign1 +
              ")",  margin: "0 auto" }}>
      <Grid
        container
        sx={{
          position: "relative",
          overflow: "hidden",
          height: "auto",
          maxHeight: 1000,
          maxWidth: "1400px",
          padding: window.innerWidth > 1400 ? " 0  0" : "0 7% 0 7%",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            // background:
            // window.innerWidth > 899 ?
            //   "linear-gradient(to right, rgba(236, 6, 115), rgba(236, 6, 115, 0.77), rgba(236, 6, 115, 0.47)), url(" +
            //   ImagePath.BackDesign1 +
            //   ")" : "linear-gradient(to bottom, rgba(236, 6, 115), rgba(236, 6, 115, 0.77), rgba(236, 6, 115, 0.47)), url(" +
            //   ImagePath.BackDesign1 +
            //   ")",
            // backgroundColor: "#FFF9EC",
            backgroundSize: "cover",
            //   backgroundPosition: "center",
            zIndex: -1,
          },
        }}
      >
        <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{
            display: "flex",
            alignContent: window.innerWidth > 600 ? "end" : "start",
            // alignItems: window.innerWidth > 600 ? "end" : "start",
            flexDirection: "column",
            justifyContent: window.innerWidth > 600 ? "center" : "start",
            // backgroundColor: "red",
            // padding: window.innerWidth < 300 ? "0px 7%px" : "0px 7%px",
            paddingTop: 60,
          }}
        >
          <Typography
            style={fontStyle.ContentHead2}
          >
            Create beautiful Biodata from our App
          </Typography>
          <Typography
            style={fontStyle.HeadingFont2}
          >
            Download the <br /> MakeMyBioData App today!
          </Typography>
          <Typography
            style={fontStyle.ContentFont4}
          >
            Love is waiting. Download MakeMyBioData, the free app that helps you
            find your perfect someone.
          </Typography>

          <Grid
      container
      spacing={2}
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        alignItems: 'center',
        padding: '30px 0',
        
        marginLeft:
                window.innerWidth < 300
                  ? 0
                  : window.innerWidth < 400
                  ? -5
                  : window.innerWidth > 600
                  ? 60
                  : 5,
              paddingTop: 30,
      }}
    >
      <Grid item lg={3.2} md={4.2} sm={3.2} xs={3.8} style={{ display: 'flex', justifyContent: 'end', backgroundColor: ""}}>
        <img
          src={ImagePath.playstore}
          alt="App Icon"
          style={{ width: '100%', maxWidth: '190px', height: 'auto' }}
        />
      </Grid>
      <Grid item lg={3.2} md={4.2} sm={3.2} xs={3.8} style={{ display: 'flex', justifyContent: 'start', backgroundColor: "" }}>
        <img
          src={ImagePath.appstore}
          alt="App Store Icon"
          style={{ width: '100%', maxWidth: '190px', height: 'auto' }}
        />
      </Grid>
    </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{
            // backgroundColor: "red",
            display: "flex",
            alignItems: "start",
            justifyContent: "center",
            alignContent: "end",
            height: window.innerWidth < 300 ? 250 : 350,
            marginTop: 60,
          }}
        >
          <img
            src={ImagePath.TwoPhone}
            style={{
              width:
                window.innerWidth < 300
                  ? 250
                  : window.innerWidth < 400
                  ? 350
                  : window.innerWidth > 600
                  ? 400
                  : 365,
            }}
          ></img>
        </Grid>
        </Grid>
      </Grid>
      </Grid>
    </>
  );
};

export default Footer1;
